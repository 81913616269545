import { httpGet, httpPut, httpPost, httpDel } from 'boot/axios';

const state = {
	lists: []
};

const getters = {
	GET_FAMILY_MEMBERS(state) {
		return state.lists;
	}
};

const mutations = {
	SET_FAMILY_MEMBERS(state, payload) {
		state.lists = payload;
	},
	INSERT_FAMILY_MEMBER(state, payload) {
		state.lists.unshift(payload);
	},
	MODIFY_FAMILY_MEMBER(state, payload) {
		let objectIndex = state.lists.findIndex(e => e.id === payload.id);
		objectIndex !== -1 &&
			Object.keys(state.lists[objectIndex]).forEach(key => {
				state.lists[objectIndex][key] = payload[key];
			});
	},
	REMOVE_FAMILY_MEMBERS(state, payload) {
		let objectIndex = state.lists.findIndex(e => e.id === payload.id);
		objectIndex !== -1 && state.lists.splice(objectIndex, 1);
	}
};

const actions = {
	FETCH_EMPLOYEE_FAMILY_BACKGROUND({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/familyBackground',
				{
					success(response) {
						response.data.status === 'success' && commit('SET_FAMILY_MEMBERS', response.data.data);
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					}
				},
				payload
			);
		});
	},

	SAVE_EMPLOYEE_FAMILY_BACKGROUND({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPost(`juanHR/familyBackground`, payload, {
				success(response) {
					response.data.status === 'success' && commit('INSERT_FAMILY_MEMBER', response.data.data);
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				}
			});
		});
	},

	UPDATE_EMPLOYEE_FAMILY_BACKGROUND({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPut(`juanHR/familyBackground/${payload.id}`, payload, {
				success(response) {
					response.data.status === 'success' && commit('MODIFY_FAMILY_MEMBER', response.data.data);
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				}
			});
		});
	},

	DELETE_EMPLOYEE_FAMILY_BACKGROUND({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpDel(
				`juanHR/familyBackground/${payload.id}`,
				{
					id: [payload.id]
				},
				{
					success(response) {
						response.data.status === 'success' && commit('REMOVE_FAMILY_MEMBERS', payload);
						resolve(response.data);
					},
					catch(response) {}
				}
			);
		});
	}
};

export default { namespaced: true, state, getters, mutations, actions };
