import { httpGet, httpPost, httpPut, httpDel } from 'boot/axios';
// import { isObject } from './../../../../../shared/helpers/Common';
import { isObject } from './../../../shared/helpers/Common';

const state = {
	lists: [],
	lazyListsGroup: [],
	resetAllowanceLazyLoad: false,
	deleteEnabled: false,
	displayAllowanceForm: false,
	searchLists: [],
	suggestLists: [],
	employeeAllowanceLists: [],
};

const getters = {
	GET_ALLOWANCE: (state) => {
		return state.lists;
	},

	GET_ALLOWANCE_LAZY_GROUP(state) {
		return state.lazyListsGroup;
	},

	GET_ALLOWANCE_FORM_STATE: (state) => {
		return state.displayAllowanceForm;
	},
	GET_RESET_ALLOWANCE_LAZY_LOAD(state) {
		return state.resetAllowanceLazyLoad;
	},
	GET_ALLOWANCE_DELETE_STATE: (state) => {
		return state.deleteEnabled;
	},
	GET_SEARCH_ALLOWANCE: (state) => {
		return state.searchLists;
	},
	GET_SUGGEST_ALLOWANCE: (state) => {
		return state.suggestLists;
	},
	GET_EMPLOYEE_ALLOWANCE: (state) => {
		return state.employeeAllowanceLists;
	},
};

const mutations = {
  SET_DELETE(state, payload) {
    state.deleteEnabled = payload
  },
  CLEAR_SEARCH_ALLOWANCE(state) {
    state.searchLists = []
  },
  CLEAR_SUGGEST_ALLOWANCE(state) {
    state.suggestLists = []
  },
	TOGGLE_ALLOWANCE_DELETE(state, payload) {
		state.deleteEnabled = state.deleteEnabled !== true;
	},

	SET_ALLOWANCE(state, payload) {
		state.lists = payload;
	},
	SEARCH_ALLOWANCE(state, payload) {
		state.searchLists = payload;
	},

	SUGGEST_ALLOWANCE(state, payload) {
		state.suggestLists = payload;
	},

	INSERT_ALLOWANCE(state, payload) {
		// state.lists.unshift(payload);
		// state.lazyListsGroup.unshift(payload);
		let exists = state.lists.filter((list) => list.employee_id === payload.employee_id);
		!Boolean(exists.length) && state.lists.unshift(payload);

		let existss = state.lazyListsGroup.filter((list) => list.employee_id === payload.employee_id);
		!Boolean(existss.length) && state.lazyListsGroup.unshift(payload);
	},

	INSERT_ALLOWANCE_BULK(state, payload) {
		payload.forEach((e) => {
			let exists = state.lists.filter((list) => list.employee_id === e.employee_id);
			e.name = e.allowance_name;
			!Boolean(exists.length) && state.lists.unshift(e);

			let existss = state.lazyListsGroup.filter((list) => list.employee_id === e.employee_id);
			!Boolean(existss.length) && state.lazyListsGroup.unshift(e);
		});
	},

	MODIFY_ALLOWANCE(state, payload) {
		let objectIndex = state.lists.findIndex((e) => e.id === payload.id);
		objectIndex !== -1 &&
			Object.keys(state.lists[objectIndex]).forEach((key) => {
				state.lists[objectIndex][key] = payload[key];
			});

		let objectIndexs = state.lazyListsGroup.findIndex((e) => e.id === payload.id);
		objectIndexs !== -1 &&
			Object.keys(state.lazyListsGroup[objectIndexs]).forEach((key) => {
				state.lazyListsGroup[objectIndexs][key] = payload[key];
			});
	},

	REMOVE_ALLOWANCE(state, payload) {
		payload.id.forEach((arrayItem) => {
			let objectIndex = state.employeeAllowanceLists.findIndex((b) => b.id === arrayItem);
			objectIndex !== -1 && state.employeeAllowanceLists.splice(objectIndex, 1);

			//Get allowances bound to Employee ID
			let employeeAllowancesid = state.lazyListsGroup.filter((lists) => lists.id === arrayItem);
			console.log(employeeAllowancesid);
			// Iterate found employee allowances
			employeeAllowancesid.forEach((employeeAllowancesids) => {
				let objectIndex = state.lazyListsGroup.findIndex((b) => b.id === employeeAllowancesids.id);
				objectIndex !== -1 && state.lazyListsGroup.splice(objectIndex, 1);
			});

			let objectIndex1 = state.searchLists.findIndex((b) => b.id === arrayItem);
			objectIndex1 !== -1 && state.searchLists.splice(objectIndex1, 1);
		});
	},

	JOIN_ALLOWANCE_GROUP(state, payload) {
		payload.forEach((e) => {
			state.lazyListsGroup.push(e);
		});
	},

	JOIN_ALLOWANCE_TYPE_GROUP(state, payload) {
		let employee_id = payload.record.employee_id;
		let objectIndex = state.lazyListsGroup.findIndex((b) => b.employee_id === employee_id);
		if (objectIndex !== -1) {
			state.lazyListsGroup[objectIndex].allowances = [];
			state.lazyListsGroup[objectIndex].allowances = payload.allowances;
		}
	},

	JOIN_SEARCH_ALLOWANCE_TYPE_GROUP(state, payload) {
		let employee_id = payload.record.employee_id;
		let objectIndex = state.searchLists.findIndex((b) => b.employee_id === employee_id);

		state.searchLists[objectIndex].allowances = [];
		state.searchLists[objectIndex].allowances = payload.allowances;
	},

	SET_ALLOWANCE_FORM_STATE(state, payload) {
		state.displayAllowanceForm = payload;
	},

	REMOVE_EMPLOYEE_ALLOWANCES(state, payload) {
		console.log('REMOVE_EMPLOYEE_ALLOWANCES');
		payload.employee_id.forEach((arrayItem) => {
			// Get allowances bound to Employee ID
			let employeeAllowances = state.lazyListsGroup.filter(
				(lazyListsGroup) => lazyListsGroup.employee_id === arrayItem
			);
			console.log(employeeAllowances);
			// Iterate found employee allowances
			employeeAllowances.forEach((employeeAllowance) => {
				let objectIndex = state.lazyListsGroup.findIndex((b) => b.id === employeeAllowance.id);
				objectIndex !== -1 && state.lazyListsGroup.splice(objectIndex, 1);

				objectIndex = state.searchLists.findIndex((b) => b.id === employeeAllowance.id);
				objectIndex !== -1 && state.searchLists.splice(objectIndex, 1);
			});
		});
	},
	SET_RESET_ALLOWANCE_LAZY_LOAD(state, payload) {
		state.resetAllowanceLazyLoad = payload;
	},
	INSERT_EMPLOYEE_ALLOWANCE(state, payload) {
    if (state.employeeAllowanceLists.length) {
      let filterEmp = state.employeeAllowanceLists.filter(empList => empList.employee_id === payload.employee_id)
      let filterByAllowance = filterEmp.filter(empList2 => empList2.allowance_id === payload.allowance_id)
      if (filterByAllowance.length > 0) {
        state.employeeAllowanceLists.push(payload);
      } else {
        state.employeeAllowanceLists = payload;
      }
    } else {
      state.employeeAllowanceLists = payload;
    }

	},
};

const actions = {
	FETCH_ALLOWANCE({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/allowance',
				{
					success(response) {
						response.data.status === 'success' && commit('INSERT_EMPLOYEE_ALLOWANCE', response.data.data);

						resolve(response.data.data);
						console.log(response);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},
	FETCH_ALLOWANCE_BASED_ON_ALLOWANCE_ID({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/allowance',
				{
					success(response) {
						resolve(response.data.data);
						console.log(response);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},
	FIND_ALLOWANCE({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/allowance',
				{
					success(response) {
						response.data.status === 'success' && commit('SEARCH_ALLOWANCE', response.data.data);
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},
	LOOK_ALLOWANCE({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/allowance',
				{
					success(response) {
						response.data.status === 'success' && commit('SUGGEST_ALLOWANCE', response.data.data);
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},
	FIND_ALLOWANCE_DOWNLOAD({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/allowance',
				{
					success(response) {
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},
	FETCH_ALLOWANCE_LAZY_GROUP({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/allowance',
				{
					success(response) {
						response.data.status === 'success' && commit('JOIN_ALLOWANCE_GROUP', response.data.data);
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},

	SAVE_ALLOWANCE: ({ commit }, payload) => {
		return new Promise((resolve, reject) => {
			httpPost('juanHR/allowance', payload.form, {
				success(response) {
					if (response.data.status === 'success') {
						let tosave = response.data.data;
						tosave.name = payload.others.allowances;
						tosave.full_name = payload.others.full_name;
						tosave.sex = payload.others.sex;
						tosave.photo = payload.others.photo;
						tosave.allowances = [payload.others.allowances];
            commit('INSERT_EMPLOYEE_ALLOWANCE', tosave);
						commit('INSERT_ALLOWANCE', tosave);
					}
					resolve(response.data);
					console.log(response);
				},
				catch(response) {
					reject(response);
					console.log(response);
				},
			});
		});
	},

	UPLOAD_BULK_ALLOWANCE({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPost(`juanHR/allowance`, payload, {
				success(response) {
					if (response.data.status === 'success' && isObject(response.data.data)) {
						commit('INSERT_ALLOWANCE_BULK', response.data.data.inserted);
					} else if (response.data.status === 'success' && isObject(response.data)) {
						commit('INSERT_ALLOWANCE_BULK', response.data.data);
					}
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				},
			});
		});
	},

	UPDATE_ALLOWANCE({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPut(`juanHR/allowance/${payload.id}`, payload, {
				success(response) {
					if (response.data.status === 'success') {
						let tosave = response.data.data;
						// Add property name & allowance
						tosave.name = response.data.data.allowance_name;
						tosave.allowances = [response.data.data.allowance_name];
						/** Commit Data */
						commit('MODIFY_ALLOWANCE', tosave);
					}
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				},
			});
		});
	},

	DELETE_ALLOWANCE({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpDel(`juanHR/allowance/${payload.id}`, payload, {
				success(response) {
					console.log('this is payload');
					console.log(payload);
					console.log('this is payload');
					/** If response is succss commit to remove branch on vuex */
					response.data.status === 'success' && commit('REMOVE_ALLOWANCE', payload);
					/** resolve promise */
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				},
			});
		});
	},

	DELETE_EMPLOYEE_ALLOWANCES({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpDel(`juanHR/allowance/${payload.employee_id}`, payload, {
				success(response) {
					/** If response is succss commit to remove branch on vuex */
					response.data.status === 'success' && commit('REMOVE_EMPLOYEE_ALLOWANCES', payload);
					/** resolve promise */
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				},
			});
		});
	},
	TOGGLE_ALLOWANCE_DELETE({ commit }, payload) {
		commit('TOGGLE_ALLOWANCE_DELETE');
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
