import { httpGet, httpPost, httpPut, httpDel } from 'boot/axios';

const state = {
	lists: [],
  advanceSearchYear: null,
  advanceSearchMonth: null
};

const getters = {
	GET_THIRTEENTH_MONTH_CONFIGURATIONS: state => {
		return state.lists;
	},
  GET_ADVANCE_SEARCH_YEAR: state => {
    return state.advanceSearchYear;
  },
  GET_ADVANCE_SEARCH_MONTH: state => {
    return state.advanceSearchMonth;
  }
};

const mutations = {
	SET_CONFIGURATION(state, payload) {
		state.lists = payload;
	},
	INSERT_CONFIGURATIONS(state, payload) {
		state.lists.push(payload);
	},
  SET_ADVANCE_SEARCH_YEAR(state, payload) {
    state.advanceSearchYear = payload;
  },
  SET_ADVANCE_SEARCH_MONTH(state, payload) {
    state.advanceSearchMonth = payload;
  }
};

const actions = {
	FETCH_THIRTEENTH_MONTH_CONFIGURATIONS(context, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/thirteenthMonthConfig',
				{
					success:(response) => {
						if (response.data.status === 'success');
						context.commit("SET_CONFIGURATION", response.data.data);

						resolve(response.data);
					},

          catch:(response) =>{
            console.log(response);
          }
				},
				payload
			);
		});
	},

  SAVE_THIRTEENTH_MONTH_CONFIGURATION: (context, payload) => {
    return new Promise((resolve, reject) => {
      httpPost('juanHR/thirteenthMonthConfig', payload, {
        success: (response) => {
          if (response.data.status === 'success') {
            context.commit("INSERT_CONFIGURATIONS", response.data.data);

            resolve(response.data);
          }
        },
        catch: (response) => {
          reject(response.data);
          console.log(response);
        }
      });
    });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
