import { httpGet, httpPost, httpDel, httpPut } from 'boot/axios';

const state = {
	templates: []
};

const getters = {
	GET_SCHEDULE_TEMPLATES: state => {
		return state.templates;
	}
};

const mutations = {
	SET_SCHEDULE_TEMPLATES(state, payload) {
		// Convert rest days string into array
		payload.forEach(template => {
			template.restdays = template.restdays.map(Number);
		});
		state.templates = payload;
	},

	INSERT_SCHEDULE_TEMPLATE(state, payload) {
		payload.restdays = payload.restdays.map(Number);
		state.templates.unshift(payload);
	},

	MODIFY_SCHEDULE_TEMPLATE(state, payload) {
		let objectIndex = state.templates.findIndex(b => b.schedule_template_id === payload.schedule_template_id);
		Object.keys(state.templates[objectIndex]).forEach(key => {
			state.templates[objectIndex][key] = payload[key];
		});
	},

	REMOVE_SCHEDULE_TEMPLATE(state, payload) {
		let objectIndex = state.templates.findIndex(b => b.schedule_template_id === payload.schedule_template_id);
		objectIndex !== -1 && state.templates.splice(objectIndex, 1);
	}
};

const actions = {
	FETCH_SCHEDULE_TEMPLATES({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet('juanHR/scheduleConfiguration', {
				success(response) {
					response.data.status === 'success' && commit('SET_SCHEDULE_TEMPLATES', response.data.data);
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				}
			});
		});
	},

	SAVE_SCHEDULE_CONFIGURATION({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPost('juanHR/scheduleConfiguration', payload, {
				success(response) {
					response.data.status === 'success' && commit('INSERT_SCHEDULE_TEMPLATE', response.data.data);
					resolve(response.data);
				},
				catch(response) {}
			});
		});
	},

	UPDATE_SCHEDULE_CONFIGURATION({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPut(`juanHR/scheduleConfiguration/${payload.schedule_template_id}`, payload, {
				success(response) {
					response.data.status === 'success' && commit('MODIFY_SCHEDULE_TEMPLATE', response.data.data);
					resolve(response.data);
				},
				catch(response) {}
			});
		});
	},

	DELETE_SCHEDULE_CONFIGURATION({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpDel(`juanHR/scheduleConfiguration/${payload.schedule_template_id}`, payload, {
				success(response) {
					/** If response is succss commit to remove branch on vuex */
					response.data.status === 'success' && commit('REMOVE_SCHEDULE_TEMPLATE', payload);
					/** resolve promise */
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				}
			});
		});
	}
};

export default { namespaced: true, state, getters, mutations, actions };
