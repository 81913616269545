/* This function will check if the argument is object {}
    console.log(isObject({}));             // Will return: true
    console.log(isObject([]));             // Will return: false
    console.log(isObject(null));           // Will return: false
    console.log(isObject(function () {})); // Will return: false
*/
export const isObject = function(o) {
	return o instanceof Object && o.constructor === Object;
};

/** Transfer Words String into Capitalize */
export const transformToCapitalize = function(str) {
	str = str.split(' ');

	for (var i = 0, x = str.length; i < x; i++) {
		str[i] = str[i][0].toUpperCase() + str[i].substr(1);
	}

	return str.join(' ');
};

/** Local Storage SET Function */
export const setLocalStorage = function(key, value, vueInstance) {
	vueInstance.$q.localStorage.set(key, value);
};

/** Local Storage GET Functions */
export const getLocalStorage = function(key, value, vueInstance) {
	vueInstance.$q.localStorage.getItem(key, value);
};

/** Local Storage DELETE Functions */
export const deleteLocalStorage = function(key, value, vueInstance) {
	vueInstance.$q.localStorage.remove(key, value);
};
