import Dashboard from './modules/dashboard/Dashboard';
import AllowanceConfiguration from './modules/Allowance/Configuration';
import Allowance from './modules/Allowance/Allowance';
import Biometric from './modules/Biometrics/Biometric';
import Device from './modules/Biometrics/Device';
import Leaves from './Modules/Leave/Leave';
import LeaveCredits from './Modules/Leave/LeaveCredits';
import LeaveCreditsDistribution from './Modules/Leave/LeaveCreditsDistribution';
import LeaveType from './Modules/Leave/LeaveType';
import LeaveApproval from './Modules/Leave/LeaveApproval';
import BiometricsConfiguration from './modules/Biometrics/BiometricsConfiguration';
import EmployeeInformation from './modules/Employee/Employee';
import EmployeeFamilyBackground from './modules/Employee/Family';
import EmployeeEducationBackground from './modules/Employee/Education';
import EmployeeCivilService from './modules/Employee/CivilService';
import EmployeeWorkExperience from './modules/Employee/WorkExperience';
import EmployeeVoluntaryWork from './modules/Employee/VoluntaryWork';
import EmployeeLearningDevelopment from './modules/Employee/LearningDevelopment';
import EmployeeOtherSpecialInformation from './modules/Employee/Others';
import EmployeeFile201 from './modules/Employee/Files201';
import Images from './ui/Images';
import Branches from './modules/masterlists/branch';
import Departments from './modules/masterlists/department';
import Holiday from './modules/OvertimeAndHolidays/Holidays';
import Overtime from './modules/OvertimeAndHolidays/Overtime';
import MemosAndNotices from './modules/MemosAndNotices/MemosAndNotices';
import ScheduleTemplates from './modules/schedules/ScheduleTemplates';
import Schedules from './modules/schedules/Schedules';
import BranchAssignment from './modules/BranchAssignments/BranchAssignments';
import Position from './modules/ServiceRecord/Position';
import ServiceRecord from './modules/ServiceRecord/ServiceRecord';
import BenefitsPhilhealthConfiguration from './modules/Benefits/PhilhealthConfiguration';
import BenefitsPagibigConfiguration from './modules/Benefits/PagIBIGConfiguration';
import BenefitsGsisConfiguration from './modules/Benefits/GSISConfiguration';
import BenefitsSssConfiguration from './modules/Benefits/SSSConfiguration';
import BenefitsInformation from './modules/Benefits/Benefits';
import BenefitsForm from './modules/Benefits/BenefitForms';
import LoanConfiguration from './modules/Loan/Configuration';
import Loan from './modules/Loan/Loan';
import DTR from './modules/DTR/Dtr';
import Adjustments from './modules/AdjustmentHandling/Adjustments';
import AdjustmentConfiguration from './modules/AdjustmentHandling/AdjustmentConfiguration';
import PayrollConfiguration from './Modules/Payroll/Configuration';
import PayrollInformation from './modules/Payroll/PayrollInformation';
import PayrollReports from './modules/Payroll/Reports';
import CoreStorage from './ui/CoreStorage';
import OrganizationManagement from './modules/OrganizationManagement/OrganizationManagement';
import ThirteenMonthPayConfiguration from './modules/ThirteenMonthPay/Configuration';
import ThirteenMonthPayInformation from './modules/ThirteenMonthPay/Information';
import Unit from './modules/masterlists/unit';

const StoreModules = {
	JuanHR: {
		namespaced: true,
		modules: {
			Employee: {
				namespaced: true,
				modules: {
					/** Name Spaced getters['JuanHR/Employee/Information'] */

					Information: EmployeeInformation,

					/** Name Spaced getters['JuanHR/Employee/FamilyBackground'] */
					FamilyBackground: EmployeeFamilyBackground,

					/** Name Spaced getters['JuanHR/Employee/EducationBackground'] */
					EducationBackground: EmployeeEducationBackground,

					/** Name Spaced getters['JuanHR/Employee/CivilService'] */
					CivilService: EmployeeCivilService,

					/** Name Spaced getters['JuanHR/Employee/WorkExperience'] */
					WorkExperience: EmployeeWorkExperience,

					/** Name Spaced getters['JuanHR/Employee/VoluntaryWork'] */
					VoluntaryWork: EmployeeVoluntaryWork,

					/** Name Spaced getters['JuanHR/Employee/LearningDevelopment'] */
					LearningDevelopment: EmployeeLearningDevelopment,

					/** Name Spaced getters['JuanHR/Employee/OtherSpecialInformation'] */
					OtherSpecialInformation: EmployeeOtherSpecialInformation,

					/** Name Spaced getters['JuanHR/Employee/File201'] */
					File201: EmployeeFile201,
				},
			},
			// Namespace: 'JuanHR/Dashboard'
			Dashboard,
			Images,
			/** Name Spaced getters['JuanHR/Branches'] */

			CoreStorage,
			// Namespace: 'JuanHR/JuanStorage'
			// Namespace: 'JuanHR/Scheduling'
			Scheduling: {
				namespaced: true,

				modules: {
					// Namespace: 'JuanHR/Scheduling/Information'
					Information: Schedules,

					// Namespace: 'JuanHR/Scheduling/Templates'
					Templates: ScheduleTemplates,
				},
			},
			/** Name Spaced getters['JuanHR/Branches'] */
			Branches,
      /** Name Spaced getters['JuanHR/Unit'] */
      Unit,
			/** Name Spaced getters['JuanHR/Departments'] */
			Departments,
			/** Namespaced getters['JuanHR/Holiday'] */
			Holiday,
			/** Namespaced getters['JuanHR/Overtime'] */
			Overtime,
			// Namespace: 'JuanHR/MemosAndNotices'
			MemosAndNotices,

			BranchAssignment,

			Allowance: {
				namespaced: true,
				modules: {
					/** Name Spaced getters['JuanHR/Allowance/Information'] */
					Information: Allowance,

					/** Name Spaced getters['JuanHR/Allowance/Configuration'] */
					Configuration: AllowanceConfiguration,
				},
			},
			// Namespace: 'JuanHR/Biometric'
			Biometric: {
				namespaced: true,
				modules: {
					/** Name Spaced getters['JuanHR/Biometric/Information'] */
					Information: Biometric,

          /** Name Spaced getters['JuanHR/Biometric/Configuration'] */
          Configuration: BiometricsConfiguration,

					/** Name Spaced getters['JuanHR/Biometric/Device'] */
					Device: Device,
				},
			},
			ServiceRecord: {
				namespaced: true,
				modules: {
					/** Name Spaced getters['JuanHR/ServiceRecord/Information'] */
					Information: ServiceRecord,

					/** Name Spaced getters['JuanHR/ServiceRecord/Position'] */
					Position: Position,
				},
			},
			Leave: {
				namespaced: true,
				modules: {
					/** Name Spaced getters['JuanHR/Leave/Information'] */
					Information: Leaves,

					/** Name Spaced getters['JuanHR/Leave/Credits'] */
					Credits: LeaveCredits,

					/** Name Spaced getters['JuanHR/Leave/Distribution'] */
					Distribution: LeaveCreditsDistribution,

					/** Name Spaced getters['JuanHR/Leave/Type'] */
					Type: LeaveType,

					/** Name Spaced getters['JuanHR/Leave/Approval'] */
					Approval: LeaveApproval,
				},
			},
			// Namespace: 'JuanHR/Benefits'
			Benefits: {
				namespaced: true,

				modules: {
					// Namespace: 'JuanHR/Benefits/Information'
					Information: BenefitsInformation,
					// Namespace: 'JuanHR/Benefits/PdfForms'
					PdfForms: BenefitsForm,
					// Namespace: 'JuanHR/Benefits/Configuration'
					Configuration: {
						namespaced: true,

						modules: {
							// Namespace: 'JuanHR/Benefits/Configuration/SSS'
							SSS: BenefitsSssConfiguration,

							// Namespace: 'JuanHR/Benefits/Configuration/Philhealth'
							Philhealth: BenefitsPhilhealthConfiguration,

							// Namespace: 'JuanHR/Benefits/Configuration/PagIBIG'
							PagIBIG: BenefitsPagibigConfiguration,

							// Namespace: 'JuanHR/Benefits/Configuration/GSIS'
							GSIS: BenefitsGsisConfiguration,
						},
					},
				},
			},
			Loan: {
				namespaced: true,
				modules: {
					/** Name Spaced getters['JuanHR/Loan/Information'] */
					Information: Loan,

					/** Name Spaced getters['JuanHR/Loan/Configuration'] */
					Configuration: LoanConfiguration,
				},
			},
			// Namespace: 'JuanHR/DTR'
			DTR,
			Adjustments: {
				namespaced: true,

				modules: {
					// Namespace: 'JuanHR/Adjustments/Configuration'
					Configuration: AdjustmentConfiguration,

					// Namespace: 'JuanHR/Adjustments/Information'
					Information: Adjustments,
				},
			},
			// Namespace: 'JuanHR/OrganizationManagement'
			OrganizationManagement,
			// Namespace: 'JuanHR/Payroll'
			Payroll: {
				namespaced: true,
				modules: {
					// Namespace: 'JuanHR/Payroll/Information'
					Information: PayrollInformation,

					Configuration: PayrollConfiguration,

          Reports: PayrollReports
        }
      },

      ThirteenMonth: {
				namespaced: true,
				modules: {
					// Namespace: 'JuanHR/ThirteenMonth/Information'
					Information: ThirteenMonthPayInformation,

          // Namespace: 'JuanHR/ThirteenMonth/Configuration'
					Configuration: ThirteenMonthPayConfiguration,
        }
      },
		},
	},
};

export default StoreModules;
