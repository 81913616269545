import { httpGet, httpPost, httpPut, httpDel } from 'boot/axios';

const state = {
	lists: [],
};

const getters = {
	GET_CONFIGURATIONS: (state) => {
		return state.lists;
	},
};

const mutations = {
	SET_CONFIGURATION(state, payload) {
		state.lists = payload;
	},
	INSERT_CONFIGURATIONS(state, payload) {
		state.lists.push(payload);
	},
};

const actions = {
	FETCH_CONFIGURATIONS(context, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/payrollConfiguration',
				{
					success: (response) => {
						if (response.data.status === 'success');
						context.commit('SET_CONFIGURATION', response.data.data);

						resolve(response.data);
					},

					catch: (response) => {
						console.log(response);
					},
				},
				payload
			);
		});
	},

	SAVE_CONFIGURATION: (context, payload) => {
		return new Promise((resolve, reject) => {
			httpPost('juanHR/payrollConfiguration', payload, {
				success: (response) => {
					if (response.data.status === 'success') {
						context.commit('INSERT_CONFIGURATIONS', response.data.data);

						resolve(response.data);
						console.log('Resolve Value', response);
					}
				},
				catch: (response) => {
					reject(response.data);
					console.log(response);
				},
			});
		});
	},
};

export default { state, getters, mutations, actions, namespaced: true };
