import { httpGet, httpPost, httpDel, httpPut } from 'boot/axios';
const state = {
	orgStructure: [],
};

const getters = {
	GET_ORGANIZATION_MANAGEMENT: state => {
		return state.orgStructure;
	}
};

const mutations = {
	BUILD_ORGANIZATION_MANAGEMENT(state, payload) {
	state.orgStructure = [];
  payload.forEach(branch => {

    /* Build Structure for Branch */
    let branchIndex = state.orgStructure.findIndex(a => a.id === branch.branch_id);
    if (branchIndex === -1) {
      branchIndex = state.orgStructure.push({
        name: branch.name,
        title: 'Branch',
        id: branch.branch_id,
        children: []
      });
      branchIndex--;
    }

    /* Build Structure for Department */
    let deptIndex = state.orgStructure[branchIndex]['children'].findIndex(a => a.department_id === branch.department_id)
    if (deptIndex === -1 && branch.department_id !== null) {
      deptIndex = state.orgStructure[branchIndex]['children'].push({
        name: branch.department_name,
        title: 'Department',
        department_id: branch.department_id,
        children: []
      });
      deptIndex--;
    }

    /* Build Structure for Unit */
    if (typeof state.orgStructure[branchIndex]['children'][deptIndex] !== 'undefined') {
      let unitIndex = state.orgStructure[branchIndex]['children'][deptIndex]['children'].findIndex(a => a.unit_id === branch.unit_id)
      if (unitIndex === -1 && branch.unit_id !== null) {
        unitIndex = state.orgStructure[branchIndex]['children'][deptIndex]['children'].push({
          name: branch.unit_name,
          unit_id: branch.unit_id,
          title: 'Unit',
          children: []
        });
        unitIndex--;
      }

      /* Build Structure for Position */
      if (typeof state.orgStructure[branchIndex]['children'][deptIndex]['children'][unitIndex] !== 'undefined') {
        let positionIndex = state.orgStructure[branchIndex]['children'][deptIndex]['children'][unitIndex]['children'].findIndex(a => a.position_id === branch.position_id)
        if (positionIndex === -1 && branch.position_id !== null ){
          positionIndex = state.orgStructure[branchIndex]['children'][deptIndex]['children'][unitIndex]['children'].push({
            name: branch.position_name,
            position_id: branch.position_id,
            title: 'Position',
          });
          positionIndex--;
        }
      }
    }
  })
	}
};

const actions = {
	FETCH_ORGANIZATION_MANAGEMENT({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/organizationManagement',
				{
					success(response) {
						response.data.status === 'success' &&
							commit('BUILD_ORGANIZATION_MANAGEMENT', response.data.data);
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					}
				},
				payload
			);
		});
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};
