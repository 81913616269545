import { httpGet, httpPost, httpPut, httpDel } from 'boot/axios';

const state = {
	lists: [],
	lazyLists: [],
	deleteEnabled: false,
	displayLeaveApprovalForm: false,
};

const getters = {
	GET_LEAVE_APPROVAL: (state) => {
		return state.lists;
	},

	GET_LEAVE_APPROVAL_LAZY(state) {
		return state.lazyLists;
	},

	GET_LEAVE_APPROVAL_FORM_STATE: (state) => {
		return state.displayLeaveApprovalForm;
	},
	GET_LEAVE_APPROVAL_DELETE_STATE: (state) => {
		return state.deleteEnabled;
	},
};

const mutations = {
	TOGGLE_LEAVE_APPROVAL_DELETE(state, payload) {
		state.deleteEnabled = state.deleteEnabled !== true;
	},
	SET_LEAVE_APPROVAL(state, payload) {
		state.lists = payload;
	},
	INSERT_LEAVE_APPROVAL(state, payload) {
		let exist = state.lists.filter((ba) => ba.id === Number(payload.id));
		!exist.length && state.lists.unshift(payload);
		!exist.length && state.lazyLists.unshift(payload);
	},

	JOIN_LEAVE_APPROVAL(state, payload) {
		payload.forEach((e) => {
			state.lazyLists.push(e);
		});
	},

	SET_LEAVE_APPROVAL_FORM_STATE(state, payload) {
		state.displayLeaveApprovalForm = payload;
	},

	MODIFY_LEAVE_APPROVAL(state, payload) {
		let objectIndex = state.lists.findIndex((e) => e.id === payload.id);
		objectIndex !== -1 &&
			Object.keys(state.lists[objectIndex]).forEach((key) => {
				state.lists[objectIndex][key] = payload[key];
			});

		let objectIndexLazy = state.lazyLists.findIndex((e) => e.id === payload.id);
		objectIndexLazy !== -1 &&
			Object.keys(state.lazyLists[objectIndexLazy]).forEach((key) => {
				state.lazyLists[objectIndexLazy][key] = payload[key];
			});
	},

	REMOVE_LEAVE_APPROVAL(state, payload) {
		let objectIndex = state.lists.findIndex((e) => e.id === payload.id);
		objectIndex !== -1 && state.lists.splice(objectIndex, 1);
	},

	REMOVE_EMPLOYEE_LEAVE_APPROVAL(state, payload) {
		payload.id.forEach((arrayItem) => {
			// Get leaves bound to Employee ID
			let employeeLeavesConfig = state.lists.filter((lists) => lists.id === arrayItem);
			console.log(employeeLeavesConfig);
			// Iterate found employee leaves
			employeeLeavesConfig.forEach((employeeLeavesConfigs) => {
				let objectIndex = state.lists.findIndex((b) => b.id === employeeLeavesConfigs.id);
				objectIndex !== -1 && state.lists.splice(objectIndex, 1);
			});

			// Get leaves bound to Employee ID
			let employeeLeavesApprovals = state.lazyLists.filter((lazyLists) => lazyLists.id === arrayItem);
			console.log(employeeLeavesApprovals);
			// Iterate found employee leaves
			employeeLeavesApprovals.forEach((employeeLeavesApprovals) => {
				let objectIndex = state.lazyLists.findIndex((b) => b.id === employeeLeavesApprovals.id);
				objectIndex !== -1 && state.lazyLists.splice(objectIndex, 1);
			});
		});
	},
};

const actions = {
	FETCH_LEAVE_APPROVALS({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/leaveApprovals',
				{
					success(response) {
						response.data.status === 'success' && commit('SET_LEAVE_APPROVAL', response.data.data);
						resolve(response.data);
						console.log(response);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},

	FETCH_LEAVE_APPROVAL_LAZY({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/leaveApprovals',
				{
					success(response) {
						/** Commit Data */
						response.data.status === 'success' && commit('JOIN_LEAVE_APPROVAL', response.data.data);
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},

	SAVE_LEAVE_APPROVAL: ({ commit }, payload) => {
		return new Promise((resolve, reject) => {
			httpPost('juanHR/leaveApprovals', payload, {
				success(response) {
					response.data.status === 'success' && commit('INSERT_LEAVE_APPROVAL', response.data.data);
					resolve(response.data);
					console.log(response);
				},
				catch(response) {
					reject(response.data);
					console.log(response);
				},
			});
		});
	},

	UPDATE_LEAVE_APPROVAL({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPut(`juanHR/leaveApprovals/${payload.id}`, payload, {
				success(response) {
					/** Commit Data */
					response.data.status === 'success' && commit('MODIFY_LEAVE_APPROVAL', response.data.data);
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				},
			});
		});
	},

	DELETE_LEAVE_APPROVAL({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpDel(
				`juanHR/leaveApprovals/${payload.id}`,
				{
					id: [payload.id],
				},
				{
					success(response) {
						/** If response is succss commit to remove branch on vuex */
						response.data.status === 'success' && commit('REMOVE_LEAVE_APPROVAL', payload);
						/** resolve promise */
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				}
			);
		});
	},

	DELETE_EMPLOYEE_LEAVE_APPROVAL({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpDel(`juanHR/leaveApprovals/${payload.id}`, payload, {
				success(response) {
					/** If response is succss commit to remove branch on vuex */
					response.data.status === 'success' && commit('REMOVE_EMPLOYEE_LEAVE_APPROVAL', payload);
					/** resolve promise */
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				},
			});
		});
	},
	TOGGLE_LEAVE_APPROVAL_DELETE({ commit }, payload) {
		commit('TOGGLE_LEAVE_APPROVAL_DELETE');
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
