import { httpGet, httpFileDownload, httpDel, httpFileUpload } from 'boot/axios';

const state = {
	lists: [],
};

const getters = {
	GET_FILES201(state) {
		return state.lists;
	},
};

const mutations = {
	SET_FILES201(state, payload) {
		state.lists = payload;
	},

	INSERT_FILES201(state, payload) {
		state.lists.unshift(payload);
	},

	REMOVE_FILES201(state, payload) {
		let objectIndex = state.lists.findIndex((e) => e.id === payload.id);
		objectIndex !== -1 && state.lists.splice(objectIndex, 1);
	},
};

const actions = {
	FETCH_FILE201({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/employeeFiles201',
				{
					success(response) {
						response.data.status === 'success' && commit('SET_FILES201', response.data.data);
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},

	DOWNLOAD_FILE201({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpFileDownload(`juanHR/employeeFiles201`, payload, {
				success(response) {
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				},
			});
		});
	},

	DELETE_FILE201({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpDel(
				`juanHR/employeeFiles201/${payload.id}`,
				{
					id: [payload.id],
				},
				{
					success(response) {
						response.data.status === 'success' && commit('REMOVE_FILES201', payload);
						resolve(response.data);
					},
					catch(response) {},
				}
			);
		});
	},

	UPLOAD_FILES_201({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpFileUpload(`juanHR/employeeFiles201/${payload.id}`, payload, {
				success(response) {
					response.data.status === 'success' && commit('INSERT_FILES201', response.data.data);
					resolve(response.data);
				},
				catch(response) {},
			});
		});
	},
};
export default { namespaced: true, state, getters, mutations, actions };
