import { httpGet, httpPut, httpPost, httpDel, httpFileUpload, httpFileDownload } from 'boot/axios';
const state = {
	lists: [],
	searchLists: [],
	lazyLists: [],
	editMode: false,
	displayAvatar: false,
	activeButton: {
		family: false,
		education: false,
		civil_service: false,
		work_experience: false,
		voluntary_work: false,
		others: false,
		learning_development: false,
	},

	activeForm: {
		family: false,
		education: false,
		civil_service: false,
		work_experience: false,
		voluntary_work: false,
		others: false,
		learning_development: false,
	},

	searchResults: [],
	suggestResults: [],
	searchParams: {},
	activeEmployee: {},

	triggers: {
		displayBulkDelete: false,
	},
};

const getters = {
	GET_EMPLOYEES(state) {
		return state.lists;
	},
	GET_SAVED_SEARCHES_EMPLOYEES: (state) => {
		return state.searchLists;
	},
	GET_EMPLOYEES_LAZY(state) {
		return state.lazyLists;
	},

	GET_FORM_MODE(state) {
		return state.editMode;
	},

	GET_ADD_BUTTON_STATE(state) {
		return state.activeButton;
	},

	GET_EMPLOYEE_FORMS_STATES(state) {
		return state.activeForm;
	},

	GET_AVATAR_STATE(state) {
		return state.displayAvatar;
	},

	GET_SEARCH_RESULTS(state) {
		return state.searchResults;
	},

	GET_SEUGGEST_RESULTS(state) {
		return state.suggestResults;
	},

	GET_SEARCH_PARAMS(state) {
		return state.searchParams;
	},

	GET_ACTIVE_EMPLOYEE(state) {
		return state.activeEmployee;
	},

	GET_GLOBAL_TRIGGERS(state) {
		return state.triggers;
	},
};

const mutations = {
	SET_EMPLOYEE(state, payload) {
		state.lists = payload;
	},
	PUSH_SAVED_SEARCHES_EMPLOYEES(state, payload) {
		payload.forEach((s) => {
			let index = state.searchLists.findIndex((e) => {
				return typeof e !== 'undefined' && e.employee_id == s.employee_id;
			});
			if (index < 0) {
				state.lists.push(s);
			}
		});
	},

	INSERT_EMPLOYEE(state, payload) {
		console.log('payload', payload);
		console.log('state', state);
		payload.forEach((emp) => {
			state.lists.unshift(emp);
			state.lazyLists.unshift(emp);
		});
	},

	MODIFY_EMPLOYEE(state, payload) {
		/** Delete Employee ID, not using for modifications */
		let employeeId = payload.employee_id;

		delete payload.employee_id;

		let objectIndex = state.lists.findIndex((e) => e.employee_id === Number(employeeId));

		objectIndex !== -1 &&
			Object.keys(state.lists[objectIndex]).forEach((key) => {
				payload[key] && (state.lists[objectIndex][key] = payload[key]);
			});

		let objectIndex2 = state.lazyLists.findIndex((e) => e.employee_id === Number(employeeId));

		objectIndex2 !== -1 &&
			Object.keys(state.lazyLists[objectIndex2]).forEach((key) => {
				if (payload[key]) {
					state.lazyLists[objectIndex2][key] = payload[key];
				}
			});
	},

	REMOVE_EMPLOYEE(state, payload) {
		payload.forEach((employee_id) => {
			let objectIndex = state.lists.findIndex((e) => e.employee_id === employee_id);
			objectIndex !== -1 && state.lists.splice(objectIndex, 1);

			objectIndex = state.lazyLists.findIndex((e) => e.employee_id === employee_id);
			objectIndex !== -1 && state.lazyLists.splice(objectIndex, 1);

			objectIndex = state.searchResults.findIndex((e) => e.employee_id === employee_id);
			objectIndex !== -1 && state.searchResults.splice(objectIndex, 1);
		});
	},

	SET_EMPLOYEE_FAMILY_MEMBERS(state, payload) {
		let objectIndex = state.lists.findIndex((e) => e.employee_id === payload.employee_id);
		objectIndex !== -1 && (state.lists[objectIndex].family_members = payload.family_members);
	},

	JOIN_EMPLOYEE(state, payload) {
		payload.forEach((e) => {
			state.lazyLists.push(e);
		});
	},

	SET_FORM_MODE(state, payload) {
		state.editMode = payload;
	},

	SET_ACTIVE_FORM(state, payload) {
		state.activeForm[payload.key] = payload.value;
	},

	SET_ACTIVE_BUTTON(state, payload) {
		state.activeButton[payload.key] = payload.value;
	},

	SET_AVATAR_STATE(state, payload) {
		state.displayAvatar = payload;
	},

	SET_SEARCH_RESULTS(state, payload) {
		state.searchResults = payload;
	},

	JOIN_SEARCH_RESULTS(state, payload) {
		payload.forEach((search) => {
			let index = state.searchResults.findIndex((s) => s.employee_id === search.employee_id);
			index === -1 && state.searchResults.push(search);
		});
	},

	SET_SUGGEST_RESULTS(state, payload) {
		state.suggestResults = payload;
	},

	SET_SEARCH_PARAMS(state, payload) {
		state.searchParams = payload;
	},

	SET_ACTIVE_EMPLOYEE(state, payload) {
		state.activeEmployee = payload;
	},

	SET_GLOBAL_TRIGGERS(state, payload) {
		state.triggers[payload.key] = payload.value;
	},
};

const actions = {
	FETCH_EMPLOYEE({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/employee',
				{
					success(response) {
						/** Commit Data */
						response.data.status === 'success' && commit('SET_EMPLOYEE', response.data.data);
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},
	FETCH_EMPLOYEE_MAX_ID({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/employee',
				{
					success(response) {
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},
	FETCH_EMPLOYEE_LAZY({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/employee',
				{
					success(response) {
						/** Commit Data */
						response.data.status === 'success' && commit('JOIN_EMPLOYEE', response.data.data);
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},

	FETCH_EMPLOYEE_AVATAR({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/employee',
				{
					success(response) {
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},

	SAVE_EMPLOYEE({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPost(`juanHR/employee`, payload, {
				success(response) {
					/** Commit Data */
					if (response.data.status === 'success') {
						let data = Array.isArray(response.data.data) ? response.data.data : [response.data.data];
						commit('INSERT_EMPLOYEE', data);
					}
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				},
			});
		});
	},

	UPDATE_EMPLOYEE({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPut(`juanHR/employee/${payload.employee_id}`, payload, {
				success(response) {
					/** Commit Data */
					response.data.status === 'success' && commit('MODIFY_EMPLOYEE', response.data.data);
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				},
			});
		});
	},

	DELETE_EMPLOYEE({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpDel(
				`juanHR/employee/${payload}`,
				{
					employee_id: payload,
				},
				{
					success(response) {
						response.data.status === 'success' && commit('REMOVE_EMPLOYEE', payload);
						resolve(response.data);
					},
					catch(response) {},
				}
			);
		});
	},

	UPLOAD_EMPLOYEE_AVATAR({ commit }, payload) {
		console.log('payload ', payload);
		return new Promise((resolve, reject) => {
			httpFileUpload(`juanHR/employee/${payload.id}`, payload, {
				success(response) {
					let employee_id = payload.get('employee_id');
					response.data.status === 'success' &&
						commit('MODIFY_EMPLOYEE', {
							employee_id,
							photo: response.data.data.base64,
						});
					resolve(response.data);
				},
				catch(response) {},
			});
		});
	},

	SEARCH_EMPLOYEE({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/employee',
				{
					success(response) {
						if (response.data.status === 'success') {
							/** Commit Data */
							commit('PUSH_SAVED_SEARCHES_EMPLOYEES', response.data.data);
							if (typeof payload.formSearch === 'undefined') {
								commit(
									!payload.offset ? 'SET_SEARCH_RESULTS' : 'JOIN_SEARCH_RESULTS',
									response.data.data
								);
							}
						}
						resolve(response.data);
					},
					catch(response) {},
				},
				payload
			);
		});
	},

	SUGGEST_EMPLOYEE({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/employee',
				{
					success(response) {
						/** Commit Data */
						response.data.status === 'success' && commit('SET_SUGGEST_RESULTS', response.data.data);
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},

	FETCH_EMPLOYEE_PDS({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpFileDownload(
				'juanHR/employee',
				payload,
				{
					success(response) {
						/** Commit Data */
						response.data.status === 'success' && commit('SET_EMPLOYEE', response.data.data);
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				}
				// payload
			);
		});
	},
};

export default { namespaced: true, state, getters, mutations, actions };
