import { httpGet, httpPut, httpPost, httpDel } from 'boot/axios';

const state = {
	lists: []
};
const getters = {
	GET_EMPLOYEE_LEARNING_DEVELOPMENT(state) {
		return state.lists;
	}
};
const mutations = {
	SET_EMPLOYEE_LEARNING_DEVELOPMENT(state, payload) {
		state.lists = payload;
	},

	INSERT_EMPLOYEE_LEARNING_DEVELOPMENT(state, payload) {
		state.lists.unshift(payload);
	},

	MODIFY_EMPLOYEE_LEARNING_DEVELOPMENT(state, payload) {
		let objectIndex = state.lists.findIndex(e => e.id === payload.id);
		objectIndex !== -1 &&
			Object.keys(state.lists[objectIndex]).forEach(key => {
				state.lists[objectIndex][key] = payload[key];
			});
	},

	REMOVE_EMPLOYEE_LEARNING_DEVELOPMENT(state, payload) {
		let objectIndex = state.lists.findIndex(e => e.id === payload.id);
		objectIndex !== -1 && state.lists.splice(objectIndex, 1);
	}
};

const actions = {
	FETCH_EMPLOYEE_LEARNING_DEVELOPMENT({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/employeeLearningDevelopment',
				{
					success(response) {
						response.data.status === 'success' &&
							commit('SET_EMPLOYEE_LEARNING_DEVELOPMENT', response.data.data);

						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					}
				},
				payload
			);
		});
	},

	SAVE_EMPLOYEE_LEARNING_DEVELOPMENT({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPost(`juanHR/employeeLearningDevelopment`, payload, {
				success(response) {
					response.data.status === 'success' &&
						commit('INSERT_EMPLOYEE_LEARNING_DEVELOPMENT', response.data.data);
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				}
			});
		});
	},

	UPDATE_EMPLOYEE_LEARNING_DEVELOPMENT({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPut(`juanHR/employeeLearningDevelopment/${payload.id}`, payload, {
				success(response) {
					response.data.status === 'success' &&
						commit('MODIFY_EMPLOYEE_LEARNING_DEVELOPMENT', response.data.data);
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				}
			});
		});
	},

	DELETE_EMPLOYEE_LEARNING_DEVELOPMENT({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpDel(
				`juanHR/employeeLearningDevelopment/${payload.id}`,
				{
					id: [payload.id]
				},
				{
					success(response) {
						response.data.status === 'success' && commit('REMOVE_EMPLOYEE_LEARNING_DEVELOPMENT', payload);
						resolve(response.data);
					},
					catch(response) {}
				}
			);
		});
	}
};
export default { namespaced: true, state, getters, mutations, actions };
