import { httpGet, httpPost, httpDel, httpPut } from 'boot/axios';

const state = {
	lists: [],
	searchLists: [],
};
const getters = {
	GET_BRANCH_DEPARTMENT(state) {
		return state.lists;
	},
	GET_SAVED_SEARCHES_BRANCHES_DEPARTMENTS(state) {
		return state.searchLists;

		console.log('Heeya', this.state.searchLists);
	},
};
const mutations = {
	SET_BRANCH_DEPARTMENT(state, payload) {
		state.lists = payload;
	},
	PUSH_SAVED_SEARCHES_BRANCHES_DEPARTMENTS(state, payload) {
		state.searchLists = state.searchLists.concat(payload);
	},
	INSERT_BRANCH_DEPARTMENT(state, payload) {
		state.lists.unshift(payload);
	},

	MODIFY_BRANCH_DEPARTMENT(state, payload) {
		let objectIndex = state.lists.findIndex((d) => d.department_id === payload.department_id);
		objectIndex !== -1 &&
			Object.keys(state.lists[objectIndex]).forEach((key) => {
				state.lists[objectIndex][key] = payload[key];
			});
	},

	REMOVE_BRANCH_DEPARTMENT(state, payload) {
		/** Remove Branch Based on Department ID */
		payload.forEach((id) => {
			/** Find Array Index on State */
			let objectIndex = state.lists.findIndex((d) => d.department_id === id);

			/** If objectIndex !== -1 means the id was found on state then we remove it manually */
			objectIndex !== -1 && state.lists.splice(objectIndex, 1);
		});
	},
};
const actions = {
	FETCH_BRANCH_DEPARTMENT({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/department',
				{
					success(response) {
						response.data.status === 'success' && commit('SET_BRANCH_DEPARTMENT', response.data.data);
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},

	FETCH_BRANCH_DEPARTMENT_MAX_ID({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/department',
				{
					success(response) {
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},

	SEARCH_BRANCH_DEPARTMENT({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/department',
				{
					success(response) {
						response.data.status === 'success' &&
							commit('PUSH_SAVED_SEARCHES_BRANCHES_DEPARTMENTS', response.data.data);
						resolve(response.data);
					},
					catch(response) {},
				},
				payload
			);
		});
	},
	SAVE_BRANCH_DEPARTMENT({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPost('juanHR/department', payload, {
				success(response) {
					response.data.status === 'success' && commit('INSERT_BRANCH_DEPARTMENT', response.data.data);
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				},
			});
		});
	},

	UPDATE_BRANCH_DEPARTMENT({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPut(`juanHR/department/${payload.department_id}`, payload, {
				success(response) {
					response.data.status === 'success' && commit('MODIFY_BRANCH_DEPARTMENT', response.data.data);
					resolve(response.data);
				},
			});
		});
	},

	DELETE_BRANCH_DEPARTMENT({ commit }, payload) {
		console.log(payload);
		return new Promise((resolve, reject) => {
			httpDel(
				`juanHR/department/${payload}`,
				{
					department_id: payload,
				},
				{
					success(response) {
						response.data.status === 'success' && commit('REMOVE_BRANCH_DEPARTMENT', payload);
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				}
			);
		});
	},
};

export default { namespaced: true, state, getters, mutations, actions };
