import { httpGet, httpPost, httpPut, httpDel } from 'boot/axios';

const state = {
	lists: [],
	lazyLists: [],
	deleteEnabled: false,
	credits: [],
	table: {
		pageSize: 50,
		lastPage: 0,
		nextPage: 2,
		loading: false,
		pagination: {
			rowsPerPage: 0,
			rowsNumber: 0,
		},
	},
	displayLeaveCreditsForm: false,
};

const getters = {
	GET_LEAVE_CREDITS_DISTRIBUTION: (state) => {
		return state.lists;
	},

	GET_LEAVE_CREDITS_DISTRIBUTION_FORM_STATE: (state) => {
		return state.displayLeaveCreditsForm;
	},

	GET_LEAVE_CREDITS_DISTRIBUTION_DELETE_STATE: (state) => {
		return state.deleteEnabled;
	},
	// GET_LEAVE_CREDITS_DISTRIBUTION_TABLE(state) {
	// 	return state.table;
	// },

	GET_LEAVE_CREDITS_DISTRIBUTION_TABLE(state) {
		return state.table;
	},

	GET_LEAVE_CREDITS_DISTRIBUTION_LIST(state) {
		return state.credits;
	},
};

const mutations = {
	TOGGLE_LEAVE_CREDITS_DISTRIBUTION_DELETE(state, payload) {
		state.deleteEnabled = state.deleteEnabled !== true;
	},

	SET_LEAVE_CREDITS_DISTRIBUTION(state, payload) {
		/** Populate Data */
		state.credits = payload;
		if (state.credits.length > 1) {
			state.credits.forEach((row, index) => {
				row.index = index;
			});
		}

		// Object.freeze(state.credits);

		state.lists = payload;
	},

	INSERT_LEAVE_CREDITS_DISTRIBUTION(state, payload) {
		let exist = state.lists.filter((ba) => ba.id === Number(payload.id));
		!exist.length && state.lists.unshift(payload);

		let exist2 = state.lazyLists.filter((ba) => ba.id === Number(payload.id));
		!exist2.length && state.lazyLists.unshift(payload);
	},

	MODIFY_LEAVE_CREDITS_DISTRIBUTION(state, payload) {
		let objectIndex = state.lists.findIndex((e) => e.id === payload.id);
		objectIndex !== -1 &&
			Object.keys(state.lists[objectIndex]).forEach((key) => {
				state.lists[objectIndex][key] = payload[key];
			});
	},

	REMOVE_LEAVE_CREDITS_DISTRIBUTION(state, payload) {
		payload.id.forEach((arrayItem) => {
			// Get leaveCreditDistributions bound to Employee ID
			let employeeLeavesid = state.lists.filter((lists) => lists.id === arrayItem);
			console.log(employeeLeavesid);
			// Iterate found employee leaveCreditDistributions
			employeeLeavesid.forEach((employeeLeavesids) => {
				let objectIndex = state.lists.findIndex((b) => b.id === employeeLeavesids.id);
				objectIndex !== -1 && state.lists.splice(objectIndex, 1);
			});
		});
	},

	JOIN_LEAVE_CREDITS_DISTRIBUTION(state, payload) {
		payload.forEach((e) => {
			state.lazyLists.push(e);
		});
	},

	SET_LEAVE_CREDITS_DISTRIBUTION_FORM_STATE(state, payload) {
		state.displayLeaveCreditsForm = payload;
	},

	SET_LEAVE_CREDITS_DISTRIBUTION_TABLE(state, payload) {
		if (typeof payload.childKey !== 'undefined') {
			state.table[payload.key][payload.childKey] = payload.value;
		} else {
			state.table[payload.key] = payload.value;
		}
	},

	REMOVE_EMPLOYEE_LEAVE_CREDITS_DISTRIBUTION(state, payload) {
		payload.employee_id.forEach((arrayItem) => {
			// Get leaveCreditDistributions bound to Employee ID
			let employeeLeaves = state.lazyLists.filter((lazyLists) => lazyLists.employee_id === arrayItem);
			console.log(employeeLeaves);
			// Iterate found employee leaveCreditDistributions
			employeeLeaves.forEach((employeeLeave) => {
				let objectIndex = state.lazyLists.findIndex((b) => b.id === employeeLeave.id);
				objectIndex !== -1 && state.lazyLists.splice(objectIndex, 1);
			});
		});
	},
};

const actions = {
	FETCH_LEAVE_CREDITS_DISTRIBUTION({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/leaveCreditDistribution',
				{
					success(response) {
						response.data.status === 'success' &&
            commit('SET_LEAVE_CREDITS_DISTRIBUTION', response.data.data);
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},

	FETCH_LEAVE_CREDITS_DISTRIBUTION_TABLE({ commit }, payload) {
		console.log(payload);
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/leaveCreditDistribution',
				{
					success(response) {
						console.log(response);
						if (response.data.status === 'success') {
							commit('SET_LEAVE_CREDITS_DISTRIBUTION', response.data.data);
							var result = response.data.data;
							let evaluation = Object.values(result);
							commit('SET_LEAVE_CREDITS_DISTRIBUTION_TABLE', {
								key: 'lastPage',
								value: Math.ceil(evaluation.length / 50),
							});
							commit('SET_LEAVE_CREDITS_DISTRIBUTION_TABLE', {
								key: 'pagination',
								childKey: 'rowsNumber',
								value: evaluation.length,
							});
							commit('SET_LEAVE_CREDITS_DISTRIBUTION', evaluation);
						}

						resolve(response.data.data);
					},
					catch: (response) => console.log(response),
				},
				payload
			);
		});
	},
	FETCH_LEAVE_CREDITS_DISTRIBUTION_LAZY({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/leaveCreditDistribution',
				{
					success(response) {
						/** Commit Data */
						response.data.status === 'success' &&
							commit('JOIN_LEAVE_CREDITS_DISTRIBUTION', response.data.data);
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},

	FETCH_LEAVE_CREDITS_DISTRIBUTION_LAZY_GROUP({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/leaveCreditDistribution',
				{
					success(response) {
						/** Commit Data */
						response.data.status === 'success' &&
							commit('JOIN_LEAVE_CREDITS_DISTRIBUTION_GROUP', response.data.data);
						response.data.data.forEach((e) => {
							e.id = [];
						});
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},

	SAVE_LEAVE_CREDITS_DISTRIBUTION: ({ commit }, payload) => {
		return new Promise((resolve, reject) => {
			httpPost('juanHR/leaveCreditDistribution', payload, {
				success(response) {
					response.data.status === 'success' &&
						commit('INSERT_LEAVE_CREDITS_DISTRIBUTION', response.data.data);
					resolve(response.data);
					console.log(response);
				},
				catch(response) {
					reject(response.data);
					console.log(response);
				},
			});
		});
	},

	UPDATE_LEAVE_CREDITS_DISTRIBUTION({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPut(`juanHR/leaveCreditDistribution/${payload.id}`, payload, {
				success(response) {
					/** Commit Data */
					response.data.status === 'success' &&
						commit('MODIFY_LEAVE_CREDITS_DISTRIBUTION', response.data.data);
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				},
			});
		});
	},

	DELETE_LEAVE_CREDITS_DISTRIBUTION({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpDel(`juanHR/leaveCreditDistribution/${payload.id}`, payload, {
				success(response) {
					/** If response is succss commit to remove branch on vuex */
					response.data.status === 'success' && commit('REMOVE_LEAVE_CREDITS_DISTRIBUTION', payload);
					/** resolve promise */
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				},
			});
		});
	},

	DELETE_EMPLOYEE_LEAVE_CREDITS_DISTRIBUTION({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpDel(`juanHR/leaveCreditDistribution/${payload.employee_id}`, payload, {
				success(response) {
					/** If response is succss commit to remove branch on vuex */
					response.data.status === 'success' && commit('REMOVE_EMPLOYEE_LEAVE_CREDITS_DISTRIBUTION', payload);
					/** resolve promise */
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				},
			});
		});
	},
	TOGGLE_LEAVE_CREDITS_DISTRIBUTION_DELETE({ commit }, payload) {
		commit('TOGGLE_LEAVE_CREDITS_DISTRIBUTION_DELETE');
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
