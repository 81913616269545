import { httpGet, httpPost, httpDel, httpPut } from 'boot/axios';
import { Notify } from 'quasar';

const actions = {
	ADD_UNIT: ({ commit, state }, payload) => {
		return new Promise((resolve, reject) => {
			httpPost('juanHR/unit', payload, {
				success(response) {
					let status = Boolean(response.data.status === 'success');
					resolve(response.data);
				},
				catch(response) {
					console.log(response.data);
				},
			});
		});
	},
	SUBMIT_NOTIF: (status, response, myMessage = 'added') => {
		Notify.create({
			position: 'top-right',
			classes: 'juanhr-alert juanhr-alert-simple q-px-lg q-pt-none q-pb-none',
			html: true,
			actions: [{ icon: 'iconfont juanhr-close', color: 'white' }],
			message: status
				? `<div class="message">Success,</div> Unit Department has been successfully ${myMessage}`
				: `<div class="message">Failed,</div>${
						response.data.message ? response.data.message.message : response.data.message
				  }`,
		});
	},
	FETCH_UNIT_FROM_DB: ({ commit, state }, payload) => {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/unit',
				{
					success(response) {
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},
	UPDATE_UNIT: ({ state, commit }, payload) => {
		let unitHeaderId = Array.isArray(payload) ? payload[0].unit_id : payload.unit_id;
		return new Promise((resolve, reject) => {
			httpPut(`juanHR/unit/${unitHeaderId}`, payload, {
				success(response) {
					resolve(response.data);
				},
				catch(response) {
					resolve(response);
				},
			});
		}); //PROMISE END TAG;
	},
	DELETE_UNIT: ({ state, commit }, payload) => {
		return new Promise((resolve, reject) => {
			httpDel(
				`juanHR/unit/${payload.unit_id}`,
				{
					unit_id: payload,
				},
				{
					success(response) {
						let myMessage = 'deleted!';
						const responseStatus = response.data.status === 'success' ? true : false;
						if (responseStatus) {
							// SHOW SUCCESS NOTIFICATION
							actions.SUBMIT_NOTIF(responseStatus, response, myMessage);
						} else {
							actions.SUBMIT_NOTIF(responseStatus, response, myMessage);
						}
					},
					catch(response) {
						console.log(response);
					},
				}
			);
		});
	},
};

export default {
	namespaced: true,
	actions,
};
