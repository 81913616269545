import { httpGet, httpPost, httpPut, httpDel } from 'boot/axios';

const state = {
	lists: [],
	deleteEnabled: false,
	displayLoanConfigForm: false,
};

const getters = {
	GET_POSITION: (state) => {
		return state.lists;
	},

	GET_POSITION_FORM_STATE: (state) => {
		return state.displayLoanConfigForm;
	},

	GET_POSITION_DELETE_STATE: (state) => {
		return state.deleteEnabled;
	},
};

const mutations = {
	TOGGLE_POSITION_DELETE(state, payload) {
		state.deleteEnabled = state.deleteEnabled !== true;
	},

	SET_POSITION(state, payload) {
		state.lists = payload;
	},

	INSERT_POSITION(state, payload) {
		state.lists.unshift(payload);
	},

	SET_POSITION_FORM_STATE(state, payload) {
		state.displayLoanConfigForm = payload;
	},

	MODIFY_POSITION(state, payload) {
		let objectIndex = state.lists.findIndex((e) => e.position_id === payload.position_id);

		// alert(objectIndex);

		objectIndex !== -1 &&
			Object.keys(state.lists[objectIndex]).forEach((key) => {
				state.lists[objectIndex][key] = payload[key];
			});
	},

	REMOVE_POSITION(state, payload) {
		let objectIndex = state.lists.findIndex((e) => e.id === payload.id);
		objectIndex !== -1 && state.lists.splice(objectIndex, 1);
	},

	REMOVE_EMPLOYEE_POSITION(state, payload) {
		payload.position_id.forEach((arrayItem) => {
			// Get loans bound to Employee ID
			let employeeLoansConfig = state.lists.filter((lists) => lists.position_id === arrayItem);
			// Iterate found employee loans
			employeeLoansConfig.forEach((employeeLoansConfigs) => {
				let objectIndex = state.lists.findIndex((b) => b.position_id === employeeLoansConfigs.position_id);
				objectIndex !== -1 && state.lists.splice(objectIndex, 1);
			});
		});
	},
};

const actions = {
	FETCH_POSITIONS({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/position',
				{
					success(response) {
						response.data.status === 'success';
						resolve(response.data);
						// console.log(response);
					},
					catch(response) {
						// console.log(response);
					},
				},
				payload
			);
		});
	},

	SAVE_POSITION: ({ commit }, payload) => {
		return new Promise((resolve, reject) => {
			httpPost('juanHR/position', payload, {
				success(response) {
					response.data.status === 'success' && commit('INSERT_POSITION', response.data.data);
					resolve(response.data);
					console.log(response);
				},
				catch(response) {
					reject(response.data);
					console.log(response);
				},
			});
		});
	},

	UPDATE_POSITION({ commit }, payload) {
		// alert(JSON.stringify(payload));
		return new Promise((resolve, reject) => {
			httpPut(`juanHR/position/${payload.position_id}`, payload, {
				success(response) {
					// alert(JSON.stringify(response.data.data));
					/** Commit Data */
					response.data.status === 'success' && commit('MODIFY_POSITION', response.data.data);
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				},
			});
		});
	},

	DELETE_POSITION({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpDel(
				`juanHR/position/${payload.id}`,
				{
					id: [payload.id],
				},
				{
					success(response) {
						/** If response is succss commit to remove branch on vuex */
						response.data.status === 'success' && commit('REMOVE_POSITION', payload);
						/** resolve promise */
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				}
			);
		});
	},

	DELETE_EMPLOYEE_POSITION({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpDel(`juanHR/position/${payload.position_id}`, payload, {
				success(response) {
					/** If response is succss commit to remove branch on vuex */
					response.data.status === 'success' && commit('REMOVE_EMPLOYEE_POSITION', payload);
					/** resolve promise */
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				},
			});
		});
	},
	TOGGLE_POSITION_DELETE({ commit }, payload) {
		commit('TOGGLE_POSITION_DELETE');
	},
};

export default { namespaced: true, state, getters, mutations, actions };
