import { httpFileDownload } from 'boot/axios';

const state = {};

const getters = {};

const mutations = {};

const actions = {
	GENERATE_BENEFITS_PDF({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpFileDownload('juanHR/benefitForms', payload, {
				success(response) {
					console.log('payload ', payload);
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				},
			});
		});
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
