import { httpGet, httpPost, httpPut, httpDel, httpFileDownload } from 'boot/axios';

const state = {
	lists: [],
	lazyListsGroup: [],
	resetBiometricLazyLoad: false,
	deleteEnabled: false,
	displayBiometricForm: false,
	searchLists: [],
	suggestLists: [],
	activeBiometrics: [],
	activeEmployee: [],
	deviceEmployee: [],
};

const getters = {
	GET_BIOMETRIC: (state) => {
		return state.lists;
	},
	GET_BIOMETRIC_LAZY_GROUP(state) {
		return state.lazyListsGroup;
	},
	GET_RESET_BIOMETRIC_LAZY_LOAD(state) {
		return state.resetBiometricLazyLoad;
	},
	GET_BIOMETRIC_FORM_STATE: (state) => {
		return state.displayBiometricForm;
	},
	GET_BIOMETRIC_DELETE_STATE: (state) => {
		return state.deleteEnabled;
	},
	GET_SEARCH_BIOMETRIC: (state) => {
		return state.searchLists;
	},
	GET_SUGGEST_BIOMETRIC: (state) => {
		return state.suggestLists;
	},
	GET_ACTIVE_BIOMETRICS(state) {
		return state.activeBiometrics;
	},
	GET_ACTIVE_EMPLOYEE(state) {
		return state.activeEmployee;
	},

	GET_DEVICE_EMPLOYEE(state) {
		return state.deviceEmployee;
	},
};

const mutations = {
	TOGGLE_BIOMETRIC_DELETE(state, payload) {
		state.deleteEnabled = state.deleteEnabled !== true;
	},

	SET_BIOMETRIC(state, payload) {
		state.lists = payload;
	},

	SEARCH_BIOMETRIC(state, payload) {
		state.searchLists = payload;
	},

	SUGGEST_BIOMETRIC(state, payload) {
		state.suggestLists = payload;
	},

	INSERT_BIOMETRIC(state, payload) {
		// console.log(payload, 'this is payload on insert');
		// state.lists.unshift(payload);
		// state.lazyListsGroup.unshift(payload);
		payload.forEach((arrayItem) => {
			state.lists.unshift(arrayItem);
			state.lazyListsGroup.unshift(arrayItem);
			state.deviceEmployee.unshift(arrayItem);
		});
	},

	MODIFY_BIOMETRIC(state, payload) {
		let objectIndex = state.lists.findIndex((e) => e.id === payload.id);
		objectIndex !== -1 &&
			Object.keys(state.lists[objectIndex]).forEach((key) => {
				state.lists[objectIndex][key] = payload[key];
			});
	},

	REMOVE_BIOMETRIC(state, payload) {
		payload.id.forEach((arrayItem) => {
			// Get biometrics bound to ID
			let employeeBiometricsid = state.lists.filter((lists) => lists.id === arrayItem);
			// Iterate found employee biometrics
			employeeBiometricsid.forEach((employeeBiometricsids) => {
				let objectIndex = state.lists.findIndex((b) => b.id === employeeBiometricsids.id);
				objectIndex !== -1 && state.lists.splice(objectIndex, 1);
			});

			// Get biometrics bound to ID
			let employeeBiometricsid2 = state.searchLists.filter((lists) => lists.id === arrayItem);
			// Iterate found employee biometrics
			employeeBiometricsid2.forEach((employeeBiometricsid2s) => {
				let objectIndex = state.searchLists.findIndex((b) => b.id === employeeBiometricsid2s.id);
				objectIndex !== -1 && state.searchLists.splice(objectIndex, 1);
			});

			// Get biometrics bound to Employee ID
			let employeeBiometricsid3 = state.lazyListsGroup.filter((lists) => lists.id === arrayItem);
			// Iterate found employee biometrics
			employeeBiometricsid3.forEach((employeeBiometricsid3s) => {
				let objectIndex = state.lazyListsGroup.findIndex((b) => b.id === employeeBiometricsid3s.id);
				objectIndex !== -1 && state.lazyListsGroup.splice(objectIndex, 1);
			});
		});
	},

	REMOVE_EMPLOYEE_BIOMETRICS(state, payload) {
		payload.employee_id.forEach((arrayItem) => {
			// Get biometrics bound to ID
			let employeeBiometricsid4 = state.deviceEmployee.filter((lists) => lists.employee_id === arrayItem);

			// Iterate found employee biometrics
			employeeBiometricsid4.forEach((employeeBiometricsid4s) => {
				let objectIndex = state.deviceEmployee.findIndex(
					(b) => b.employee_id === employeeBiometricsid4s.employee_id
				);
				objectIndex !== -1 && state.deviceEmployee.splice(objectIndex, 1);
			});

			// Get biometrics bound to ID
			let employeeBiometricsid = state.lists.filter((lists) => lists.employee_id === arrayItem);
			// Iterate found employee biometrics
			employeeBiometricsid.forEach((employeeBiometricsids) => {
				let objectIndex = state.lists.findIndex((b) => b.employee_id === employeeBiometricsids.employee_id);
				objectIndex !== -1 && state.lists.splice(objectIndex, 1);
			});

			// Get biometrics bound to ID
			let employeeBiometricsid2 = state.searchLists.filter((lists) => lists.id === arrayItem);
			// Iterate found employee biometrics
			employeeBiometricsid2.forEach((employeeBiometricsid2s) => {
				let objectIndex = state.searchLists.findIndex(
					(b) => b.employee_id === employeeBiometricsid2s.employee_id
				);
				objectIndex !== -1 && state.searchLists.splice(objectIndex, 1);
			});

			// Get biometrics bound to Employee ID
			let employeeBiometricsid3 = state.lazyListsGroup.filter((lists) => lists.employee_id === arrayItem);
			// Iterate found employee biometrics
			employeeBiometricsid3.forEach((employeeBiometricsid3s) => {
				let objectIndex = state.lazyListsGroup.findIndex(
					(b) => b.employee_id === employeeBiometricsid3s.employee_id
				);
				objectIndex !== -1 && state.lazyListsGroup.splice(objectIndex, 1);
			});
		});
	},

	CLEAR_EMPLOYEE_BIOMETRIC(state, payload) {
		state.lazyListsGroup = payload;
		state.deviceEmployee = payload;
	},

	JOIN_BIOMETRIC_GROUP(state, payload) {
		payload.forEach((e) => {
			let exist = state.lazyListsGroup.filter((i) => i.employee_id === Number(e.employee_id));
			!exist.length && state.lazyListsGroup.push(e);
		});
	},

	SET_BIOMETRIC_FORM_STATE(state, payload) {
		state.displayBiometricForm = payload;
	},
	SET_RESET_BIOMETRIC_LAZY_LOAD(state, payload) {
		state.resetBiometricLazyLoad = payload;
	},

	SET_ACTIVE_BIOMETRICS(state, payload) {
		state.activeBiometrics = payload;
	},
	SET_ACTIVE_EMPLOYEE(state, payload) {
		state.activeEmployee = payload;
	},

	SET_DEVICE_EMPLOYEE(state, payload) {
		state.deviceEmployee = payload;
	},
};

const actions = {
	FETCH_BIOMETRIC({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/biometric',
				{
					success(response) {
						response.data.status === 'success';
						resolve(response.data.data);
						console.log(response);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},
	FIND_BIOMETRIC({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/biometric',
				{
					success(response) {
						response.data.status === 'success' && commit('SEARCH_BIOMETRIC', response.data.data);
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},

	FIND_DEVICE_EMPLOYEE({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/biometric',
				{
					success(response) {
						response.data.status === 'success' && commit('SET_DEVICE_EMPLOYEE', response.data.data);
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},

	LOOK_BIOMETRIC({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/biometric',
				{
					success(response) {
						response.data.status === 'success' && commit('SUGGEST_BIOMETRIC', response.data.data);
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},
	FETCH_BIOMETRIC_DATDAT({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/biometric',
				{
					success(response) {
						resolve(response.data);
						console.log(response);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},

	FETCH_BIOMETRIC_LAZY_GROUP({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/biometric',
				{
					success(response) {
						// /** Commit Data */
						if (response.data.status === 'success') {
							response.data.data.forEach((e) => {
								e.biometric_id = [];
							});
							commit('JOIN_BIOMETRIC_GROUP', response.data.data);
						}
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},

	SAVE_BIOMETRIC: ({ commit }, payload) => {
		return new Promise((resolve, reject) => {
			httpPost('juanHR/biometric', payload, {
				success(response) {
					response.data.status === 'success' && commit('INSERT_BIOMETRIC', response.data.data);
					resolve(response.data);
					console.log(response);
				},
				catch(response) {
					reject(response.data);
					console.log(response);
				},
			});
		});
	},

	UPDATE_BIOMETRIC({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPut(`juanHR/biometric/${payload.id}`, payload, {
				success(response) {
					/** Commit Data */
					response.data.status === 'success' && commit('MODIFY_BIOMETRIC', response.data.data);
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				},
			});
		});
	},

	DELETE_BIOMETRIC({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpDel(`juanHR/biometric/${payload.id}`, payload, {
				success(response) {
					/** If response is succss commit to remove branch on vuex */
					response.data.status === 'success' && commit('REMOVE_BIOMETRIC', payload);
					/** resolve promise */
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				},
			});
		});
	},

	DELETE_EMPLOYEE_BIOMETRICS({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpDel(`juanHR/biometric/${payload.employee_id}`, payload, {
				success(response) {
					/** If response is succss commit to remove branch on vuex */
					response.data.status === 'success' && commit('REMOVE_EMPLOYEE_BIOMETRICS', payload);
					/** resolve promise */
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				},
			});
		});
	},
	TOGGLE_BIOMETRIC_DELETE({ commit }, payload) {
		commit('TOGGLE_BIOMETRIC_DELETE');
	},
};

export default { namespaced: true, state, getters, mutations, actions };
