import { httpGet, httpPost, httpDel, httpPut } from 'boot/axios';
const state = {
	lists: [],
	searchLists: [],
	displayBranchForm: false,
	addButton: false,
};

const getters = {
	GET_BRANCHES: (state) => {
		return state.lists;
	},
	GET_SAVED_SEARCHES_BRANCHES: (state) => {
		return state.searchLists;
	},

	GET_BRANCH_FORM_STATE: (state) => {
		return state.displayBranchForm;
	},

	ADD_BUTTON_STATE: (state) => {
		return state.addButton;
	},
};

const mutations = {
	SET_BRANCHES(state, payload) {
		state.lists = payload;
	},

	INSERT_BRANCH(state, payload) {
		state.lists.unshift(payload);
	},
	PUSH_SAVED_SEARCHES_BRANCHES(state, payload) {
		state.searchLists = state.searchLists.concat(payload);
	},

	MODIFY_BRANCH(state, payload) {
		let objectIndex = state.lists.findIndex((b) => b.branch_id === payload.branch_id);
		Object.keys(state.lists[objectIndex]).forEach((key) => {
			state.lists[objectIndex][key] = payload[key];
		});
	},

	REMOVE_BRANCH(state, payload) {
		/** Find Index of each Payload branch ID */
		payload.branch_id.forEach((id) => {
			let objectIndex = state.lists.findIndex((b) => b.branch_id === id);
			objectIndex !== -1 && state.lists.splice(objectIndex, 1);
		});
	},

	SET_BRANCH_FORM_STATE(state, payload) {
		state.displayBranchForm = payload;
	},

	SET_BRANCH_ADD_BUTTON_STATE(state, payload) {
		state.addButton = payload;
	},
};

const actions = {
	FETCH_BRANCH({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				`juanHR/branch`,
				{
					success(response) {
						response.data.status === 'success' && commit('SET_BRANCHES', response.data.data);
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},

	SEARCH_BRANCH({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/branch',
				{
					success(response) {
						response.data.status === 'success' &&
							commit('PUSH_SAVED_SEARCHES_BRANCHES', response.data.data);
						resolve(response.data);
					},
					catch(response) {},
				},
				payload
			);
		});
	},

	SAVE_BRANCH({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPost('juanHR/branch', payload, {
				success(response) {
					response.data.status === 'success' && commit('INSERT_BRANCH', response.data.data);
					resolve(response.data);
				},
				catch(response) {},
			});
		});
	},

	UPDATE_BRANCH({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPut(`juanHR/branch/${payload.branch_id}`, payload, {
				success(response) {
					response.data.status === 'success' && commit('MODIFY_BRANCH', response.data.data);
					resolve(response.data);
				},
				catch(response) {},
			});
		});
	},

	DELETE_BRANCH({ commit }, payload) {
		return new Promise((resolve, reeject) => {
			httpDel(`juanHR/branch/${payload.branch_id}`, payload, {
				success(response) {
					/** If response is succss commit to remove branch on vuex */
					response.data.status === 'success' && commit('REMOVE_BRANCH', payload);
					/** resolve promise */
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				},
			});
		});
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
