import { httpGet, httpPost, httpDel, httpPut } from 'boot/axios';

const state = {
	weeklyStatistics: {
		present: [],
		lates: [],
		undertimes: [],
		absences: [],
		leaves: [],
		on_travel: [],
		suspended: [],
	},
	todaysStatistics: {
		drilldownData: [],
		branchEmployees: [],
		departmentEmployees: [],
	},
	yearlyStatistics: {
		present: [],
		lates: [],
		undertimes: [],
		absences: [],
		leaves: [],
		suspended: [],
	},
};

const getters = {
	GET_WEEKLY_STATISTICS: (state) => {
		return state.weeklyStatistics;
	},

	GET_TODAYS_STATISTICS: (state) => {
		return state.todaysStatistics;
	},

	GET_YEARLY_STATISTICS: (state) => {
		return state.yearlyStatistics;
	},
};

const mutations = {
	SET_WEEKLY_STATISTICS(state, payload) {
		let seriesData = [];
		let seriesNames = Object.keys(payload.statistics);
		seriesNames.forEach((seriesName) => {
			let seriesDates = Object.keys(payload.statistics[seriesName]);
			seriesDates.forEach((seriesDate) => {
				let object = {
					y: payload.statistics[seriesName][seriesDate],
					name: seriesDate,
				};
				seriesData.push(object);
			});
			state.weeklyStatistics[seriesName] = seriesData;
			seriesData = [];
		});
	},

	SET_TODAYS_STATISTICS(state, payload) {
		let seriesData = [],
			drilldownData = [],
			departmentData = [],
			branchData = [];

		let branchNames = Object.keys(payload.statistics);

		branchNames.forEach((branchName) => {
			let departmentNames = Object.keys(payload.statistics[branchName]);

			departmentNames.forEach((departmentName) => {
				let statisticsNames = Object.keys(payload.statistics[branchName][departmentName]);
				seriesData = [];

				statisticsNames.forEach((statisticsName) => {
					let object = {
						name: statisticsName,
						y:
							(payload.statistics[branchName][departmentName][statisticsName] /
								payload['branch_department_employee_count'][branchName][departmentName]) *
							100,
						color: '#' + Math.floor(Math.random() * 16777215).toString(16),
					};
					seriesData.push(object);
				});

				let drilldownObject = {
					name: 'Employee Percentage',
					id: departmentName,
					data: seriesData,
					dataLabels: {
						enabled: true,
						distance: -60,
						formatter: function () {
							console.log(this);
							return this.y !== 0 ? this.key : '';
						},
					},
					tooltip: {
						valueSuffix: '%',
					},
					size: '100%',
					innerSize: '25%',
				};
				drilldownData.push(drilldownObject);

				let object = {
					name: departmentName,
					y: payload['branch_department_employee_count'][branchName][departmentName],
					color: '#' + Math.floor(Math.random() * 16777215).toString(16),
					drilldown: departmentName,
				};
				departmentData.push(object);
			});

			let departmentDrilldownObject = {
				name: 'Department Employees',
				id: branchName,
				data: departmentData.filter(({ name }) => departmentNames.includes(name)),
				dataLabels: {
					enabled: true,
					distance: -40,
				},
				size: '100%',
				innerSize: '50%',
			};

			drilldownData.push(departmentDrilldownObject);

			let object = {
				name: branchName,
				y: payload['branch_employee_count'][branchName],
				color: '#' + Math.floor(Math.random() * 16777215).toString(16),
				drilldown: branchName,
			};
			branchData.push(object);
		});

		state.todaysStatistics = {
			drilldownData: drilldownData,
			departmentEmployees: departmentData,
			branchEmployees: branchData,
		};
	},

	SET_YEARLY_STATISTICS(state, payload) {
		state.yearlyStatistics = {
			present: [],
			lates: [],
			undertimes: [],
			absences: [],
			leaves: [],
			suspended: [],
		};

		let seriesMonths = Object.keys(payload.statistics);
		let seriesNames = Object.keys(payload.statistics['01']);

		seriesMonths
			.sort((a, b) => {
				return a - b;
			})
			.forEach((month) => {
				seriesNames.forEach((seriesName) => {
					state.yearlyStatistics[seriesName].push(payload.statistics[month][seriesName]);
				});
			});
	},
};

const actions = {
	FETCH_WEEKLY_STATISTICS({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/dashboard',
				{
					success(response) {
						response.data.status === 'success' && commit('SET_WEEKLY_STATISTICS', response.data.data);
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},

	FETCH_TODAYS_STATISTICS({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/dashboard',
				{
					success(response) {
						response.data.status === 'success' && commit('SET_TODAYS_STATISTICS', response.data.data);
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},

	FETCH_YEARLY_STATISTICS({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/dashboard',
				{
					success(response) {
						response.data.status === 'success' && commit('SET_YEARLY_STATISTICS', response.data.data);
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},
};

export default { namespaced: true, state, getters, mutations, actions };
