import { httpGet, httpPost, httpDel } from 'boot/axios';

const state = {
	payrollInformation: [],
	lazyEmployees: [],
	employeePayrollInformation: [],
	selectedPayrollInformation: [],
	employeePayrollDetailedInformation: null,
	deleteEnabled: false,
	searchLists: [],
  payrollAdjustments: []
};

const getters = {
	GET_PAYROLL_DELETE_STATE: (state) => {
		return state.deleteEnabled;
	},

	GET_PAYROLL_INFORMATION: (state) => {
		return state.payrollInformation;
	},

	GET_SELECTED_PAYROLL_INFORMATION: (state) => {
		return state.selectedPayrollInformation;
	},

	GET_EMPLOYEE_PAYROLL_INFORMATION: (state) => {
		return state.employeePayrollInformation;
	},

	GET_EMPLOYEE_PAYROLL_DETAILED_INFORMATION: (state) => {
		return state.employeePayrollDetailedInformation;
	},
	GET_SEARCH_PAYROLL: (state) => {
		return state.searchLists;
	},

	GET_PAYROLL_EMPLOYEES_LAZY: (state) => {
		return state.lazyEmployees;
	},

  GET_PAYROLL_ADJUSTMENTS: (state) => {
    return state.payrollAdjustments;
  }
};

const mutations = {
	TOGGLE_PAYROLL_DELETE(state, payload) {
		state.deleteEnabled = state.deleteEnabled !== true;
	},

	SET_PAYROLL_INFORMATION(state, payload) {
		state.payrollInformation = payload;
	},

	SET_SELECTED_PAYROLL_INFORMATION(state, payload) {
		state.selectedPayrollInformation = payload;
	},

	SET_EMPLOYEE_PAYROLL_DETAILED_INFORMATION(state, payload) {
		state.employeePayrollDetailedInformation = payload;
	},

	SET_EMPLOYEE_PAYROLL_INFORMATION(state, payload) {
		state.employeePayrollInformation = payload;
	},

  SET_PAYROLL_ADJUSTMENTS(state, payload) {
    state.payrollAdjustments = payload;
  },

	INSERT_PAYROLL_INFORMATION(state, payload) {
		state.payrollInformation.push(payload);
	},

	JOIN_PAYROLL_EMPLOYEES(state, payload) {
		payload.forEach((e) => {
			let objectIndex = state.lazyEmployees.findIndex((b) => b.employee_id === e.employee_id);
			objectIndex === -1 && state.lazyEmployees.push(e);
		});
	},

	REMOVE_PAYROLL_INFORMATION(state, payload) {
		payload.payroll_id.forEach((arrayItem) => {
			let objectIndex = state.payrollInformation.findIndex((b) => b.payroll_id === arrayItem);
			objectIndex !== -1 && state.payrollInformation.splice(objectIndex, 1);
		});
	},

	REMOVE_EMPLOYEE_PAYROLL_INFORMATION(state, payload) {
		payload.employee_id.forEach((arrayItem) => {
			// Get adjustments bound to Employee ID
			let employeePayrollInformation = state.payrollInformation.filter(
				(adjustment) => adjustment.employee_id === arrayItem
			);
			console.log(employeePayrollInformation);

			// Iterate found employee adjustments
			employeePayrollInformation.forEach((employeePayrollInfo) => {
				let objectIndex = state.payrollInformation.findIndex(
					(b) => b.payroll_id === employeePayrollInfo.payroll_id
				);
				objectIndex !== -1 && state.payrollInformation.splice(objectIndex, 1);
			});
		});
	},
};

const actions = {
	TOGGLE_PAYROLL_DELETE({ commit }, payload) {
		commit('TOGGLE_PAYROLL_DELETE');
	},

	FETCH_EMPLOYEE_PAYROLL_INFORMATION({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/payroll',
				{
					success(response) {
						if (response.data.status === 'success') {
							commit('SET_EMPLOYEE_PAYROLL_INFORMATION', response.data.data);
							resolve(response.data.data);
						} else {
							reject(response);
						}
					},
					error(response) {
						reject(response);
					},
				},
				{ employee_id: payload }
			);
		});
	},

	FETCH_EMPLOYEE_PAYROLL_DETAILED_INFORMATION({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/payrollInformation',
				{
					success(response) {
						if (response.data.status === 'success') {
							commit('SET_EMPLOYEE_PAYROLL_DETAILED_INFORMATION', response.data.data);
							resolve(response.data.data);
						} else {
							reject(response);
						}
					},
					error(response) {
						reject(response);
					},
				},
				payload
			);
		});
	},

	FETCH_PAYROLL_INFORMATION({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet('juanHR/payroll', {
				success(response) {
					if (response.data.status === 'success') {
						commit('SET_PAYROLL_INFORMATION', response.data.data);
						resolve(response.data.data);
					} else {
						reject(response);
					}
				},
				error(response) {
					reject(response);
				},
			});
		});
	},

	FETCH_PAYROLL_DATE_INFORMATION({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/payrollDateInformation',
				{
					success(response) {
						resolve(response.data);
					},
					error(response) {
						reject(response);
					},
				},
				payload
			);
		});
	},

	FETCH_PAYROLLS_LAZY({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/payroll',
				{
					success(response) {
						/** Commit Data */
						response.data.status === 'success' && commit('JOIN_PAYROLL_EMPLOYEES', response.data.data);
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},

  FETCH_PAYROLLS_ADJUSTMENTS({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/payroll',
				{
					success(response) {
						/** Commit Data */
            if(response.data.status === 'success') {
              commit('SET_PAYROLL_ADJUSTMENTS', []);
              commit('SET_PAYROLL_ADJUSTMENTS', response.data.data);
            }
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},

  INSERT_PAYROLL_ADJUSTMENTS({ commit }, payload) {
    return new Promise((resolve, reject) => {
      httpPost('juanHR/payroll', payload, {
        success(response) {
          console.log("response123: ", response);
          if (response.data.status === 'success') {
            // let generatedPayroll = Object.assign({}, response.data.data);
            // // Remove consolidated data from payroll information
            // delete generatedPayroll['consolidated_results'];
            // commit('INSERT_PAYROLL_INFORMATION', generatedPayroll);
            resolve(response.data);
          } else {
            reject(response.data);
            console.log(response);
          }
        },
      });
    });
  },

	GENERATE_PAYROLL({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPost('juanHR/payroll', payload, {
				success(response) {
					if (response.data.status === 'success') {
						let generatedPayroll = Object.assign({}, response.data.data);
						// Remove consolidated data from payroll information
						delete generatedPayroll['consolidated_results'];
						commit('INSERT_PAYROLL_INFORMATION', generatedPayroll);
						resolve(response.data);
					} else {
						reject(response.data);
						console.log(response);
					}
				},
			});
		});
	},

	DELETE_PAYROLL({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpDel(`juanHR/payroll/${payload.payroll_id}`, payload, {
				success(response) {
					if (response.data.status === 'success') {
						commit('REMOVE_PAYROLL_INFORMATION', payload);
						resolve(response.data);
					} else {
						reject(response.data);
						console.log(response);
					}
				},
			});
		});
	},

	DELETE_EMPLOYEE_PAYROLL({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpDel(`juanHR/payroll/${payload.employee_id}`, payload, {
				success(response) {
					if (response.data.status === 'success') {
						commit('REMOVE_EMPLOYEE_PAYROLL_INFORMATION', payload);
						resolve(response.data);
					} else {
						reject(response.data);
						console.log(response);
					}
				},
			});
		});
	},
};

export default { namespaced: true, state, getters, mutations, actions };
