import { httpGet, httpPost, httpPut, httpDel } from 'boot/axios';
// import { isObject } from './../../../../../shared/helpers/Common';

import { isObject } from './../../../shared/helpers/Common';

const state = {
	lists: [],
	lazyListsGroup: [],
	resetLoanLazyLoad: false,
	deleteEnabled: false,
	displayLoanForm: false,
	searchLists: [],
	suggestLists: [],
	employeeLoansLists: [],
};

const getters = {
	GET_LOAN: (state) => {
		return state.lists;
	},

	GET_LOAN_LAZY_GROUP(state) {
		return state.lazyListsGroup;
	},

	GET_LOAN_FORM_STATE: (state) => {
		return state.displayLoanForm;
	},
	GET_RESET_LOAN_LAZY_LOAD(state) {
		return state.resetLoanLazyLoad;
	},
	GET_LOAN_DELETE_STATE: (state) => {
		return state.deleteEnabled;
	},
	GET_SEARCH_LOAN: (state) => {
		return state.searchLists;
	},
	GET_SUGGEST_LOAN: (state) => {
		return state.suggestLists;
	},
	GET_EMPLOYEE_LOANS: (state) => {
		return state.employeeLoansLists;
	},
};

const mutations = {
	TOGGLE_LOAN_DELETE(state, payload) {
		state.deleteEnabled = state.deleteEnabled !== true;
	},
	SET_LOAN(state, payload) {
		state.lists = payload;
	},
	SEARCH_LOAN(state, payload) {
		state.searchLists = payload;
	},
	SUGGEST_LOAN(state, payload) {
		state.suggestLists = payload;
	},
	INSERT_LOAN(state, payload) {
		state.lists.unshift(payload);

		let existss = state.lazyListsGroup.filter((list) => list.employee_id === payload.employee_id);
		!Boolean(existss.length) && state.lazyListsGroup.unshift(payload);
	},

	INSERT_LOAN_BULK(state, payload) {
		payload.data.forEach((e) => {
			let loanIndex = payload.loans.findIndex((l) => l.loan_id === e.loan_id);
			e.name = payload.loans[loanIndex].name;
			e.loans = [payload.loans[loanIndex].name];

			let exists = state.lists.filter((list) => list.employee_id === e.employee_id);
			!Boolean(exists.length) && state.lists.unshift(e);

			let existss = state.lazyListsGroup.filter((list) => list.employee_id === e.employee_id);
			!Boolean(existss.length) && state.lazyListsGroup.unshift(e);
		});
	},
	MODIFY_LOAN(state, payload) {
		let objectIndex = state.lists.findIndex((e) => e.id === payload.data.id);
		objectIndex !== -1 &&
			Object.keys(state.lists[objectIndex]).forEach((key) => {
				state.lists[objectIndex][key] = payload.data[key];

				let loanIndex = payload.loans.findIndex((l) => l.loan_id === payload.data.loan_id);
				state.lists[objectIndex].name = payload.loans[loanIndex].name;
				state.lists[objectIndex].loans = [payload.loans[loanIndex].name];
			});
	},

	REMOVE_LOAN(state, payload) {
		payload.id.forEach((arrayItem) => {
			let objectIndex = state.employeeLoansLists.findIndex((b) => b.id === arrayItem);
			objectIndex !== -1 && state.employeeLoansLists.splice(objectIndex, 1);

			// Get loans bound to Employee ID
			let employeeLoansid = state.lists.filter((lists) => lists.id === arrayItem);
			console.log(employeeLoansid);
			// Iterate found employee loans
			employeeLoansid.forEach((employeeLoansids) => {
				let objectIndex = state.lists.findIndex((b) => b.id === employeeLoansids.id);
				objectIndex !== -1 && state.lists.splice(objectIndex, 1);
			});

			let objectIndexTwo = state.lazyListsGroup.findIndex((b) => b.id === arrayItem);
			objectIndexTwo !== -1 && state.lazyListsGroup.splice(objectIndexTwo, 1);
		});
	},

	JOIN_LOAN_GROUP(state, payload) {
		payload.forEach((e) => {
			state.lazyListsGroup.push(e);
		});
	},

	JOIN_LOAN_TYPE_GROUP(state, payload) {
		console.log(payload);
		let employee_id = payload.record.employee_id;
		let objectIndex = state.lazyListsGroup.findIndex((b) => b.employee_id === employee_id);

		state.lazyListsGroup[objectIndex].loans = [];
		state.lazyListsGroup[objectIndex].loans = payload.loans;
	},

	JOIN_SEARCH_LOAN_TYPE_GROUP(state, payload) {
		console.log(payload);
		let employee_id = payload.record.employee_id;
		let objectIndex = state.searchLists.findIndex((b) => b.employee_id === employee_id);

		state.searchLists[objectIndex].loans = [];
		state.searchLists[objectIndex].loans = payload.loans;
	},

	SET_LOAN_FORM_STATE(state, payload) {
		state.displayLoanForm = payload;
	},

	REMOVE_EMPLOYEE_LOANS(state, payload) {
		payload.employee_id.forEach((arrayItem) => {
			// Get loans bound to Employee ID
			let employeeLoans = state.lazyListsGroup.filter(
				(lazyListsGroup) => lazyListsGroup.employee_id === arrayItem
			);
			console.log(employeeLoans);
			// Iterate found employee loans
			employeeLoans.forEach((employeeLoan) => {
				let objectIndex = state.lazyListsGroup.findIndex((b) => b.id === employeeLoan.id);
				objectIndex !== -1 && state.lazyListsGroup.splice(objectIndex, 1);

				objectIndex = state.searchLists.findIndex((b) => b.id === employeeLoan.id);
				objectIndex !== -1 && state.searchLists.splice(objectIndex, 1);

				objectIndex = state.lists.findIndex((b) => b.id === employeeLoan.id);
				objectIndex !== -1 && state.lists.splice(objectIndex, 1);
			});
		});
	},
	INSERT_EMPLOYEE_LOANS(state, payload) {
		state.employeeLoansLists = payload;
	},
};

const actions = {
	FETCH_LOAN({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/loan',
				{
					success(response) {
						response.data.status === 'success' && commit('INSERT_EMPLOYEE_LOANS', response.data.data);
						resolve(response.data.data);
					},
					catch(response) {},
				},
				payload
			);
		});
	},

	FETCH_LOAN_BASED_ON_ID({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/loan',
				{
					success(response) {
						resolve(response.data.data);
					},
					catch(response) {},
				},
				payload
			);
		});
	},
	FIND_LOAN({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/loan',
				{
					success(response) {
						response.data.status === 'success' && commit('SEARCH_LOAN', response.data.data);
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},
	LOOK_LOAN({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/loan',
				{
					success(response) {
						response.data.status === 'success' && commit('SUGGEST_LOAN', response.data.data);
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},
	FIND_LOAN_DOWNLOAD({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/loan',
				{
					success(response) {
						resolve(response.data);
					},
					catch(response) {},
				},
				payload
			);
		});
	},

	FETCH_LOAN_LAZY_GROUP({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/loan',
				{
					success(response) {
						response.data.status === 'success' && commit('JOIN_LOAN_GROUP', response.data.data);
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},

	SAVE_LOAN: ({ commit }, payload) => {
		return new Promise((resolve, reject) => {
			httpPost('juanHR/loan', payload.form, {
				success(response) {
					if (response.data.status === 'success') {
						let tosave = response.data.data;
						tosave.name = payload.others.loans;
						tosave.loans = [payload.others.loans];
						tosave.full_name = payload.others.full_name;
						tosave.sex = payload.others.sex;
						tosave.photo = payload.others.photo;
						commit('INSERT_LOAN', tosave);
					}
					resolve(response.data);
					console.log(response);
				},
				catch(response) {
					reject(response.data);
					console.log(response);
				},
			});
		});
	},

	UPLOAD_BULK_LOAN({ commit, getters }, payload) {
		return new Promise((resolve, reject) => {
			httpPost(`juanHR/loan`, payload.data, {
				success(response) {
					response.data.status === 'success' &&
						!isObject(response.data.data) &&
						commit('INSERT_LOAN_BULK', { data: response.data.data, loans: payload.loans });
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
					reject(response.data);
				},
			});
		});
	},

	UPDATE_LOAN({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPut(`juanHR/loan/${payload.data.id}`, payload.data, {
				success(response) {
					/** Commit Data */
					response.data.status === 'success' &&
						commit('MODIFY_LOAN', {
							data: response.data.data,
							loans: payload.loans,
						});
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				},
			});
		});
	},

	DELETE_LOAN({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpDel(`juanHR/loan/${payload.id}`, payload, {
				success(response) {
					/** If response is succss commit to remove branch on vuex */
					response.data.status === 'success' && commit('REMOVE_LOAN', payload);
					/** resolve promise */
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				},
			});
		});
	},

	DELETE_EMPLOYEE_LOANS({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpDel(`juanHR/loan/${payload.employee_id}`, payload, {
				success(response) {
					/** If response is succss commit to remove branch on vuex */
					response.data.status === 'success' && commit('REMOVE_EMPLOYEE_LOANS', payload);
					/** resolve promise */
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				},
			});
		});
	},
	TOGGLE_LOAN_DELETE({ commit }, payload) {
		commit('TOGGLE_LOAN_DELETE');
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
