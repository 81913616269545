import { httpGet, httpPost, httpDel, httpPut } from 'boot/axios';

const state = {
	benefits: [],
	lazyEmployees: [],
	deleteEnabled: false,
	addFormVisible: false,
	searchLists: [],
	suggestLists: [],
};

const getters = {
	GET_BENEFIT_DELETE_STATE: (state) => {
		return state.deleteEnabled;
	},

	GET_BENEFIT_ADD_FORM_DISPLAY_STATE: (state) => {
		return state.addFormVisible;
	},

	GET_BENEFITS: (state) => {
		return state.benefits;
	},

	GET_BENEFITS_EMPLOYEES_LAZY: (state) => {
		return state.lazyEmployees;
	},

	GET_SSS_BENEFITS: (state) => {
		return state.benefits.filter((benefit) => benefit.benefit_id === 1);
	},

	GET_PHILHEALTH_BENEFITS: (state) => {
		return state.benefits.filter((benefit) => benefit.benefit_id === 2);
	},

	GET_PAGIBIG_BENEFITS: (state) => {
		return state.benefits.filter((benefit) => benefit.benefit_id === 3);
	},

	GET_GSIS_BENEFITS: (state) => {
		return state.benefits.filter((benefit) => benefit.benefit_id === 4);
	},

	GET_SEARCH_BENEFITS: (state) => {
		return state.searchLists;
	},
	GET_SUGGEST_BENEFITS: (state) => {
		return state.suggestLists;
	},
};

const mutations = {
	TOGGLE_BENEFIT_DELETE(state, payload) {
		state.deleteEnabled = state.deleteEnabled !== true;
	},

	SHOW_ADD_FORM(state, payload) {
		state.addFormVisible = true;
	},

	HIDE_ADD_FORM(state, payload) {
		state.addFormVisible = false;
	},

	SET_BENEFITS(state, payload) {
		state.benefits = payload;
	},

	SEARCH_BENEFITS(state, payload) {
		state.searchLists = payload;
	},

	SUGGEST_BENEFITS(state, payload) {
		state.suggestLists = payload;
	},

	JOIN_BENEFITS_EMPLOYEES(state, payload) {
		console.log('JOIN_BENEFITS_EMPLOYEES ', payload);
		payload.forEach((e) => {
			let objectIndex = state.lazyEmployees.findIndex((b) => b.employee_id === e.employee_id);
			objectIndex === -1 && state.lazyEmployees.push(e);
		});
	},

	JOIN_BENEFITS_TYPE_GROUP(state, payload) {
		console.log(payload);
		let employee_id = payload.record.employee_id;
		let objectIndex = state.lazyEmployees.findIndex((b) => b.employee_id === employee_id);

		state.lazyEmployees[objectIndex].benefit = [];
		state.lazyEmployees[objectIndex].benefit = payload.benefit;
	},

	JOIN_SEARCH_BENEFITS_TYPE_GROUP(state, payload) {
		console.log(payload);
		let employee_id = payload.record.employee_id;
		let objectIndex = state.searchLists.findIndex((b) => b.employee_id === employee_id);

		state.searchLists[objectIndex].benefit = [];
		state.searchLists[objectIndex].benefit = payload.benefit;
	},

	INSERT_BENEFIT(state, payload) {
		console.log('INSERT_BENEFIT ', payload);
		state.benefits.unshift(payload);
		let employeeIndex = state.lazyEmployees.findIndex((e) => e.employee_id === payload.employee_id);
		employeeIndex === -1 && state.lazyEmployees.unshift(payload);
	},

	INSERT_BENEFITS(state, payload) {
		payload.forEach((benefit) => {
			state.benefits.unshift(benefit);
		});
	},

	INSERT_BENEFITS_EMPLOYEE(state, payload) {
		let employeeIndex = state.lazyEmployees.findIndex((e) => e.employee_id === payload.employee_id);
		employeeIndex === -1 && state.lazyEmployees.unshift(payload);
	},

	MODIFY_BENEFIT(state, payload) {
		let objectIndex = state.benefits.findIndex((b) => b.id === payload.id);
		Object.keys(payload).forEach((key) => {
			state.benefits[objectIndex][key] = payload[key];
		});
	},

	REMOVE_BENEFITS(state, payload) {
		let objectIndex;
		payload.id.forEach((arrayItem) => {
			objectIndex = state.benefits.findIndex((b) => b.id === arrayItem);
			objectIndex !== -1 && state.benefits.splice(objectIndex, 1);
		});

		let empNoBenefitId = [];
		// Get employee id with no benefit record
		payload.id.forEach((arrayItem) => {
			objectIndex = state.benefits.findIndex((b) => b.id === arrayItem);
			objectIndex === -1 && empNoBenefitId.push(arrayItem);
		});

		// Remove employee from the list of lazyEmployees
		// if no record found in benefits list
		empNoBenefitId.forEach((id) => {
			objectIndex = state.lazyEmployees.findIndex((b) => b.id === id);
			objectIndex !== -1 && state.lazyEmployees.splice(objectIndex, 1);
		});
	},

	REMOVE_EMPLOYEE_BENEFITS(state, payload) {
		payload.employee_id.forEach((arrayItem) => {
			// Get benefits bound to Employee ID
			let employeeBenefits = state.lazyEmployees.filter((benefit) => benefit.employee_id === arrayItem);
			// Iterate found employee benefits
			employeeBenefits.forEach((employeeBenefit) => {
				let objectIndex = state.lazyEmployees.findIndex((b) => b.employee_id === employeeBenefit.employee_id);
				objectIndex !== -1 && state.lazyEmployees.splice(objectIndex, 1);

				objectIndex = state.searchLists.findIndex((b) => b.employee_id === employeeBenefit.employee_id);
				objectIndex !== -1 && state.searchLists.splice(objectIndex, 1);
			});
		});
	},

	REMOVE_BENEFITS_EMPLOYEES(state, payload) {
		payload.employee_id.forEach((arrayItem) => {
			let objectIndex = state.lazyEmployees.findIndex((b) => b.employee_id === arrayItem);
			objectIndex !== -1 && state.lazyEmployees.splice(objectIndex, 1);
		});
	},
};

const actions = {
	TOGGLE_BENEFIT_DELETE({ commit }, payload) {
		commit('TOGGLE_BENEFIT_DELETE');
	},

	SHOW_ADD_FORM({ commit }, payload) {
		commit('SHOW_ADD_FORM');
	},

	HIDE_ADD_FORM({ commit }, payload) {
		commit('HIDE_ADD_FORM');
	},

	FETCH_BENEFITS({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/benefit',
				{
					success(response) {
						response.data.status === 'success' && commit('SET_BENEFITS', response.data.data);
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},
	FETCH_BENEFITS_DOWNLOAD({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/benefit',
				{
					success(response) {
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},

	FETCH_BENEFITS_LAZY({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/benefit',
				{
					success(response) {
						/** Commit Data */
						response.data.status === 'success' && commit('JOIN_BENEFITS_EMPLOYEES', response.data.data);
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},
	FIND_BENEFITS({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/benefit',
				{
					success(response) {
						response.data.status === 'success' && commit('SEARCH_BENEFITS', response.data.data);
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},
	LOOK_BENEFITS({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/benefit',
				{
					success(response) {
						response.data.status === 'success' && commit('SUGGEST_BENEFITS', response.data.data);
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},

	SAVE_BENEFIT({ commit }, payload) {
		console.log('SAVE_BENEFIT');
		return new Promise((resolve, reject) => {
			httpPost('juanHR/benefit', payload, {
				success(response) {
					if (response.data.status === 'success' && !response.data.data.insertion_success) {
						commit('INSERT_BENEFIT', response.data.data);
					}
					// response.data.status === 'success' &&
					// 	commit(
					// 		'INSERT_BENEFITS_EMPLOYEE',
					// 		response.data.data.insertion_success
					// 			? response.data.data.insertion_success
					// 			: response.data.data
					// 	);
					resolve(response.data);
				},
				catch(response) {},
			});
		});
	},

	SAVE_BENEFITS({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPost('juanHR/benefit', payload, {
				success(response) {
					// response.data.status === 'success' && commit('INSERT_BENEFITS', response.data.data);
					resolve(response.data);
				},
				catch(response) {},
			});
		});
	},

	UPDATE_BENEFIT({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPut(`juanHR/benefit/${payload.id}`, payload, {
				success(response) {
					response.data.status === 'success' && commit('MODIFY_BENEFIT', response.data.data);
					resolve(response.data);
				},
				catch(response) {},
			});
		});
	},

	DELETE_BENEFITS({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpDel(`juanHR/benefit/${payload.id}`, payload, {
				success(response) {
					/** If response is succss commit to remove branch on vuex */
					response.data.status === 'success' && commit('REMOVE_BENEFITS', payload);
					/** resolve promise */
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				},
			});
		});
	},

	DELETE_EMPLOYEE_BENEFITS({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpDel(`juanHR/benefit/${payload.employee_id}`, payload, {
				success(response) {
					/** If response is succss commit to remove branch on vuex */
					response.data.status === 'success' && commit('REMOVE_EMPLOYEE_BENEFITS', payload);
					/** resolve promise */
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				},
			});
		});
	},
};

export default { namespaced: true, state, getters, mutations, actions };
