import { httpGet, httpPost, httpPut, httpDel } from 'boot/axios';

const state = {
	exportData: [],
};

const getters = {
	GET_EXPORT_DATA: (state) => {
		return state.exportData;
	},
};

const mutations = {
	SET_EXPORT_DATA(state, payload) {
		state.exportData = payload;
	},
	MODIFY_EXPORT_COLUMNS(state, payload) {
		state.exportData.columns.numDays.data = payload;
	},
	MODIFY_EXPORT_DATA: (state, payload) => {
		Object.entries(state.exportData.columns).some(([pkey, column]) => {
			if (pkey == payload.key) {
				column.cellID = payload.cellID;
				return true;
			}
			if (typeof column.data !== 'undefined') {
				Object.entries(column.data).some(([ckey, data]) => {
					if (ckey == payload.key && pkey == payload.parentKey) {
						data.cellID = payload.cellID;
						return true;
					}
				});
			}
			if (typeof column.subColumn !== 'undefined') {
				Object.entries(column.subColumn).forEach(([skey, subcolumn]) => {
					if (typeof subcolumn.data !== 'undefined') {
						Object.entries(subcolumn.data).some(([sckey, subdata]) => {
							if (sckey == payload.key && skey == payload.parentKey) {
								subdata.cellID = payload.cellID;
								return true;
							}
						});
					} else {
						if (skey == payload.key && pkey == payload.parentKey) {
							subcolumn.cellID = payload.cellID;
							return true;
						}
					}
				});
			}
		});
	},
};

const actions = {
	FETCH_PAYROLL_REPORTS: ({ commit }, payload) => {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/payrollReports',
				{
					success(response) {
						if (response.data.status == 'success') {
							commit('SET_EXPORT_DATA', response.data.data);
							resolve(response);
						} else {
							reject(response);
						}
					},
					error(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},
};

export default { state, getters, mutations, actions };
