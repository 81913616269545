import { httpGet, httpPost, httpPut, httpDel } from 'boot/axios';
import { isObject } from './../../../shared/helpers/Common';

const state = {
	lists: [],
	lazyLists: [],
	deleteEnabled: false,
	displayLeaveForm: false,
	searchLists: [],
	suggestLists: [],
};

const getters = {
	GET_LEAVE: (state) => {
		return state.lists;
	},

	GET_LEAVE_LAZY(state) {
		return state.lazyLists;
	},

	GET_LEAVE_FORM_STATE: (state) => {
		return state.displayLeaveForm;
	},
	GET_LEAVE_DELETE_STATE: (state) => {
		return state.deleteEnabled;
	},
	GET_SEARCH_LEAVE: (state) => {
		return state.searchLists;
	},
	GET_SUGGEST_LEAVE: (state) => {
		return state.suggestLists;
	},
};

const mutations = {
	TOGGLE_LEAVE_DELETE(state, payload) {
		state.deleteEnabled = state.deleteEnabled !== true;
	},

	SET_LEAVE(state, payload) {
		state.lists = payload;
	},
	SET_LEAVE_LAZY(state, payload) {
		state.lazyLists = payload;
	},
	SEARCH_LEAVE(state, payload) {
		state.searchLists = payload;
	},
	SUGGEST_LEAVE(state, payload) {
		state.suggestLists = payload;
	},
	INSERT_LEAVE(state, payload) {
		let exist = state.lists.filter((ba) => ba.employee_id === Number(payload.employee_id));
		!exist.length && state.lists.unshift(payload);

		let exists = state.lazyLists.filter((ba) => ba.employee_id === Number(payload.employee_id));
		!exists.length && state.lazyLists.unshift(payload);
	},

	INSERT_LEAVE_BULK(state, payload) {
		// state.lists.unshift(payload.inserted);
		// state.lazyLists.unshift(payload.inserted);

		// let exist = state.lists.filter(ba => ba.employee_id === Number(payload.employee_id));
		// !exist.length && state.lists.unshift(payload);
		// !exist.length && state.lazyLists.unshift(payload);

		console.log(payload, 'this is payload');
		if (isObject(payload)) {
			payload.inserted.forEach((e) => {
				let exists = state.lists.filter((list) => list.employee_id === e.employee_id);
				!Boolean(exists.length) && state.lists.unshift(e);
				let existss = state.lazyLists.filter((list) => list.employee_id === e.employee_id);
				!Boolean(existss.length) && state.lazyLists.unshift(e);
			});
		} else {
			payload.forEach((e) => {
				let exists = state.lists.filter((list) => list.employee_id === e.employee_id);
				!Boolean(exists.length) && state.lists.unshift(e);

				let existss = state.lazyLists.filter((list) => list.employee_id === e.employee_id);
				!Boolean(existss.length) && state.lazyLists.unshift(e);
			});
		}
	},

	MODIFY_LEAVE(state, payload) {
		let objectIndex = state.lists.findIndex((e) => e.id === payload.id);
		objectIndex !== -1 &&
			Object.keys(state.lists[objectIndex]).forEach((key) => {
				state.lists[objectIndex][key] = payload[key];
			});
	},

	REMOVE_LEAVE(state, payload) {
		payload.id.forEach((arrayItem) => {
			// Get leaves bound to Employee ID
			let employeeLeavesid = state.lists.filter((lists) => lists.id === arrayItem);
			console.log(employeeLeavesid);
			// Iterate found employee leaves
			employeeLeavesid.forEach((employeeLeavesids) => {
				let objectIndex = state.lists.findIndex((b) => b.id === employeeLeavesids.id);
				objectIndex !== -1 && state.lists.splice(objectIndex, 1);
			});
		});
	},

	JOIN_LEAVE(state, payload) {
		payload.forEach((e) => {
			state.lazyLists.push(e);
		});
	},

	SET_LEAVE_FORM_STATE(state, payload) {
		state.displayLeaveForm = payload;
	},

	REMOVE_EMPLOYEE_LEAVES(state, payload) {
		payload.employee_id.forEach((arrayItem) => {
			// Get leaves bound to Employee ID
			let employeeLeaves = state.lazyLists.filter((lazyLists) => lazyLists.employee_id === arrayItem);
			console.log(employeeLeaves);
			// Iterate found employee leaves
			employeeLeaves.forEach((employeeLeave) => {
				let objectIndex = state.lazyLists.findIndex((b) => b.id === employeeLeave.id);
				objectIndex !== -1 && state.lazyLists.splice(objectIndex, 1);

				objectIndex = state.searchLists.findIndex((b) => b.id === employeeLeave.id);
				objectIndex !== -1 && state.searchLists.splice(objectIndex, 1);
			});
		});
	},
};

const actions = {
	FETCH_LEAVE({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/leave',
				{
					success(response) {
						response.data.status === 'success' && commit('SET_LEAVE', response.data.data);
						resolve(response.data);
						// console.log(response);
					},
					catch(response) {
						// console.log(response);
					},
				},
				payload
			);
		});
	},

	FETCH_LEAVE_LAZY({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/leave',
				{
					success(response) {
						/** Commit Data */
						response.data.status === 'success' && commit('JOIN_LEAVE', response.data.data);
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},
	FIND_LEAVE({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/leave',
				{
					success(response) {
						if (payload.search_type !== 'autosuggest' && response.data.status === 'success') {
							commit('SEARCH_LEAVE', response.data.data);
						}
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},
	LOOK_LEAVE({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/leave',
				{
					success(response) {
						response.data.status === 'success' && commit('SUGGEST_LEAVE', response.data.data);
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},
	FIND_LEAVE_DOWNLOAD({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/leave',
				{
					success(response) {
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},
	SAVE_LEAVE: ({ commit }, payload) => {
		return new Promise((resolve, reject) => {
			httpPost('juanHR/leave', payload.form, {
				success(response) {
					if (response.data.status === 'success') {
						let tosave = response.data.data;
						tosave.name = payload.others.name;
						tosave.full_name = payload.others.full_name;
						tosave.sex = payload.others.sex;
						tosave.photo = payload.others.photo;
						tosave.branch_name = response.data.data.branch_name;
						tosave.department_name = response.data.data.department_name;
						console.log(tosave, 'data to save');
						commit('INSERT_LEAVE', tosave);
					}
					resolve(response.data);
					// console.log(response);
				},
				catch(response) {
					reject(response.data);
					// console.log(response);
				},
			});
		});
	},

	UPLOAD_BULK_LEAVE({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPost(`juanHR/leave`, payload, {
				success(response) {
					response.data.status === 'success' && commit('INSERT_LEAVE_BULK', response.data.data);
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				},
			});
		});
	},

	UPDATE_LEAVE({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPut(`juanHR/leave/${payload.id}`, payload, {
				success(response) {
					/** Commit Data */
					response.data.status === 'success' && commit('MODIFY_LEAVE', response.data.data);
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				},
			});
		});
	},

	DELETE_LEAVE({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpDel(`juanHR/leave/${payload.id}`, payload, {
				success(response) {
					/** If response is succss commit to remove branch on vuex */
					response.data.status === 'success' && commit('REMOVE_LEAVE', payload);
					/** resolve promise */
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				},
			});
		});
	},

	DELETE_EMPLOYEE_LEAVES({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpDel(`juanHR/leave/${payload.employee_id}`, payload, {
				success(response) {
					/** If response is succss commit to remove branch on vuex */
					response.data.status === 'success' && commit('REMOVE_EMPLOYEE_LEAVES', payload);
					/** resolve promise */
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				},
			});
		});
	},
	TOGGLE_LEAVE_DELETE({ commit }, payload) {
		commit('TOGGLE_LEAVE_DELETE');
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
