import { httpGet, httpPost, httpPut, httpDel } from 'boot/axios';

const state = {
	lists: [],
	lazyLists: [],
	deleteEnabled: false,
	displayLeaveTypeForm: false,
};

const getters = {
	GET_LEAVE_TYPE: (state) => {
		return state.lists;
	},

	GET_LEAVE_TYPE_LAZY(state) {
		return state.lazyLists;
	},

	GET_LEAVE_TYPE_FORM_STATE: (state) => {
		return state.displayLeaveTypeForm;
	},
	GET_LEAVE_TYPE_DELETE_STATE: (state) => {
		return state.deleteEnabled;
	},
};

const mutations = {
	TOGGLE_LEAVE_TYPE_DELETE(state, payload) {
		state.deleteEnabled = state.deleteEnabled !== true;
	},
	SET_LEAVE_TYPE(state, payload) {
		state.lists = payload;
	},

	JOIN_LEAVE_TYPE(state, payload) {
		payload.forEach((e) => {
			state.lazyLists.push(e);
		});
	},

	INSERT_LEAVE_TYPE(state, payload) {
		let exist = state.lists.filter((ba) => ba.leave_type_id === Number(payload.leave_type_id));
		!exist.length && state.lists.unshift(payload);

		let exist2 = state.lazyLists.filter((ba) => ba.leave_type_id === Number(payload.leave_type_id));
		!exist2.length && state.lazyLists.unshift(payload);
	},

	SET_LEAVE_TYPE_FORM_STATE(state, payload) {
		state.displayLeaveTypeForm = payload;
	},

	MODIFY_LEAVE_TYPE(state, payload) {
		let objectIndex = state.lists.findIndex((e) => e.leave_type_id === payload.leave_type_id);
		objectIndex !== -1 &&
			Object.keys(state.lists[objectIndex]).forEach((key) => {
				state.lists[objectIndex][key] = payload[key];
			});

		let objectIndex2 = state.lazyLists.findIndex((e) => e.leave_type_id === payload.leave_type_id);
		console.log(state.lazyLists);
		objectIndex2 !== -1 &&
			Object.keys(state.lazyLists[objectIndex2]).forEach((key) => {
				state.lazyLists[objectIndex2][key] = payload[key];
			});
	},

	REMOVE_LEAVE_TYPE(state, payload) {
		payload.leave_type_id.forEach((arrayItem) => {
			// Get leaves bound to Employee ID
			let employeeLeavesConfig = state.lists.filter((lists) => lists.leave_type_id === arrayItem);
			console.log(employeeLeavesConfig);
			// Iterate found employee leaves
			employeeLeavesConfig.forEach((employeeLeavesConfigs) => {
				let objectIndex = state.lists.findIndex((b) => b.leave_type_id === employeeLeavesConfigs.leave_type_id);
				objectIndex !== -1 && state.lists.splice(objectIndex, 1);
			});
		});
	},

	REMOVE_EMPLOYEE_LEAVE_TYPE(state, payload) {
		payload.leave_type_id.forEach((arrayItem) => {
			// Get leaves bound to Employee ID
			let employeeLeavesConfig = state.lists.filter((lists) => lists.leave_type_id === arrayItem);
			console.log(employeeLeavesConfig);
			// Iterate found employee leaves
			employeeLeavesConfig.forEach((employeeLeavesConfigs) => {
				let objectIndex = state.lists.findIndex((b) => b.leave_type_id === employeeLeavesConfigs.leave_type_id);
				objectIndex !== -1 && state.lists.splice(objectIndex, 1);
			});
		});

		payload.leave_type_id.forEach((arrayItem) => {
			// Get leaves bound to Employee ID
			let employeeLeavesConfig = state.lazyLists.filter((lazyLists) => lazyLists.leave_type_id === arrayItem);
			console.log(employeeLeavesConfig);
			// Iterate found employee leaves
			employeeLeavesConfig.forEach((employeeLeavesConfigs) => {
				let objectIndex = state.lazyLists.findIndex(
					(b) => b.leave_type_id === employeeLeavesConfigs.leave_type_id
				);
				objectIndex !== -1 && state.lazyLists.splice(objectIndex, 1);
			});
		});
	},
};

const actions = {
	FETCH_LEAVE_TYPES({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/leaveType',
				{
					success(response) {
						response.data.status === 'success' && commit('SET_LEAVE_TYPE', response.data.data);
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},
	FETCH_LEAVE_TYPES_MAX_ID({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/leaveType',
				{
					success(response) {
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},

	FETCH_LEAVE_TYPES_LAZY({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/leaveType',
				{
					success(response) {
						/** Commit Data */
						response.data.status === 'success' && commit('JOIN_LEAVE_TYPE', response.data.data);
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},

	SAVE_LEAVE_TYPE: ({ commit }, payload) => {
		return new Promise((resolve, reject) => {
			httpPost('juanHR/leaveType', payload, {
				success(response) {
					response.data.status === 'success' && commit('INSERT_LEAVE_TYPE', response.data.data);
					resolve(response.data);
					console.log(response);
				},
				catch(response) {
					reject(response.data);
					console.log(response);
				},
			});
		});
	},

	UPDATE_LEAVE_TYPE({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPut(`juanHR/leaveType/${payload.leave_type_id}`, payload, {
				success(response) {
					/** Commit Data */
					response.data.status === 'success' && commit('MODIFY_LEAVE_TYPE', response.data.data);
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				},
			});
		});
	},

	DELETE_LEAVE_TYPE({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpDel(
				`juanHR/leaveType/${payload.leave_type_id}`,
				{
					leave_type_id: [payload.leave_type_id],
				},
				{
					success(response) {
						/** If response is succss commit to remove branch on vuex */
						response.data.status === 'success' && commit('REMOVE_LEAVE_TYPE', payload);
						/** resolve promise */
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				}
			);
		});
	},

	DELETE_EMPLOYEE_LEAVE_TYPE({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpDel(`juanHR/leaveType/${payload.leave_type_id}`, payload, {
				success(response) {
					/** If response is succss commit to remove branch on vuex */
					response.data.status === 'success' && commit('REMOVE_EMPLOYEE_LEAVE_TYPE', payload);
					/** resolve promise */
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				},
			});
		});
	},
	TOGGLE_LEAVE_TYPE_DELETE({ commit }, payload) {
		commit('TOGGLE_LEAVE_TYPE_DELETE');
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
