import { httpGet, httpPost, httpDel, httpPut } from 'boot/axios';

const state = {
	adjustmentList: [],
};

const getters = {
	GET_ADJUSTMENT_LIST: (state) => {
		return state.adjustmentList;
	},
};

const mutations = {
	SET_ADJUSTMENT_LIST(state, payload) {
		state.adjustmentList = payload;
	},

	INSERT_ADJUSTMENT_LIST(state, payload) {
		state.adjustmentList.unshift(payload);
	},

	MODIFY_ADJUSTMENT_LIST(state, payload) {
		let objectIndex = state.adjustmentList.findIndex((a) => a.adjustment_id === payload.adjustment_id);
		Object.keys(state.adjustmentList[objectIndex]).forEach((key) => {
			state.adjustmentList[objectIndex][key] = payload[key];
		});
	},

	REMOVE_ADJUSTMENT_LIST(state, payload) {
		payload.adjustment_id.forEach((adjustment_id) => {
			let objectIndex = state.adjustmentList.findIndex((a) => a.adjustment_id === adjustment_id);
			objectIndex !== -1 && state.adjustmentList.splice(objectIndex, 1);
		});
	},
};

const actions = {
	FETCH_ADJUSTMENT_LIST({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet('juanHR/adjustmentConfiguration', {
				success(response) {
					response.data.status === 'success' && commit('SET_ADJUSTMENT_LIST', response.data.data);
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				},
			});
		});
	},

	FETCH_ADJUSTMENT_LIST_FOR_DOWNLOAD({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/adjustmentConfiguration',
				{
					success(response) {
						// response.data.status === 'success' && commit('SET_ADJUSTMENT_LIST', response.data.data);
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},

	SAVE_ADJUSTMENT_LIST({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPost('juanHR/adjustmentConfiguration', payload, {
				success(response) {
					response.data.status === 'success' && commit('INSERT_ADJUSTMENT_LIST', response.data.data);
					resolve(response.data);
				},
				catch(response) {},
			});
		});
	},

	UPDATE_ADJUSTMENT_LIST({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPut(`juanHR/adjustmentConfiguration/${payload.adjustment_id}`, payload, {
				success(response) {
					response.data.status === 'success' && commit('MODIFY_ADJUSTMENT_LIST', response.data.data);
					resolve(response.data);
				},
				catch(response) {},
			});
		});
	},

	DELETE_ADJUSTMENT_LIST({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpDel(`juanHR/adjustmentConfiguration/${payload.adjustment_id}`, payload, {
				success(response) {
					/** If response is succss commit to remove branch on vuex */
					response.data.status === 'success' && commit('REMOVE_ADJUSTMENT_LIST', payload);
					/** resolve promise */
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				},
			});
		});
	},
};

export default { namespaced: true, state, getters, mutations, actions };
