import { httpGet, httpPost, httpDel, httpPut } from 'boot/axios';

const state = {
	configurations: [],
};

const getters = {
	GET_PAGIBIG_CONFIGURATIONS: (state) => {
		return state.configurations;
	},
};

const mutations = {
	SET_PAGIBIG_CONFIGURATIONS(state, payload) {
		state.configurations = payload;
	},

	INSERT_PAGIBIG_CONFIGURATION(state, payload) {
		state.configurations.unshift(payload);
	},

	MODIFY_PAGIBIG_CONFIGURATION(state, payload) {
		let objectIndex = state.configurations.findIndex((b) => b.configuration_id === payload.configuration_id);
		Object.keys(state.configurations[objectIndex]).forEach((key) => {
			state.configurations[objectIndex][key] = payload[key];
		});
	},

	REMOVE_PAGIBIG_CONFIGURATION(state, payload) {
		let objectIndex = state.configurations.findIndex((b) => b.configuration_id === payload.configuration_id);
		objectIndex !== -1 && state.configurations.splice(objectIndex, 1);
	},
};

const actions = {
	FETCH_PAGIBIG_CONFIGURATIONS({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet('juanHR/benefitConfigurationPagibig', {
				success(response) {
					response.data.status === 'success' && commit('SET_PAGIBIG_CONFIGURATIONS', response.data.data);
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				},
			});
		});
	},

	SAVE_PAGIBIG_CONFIGURATION({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPost('juanHR/benefitConfigurationPagibig', payload, {
				success(response) {
					response.data.status === 'success' && commit('INSERT_PAGIBIG_CONFIGURATION', response.data.data);
					resolve(response.data);
				},
				catch(response) {},
			});
		});
	},

	UPDATE_PAGIBIG_CONFIGURATION({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPut(`juanHR/benefitConfigurationPagibig/${payload.configuration_id}`, payload, {
				success(response) {
					response.data.status === 'success' && commit('MODIFY_PAGIBIG_CONFIGURATION', response.data.data);
					resolve(response.data);
				},
				catch(response) {},
			});
		});
	},

	DELETE_PAGIBIG_CONFIGURATION({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpDel(`juanHR/benefitConfigurationPagibig/${payload.configuration_id}`, payload, {
				success(response) {
					/** If response is succss commit to remove branch on vuex */
					response.data.status === 'success' && commit('REMOVE_PAGIBIG_CONFIGURATION', payload);
					/** resolve promise */
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				},
			});
		});
	},
};

export default { namespaced: true, state, getters, mutations, actions };
