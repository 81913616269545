export default [
	{
		path: '/juanHR',
		name: 'juanHR',
		redirect: { name: 'dashboard' },
		component: () => import(/* webpackChunkName "CoreIndex" */ '../layouts/MainLayout'),

		children: [
			/***
			 *
			 * Dashboard Module Routes
			 *
			 ***/
			{
				path: 'dashboard',
				component: () => import('./../pages/Home/Constructor'),
				children: [
					{
						path: '',
						name: 'dashboard',
						component: () => import(/* webpackChunkName "Main"*/ './../pages/Home/Main'),
						// children: [
						// 	{
						// 		path: '',
						// 		name: 'juanhrHome',
						// 		component: () => import(/* webpackChunkName "Main"*/ './../pages/Home/Main'),
						// 	},
						// ],
					},
				],
			},
			// {
			// 	path: 'payroll',
			// 	component: () => import(/* webpackChunkName "Layout"*/ './../pages/Payroll/Layout'),
			// 	children: [
			// 		{
			// 			path: '',
			// 			name: 'payroll',
			// 			component: () =>
			// 				import(
			// 					/* webpackChunkName "PayrollMain"*/
			// 					'./../pages/Payroll/Payroll/Main'
			// 				),
			// 		},
			// 		{
			// 			path: 'reports',
			// 			name: 'juanhrPayrollReports',
			// 			component: () =>
			// 				import(
			// 					/* webpackChunkName "ReportsMain"*/
			// 					'./../pages/Payroll/Reports/Main'
			// 				),
			// 		},
			// 		{
			// 			path: 'configuration',
			// 			name: 'juanhrPayrollConfiguration',
			// 			component: () =>
			// 				import(
			// 					/* webpackChunkName "ConfigurationMain"*/
			// 					'./../pages/Payroll/Configuration/Main'
			// 				),
			// 		},
			// 		{
			// 			path: ':employee_id?/information',
			// 			name: 'employeePayrollInfo',
			// 			component: () =>
			// 				import(
			// 					/* webpackChunkName "PayrollEmployeePayrollInfo"*/
			// 					'./../pages/Payroll/Payroll/Information'
			// 				),
			// 		},
			// 		{
			// 			path: 'generate',
			// 			name: 'juanhrPayrollGenerate',
			// 			component: () =>
			// 				import(
			// 					/* webpackChunkName "PayrollGenerate"*/
			// 					'./../pages/Payroll/Payroll/Generate'
			// 				),
			// 		},
			// 	],
			// },
			/***
			 *
			 * Employee Module Routes
			 *
			 ***/
			{
				path: 'employee',
				component: () => import(/* webpackChunkName "Main"*/ './../pages/Employee/Layout'),
				children: [
					{
						path: '',
						name: 'employee',
						component: () => import(/* webpackChunkName "Main"*/ './../pages/Employee/Pages/Lists'),
					},
					{
						path: 'new',
						name: 'juanhrEmployeeForm',
						component: () => import(/* webpackChunkName "Main"*/ './../pages/Employee/Pages/New'),
					},
					{
						path: '',
						component: () => import('./../pages/Employee/Pages/TabLayout'),
						beforeEnter(to, from, next) {
							console.log('Test fires');
							next();
						},
						children: [
							{
								path: ':employee_id?/information',
								name: 'juanhrViewEmployeeInfo',
								component: () =>
									import(/* webpackChunkName "Main"*/ './../pages/Employee/Pages/Personal'),
							},
							{
								path: ':employee_id?/family',
								name: 'juanhrViewEmployeeFamilyBackground',
								component: () =>
									import(/* webpackChunkName "Main"*/ './../pages/Employee/Pages/Family'),
							},
							{
								path: ':employee_id?/education',
								name: 'juanhrViewEmployeeEducation',
								component: () =>
									import(/* webpackChunkName "Main"*/ './../pages/Employee/Pages/Education'),
							},
							{
								path: ':employee_id?/civil_service',
								name: 'juanhrViewEmployeeCivilService',
								component: () =>
									import(/* webpackChunkName "Main"*/ './../pages/Employee/Pages/CivilService'),
							},
							{
								path: ':employee_id?/work_experience',
								name: 'juanhrViewEmployeeWorkExperience',
								component: () =>
									import(/* webpackChunkName "Main"*/ './../pages/Employee/Pages/WorkExperience'),
							},
							{
								path: ':employee_id?/voluntary_work',
								name: 'juanhrViewEmployeeVoluntaryWork',
								component: () =>
									import(
										/** it has .vue extension because VoluntaryWork is Special to vue */
										/* webpackChunkName "Main"*/
										'./../pages/Employee/Pages/VoluntaryWork.vue'
									),
							},
							{
								path: ':employee_id?/learning_development',
								name: 'juanhrViewEmployeeLearningDevelopment',
								component: () =>
									import(
										/* webpackChunkName "Main"*/ './../pages/Employee/Pages/LearningDevelopment'
									),
							},
							{
								path: ':employee_id?/others',
								name: 'juanhrViewEmployeeOthers',
								component: () =>
									import(/* webpackChunkName "Main"*/ './../pages/Employee/Pages/Others'),
							},
							{
								path: ':employee_id?/files_201',
								name: 'juanhrViewEmployeeFiles201',
								component: () =>
									import(/* webpackChunkName "Main"*/ './../pages/Employee/Pages/Files201'),
							},
						],
					},
				],
			},
			/***
			 *
			 * Master lists Module Routes
			 *
			 ***/
			{
				path: 'masterlist',
				component: () => import('./../pages/MasterLists/Layout'),
				children: [
					{
						path: '',
						name: 'masterlist',
						component: () => import('./../pages/MasterLists/Branch'),
					},
				],
			},

			/****
			 *
			 * 13th Month Pay
			 *
			 */
			// {
			//   path: 'thirteenthMonthPay',
			//   component: () => import('./../pages/13thMonth/Layout'),
			//   children: [
			//     {
			// 			path: '',
			// 			name: 'thirteenthMonthPay',
			// 			component: () => import(/* webpackChunkName "Main"*/ './../pages/13thMonth/pages/Lists'),
			// 		},
			//     {
			// 			path: 'data',
			// 			name: 'thirteenthMonthData',
			// 			component: () => import(/* webpackChunkName "Main"*/ './../pages/13thMonth/pages/Information'),
			// 		},
			//     {
			// 			path: 'config',
			// 			name: 'thirteenthMonthConfig',
			// 			component: () => import(/* webpackChunkName "Main"*/ './../pages/13thMonth/pages/Configuration'),
			// 		},
			//   ]
			// },

			/***
			 *
			 * Overtime And Holidays Module Routes
			 *
			 ***/
			{
				path: 'overtimeAndHoliday',
				component: () => import(/* webpackChunkName "Main"*/ './../pages/OvertimeAndHolidays/Layout'),
				children: [
					{
						path: '',
						name: 'overtimeAndHoliday',
						component: () =>
							import(/* webpackChunkName "Main"*/ './../pages/OvertimeAndHolidays/Pages/Lists'),
					},
					{
						path: 'holidays',
						name: 'holidays',
						component: () =>
							import(/* webpackChunkName "Main"*/ './../pages/OvertimeAndHolidays/Pages/Holidays/Lists'),
					},
					{
						path: 'overtime',
						component: () => import('./../pages/OvertimeAndHolidays/Pages/Overtime/TabLayout'),
						children: [
							{
								path: ':employee_id/additional_pay',
								name: '',
								component: () =>
									import(
										/* webpackChunkName "Main"*/ './../pages/OvertimeAndHolidays/Pages/Overtime/AdditionalPay'
									),

								children: [
									{
										path: '',
										name: 'juanhrOvertimeAdditionalPay',
										component: () =>
											import(
												/* webpackChunkName "Main"*/ './../pages/OvertimeAndHolidays/Pages/Overtime/Table'
											),
									},
									{
										path: ':id/edit',
										name: 'juanhrOvertimeAdditionalPayEdit',
										component: () =>
											import(
												/* webpackChunkName "Main"*/ './../pages/OvertimeAndHolidays/Pages/Overtime/Information'
											),
									},
								],
							},
							{
								path: ':employee_id/regular_pay',
								component: () =>
									import(
										/* webpackChunkName "Main"*/ './../pages/OvertimeAndHolidays/Pages/Overtime/RegularPay'
									),
								children: [
									{
										path: '',
										name: 'juanhrOvertimeRegularlPay',
										component: () =>
											import(
												/* webpackChunkName "Main"*/ './../pages/OvertimeAndHolidays/Pages/Overtime/Table'
											),
									},
									{
										path: ':id/edit',
										name: 'juanhrOvertimeRegularlPayEdit',
										component: () =>
											import(
												/* webpackChunkName "Main"*/ './../pages/OvertimeAndHolidays/Pages/Overtime/Information'
											),
									},
								],
							},
						],
					},
				],
			},
			/***
			 *
			 * Memos And Notices Module Routes
			 *
			 ***/
			// {
			// 	path: 'memoAndNotice',
			// 	component: () =>
			// 		import(
			// 			/* webpackChunkName "MemosAndNoticesLayout" */
			// 			'./../pages/MemosAndNotices/Layout'
			// 		),
			// 	children: [
			// 		{
			// 			path: '',
			// 			name: 'memoAndNotice',
			// 			component: () =>
			// 				import(
			// 					/* webpackChunkName "MemosAndNoticesList" */
			// 					'./../pages/MemosAndNotices/List'
			// 				),
			// 		},
			// 		{
			// 			path: 'add',
			// 			name: 'memosAndNoticesAdd',
			// 			component: () =>
			// 				import(
			// 					/* webpackChunkName "MemosAndNoticesAdd" */
			// 					'./../pages/MemosAndNotices/Add'
			// 				),
			// 		},
			// 		{
			// 			path: ':id?/information',
			// 			name: 'memosAndNoticesInformation',
			// 			component: () =>
			// 				import(
			// 					/* webpackChunkName "MemosAndNoticesInformation" */
			// 					'./../pages/MemosAndNotices/Add'
			// 				),
			// 		},
			// 	],
			// },
			/***
			 *
			 * Schedules Module Routes
			 *
			 ***/
			{
				path: 'schedule',
				component: () =>
					import(
						/* webpackChunkName "SchedulesLayout" */
						'./../pages/Schedules/Layout'
					),
				children: [
					{
						path: '',
						name: 'schedule',
						component: () =>
							import(
								/* webpackChunkName "SchedulesEmployeeSchedules" */
								'../pages/Schedules/EmployeeSchedules/Main'
							),
					},
					{
						path: 'addschedule',
						name: 'employeeSchedulesAddSchedule',
						component: () =>
							import(
								/* webpackChunkName "SchedulesEmployeeAddSchedule" */
								'./../pages/Schedules/EmployeeSchedules/AddSchedule'
							),
					},
					{
						path: 'createtemplate',
						name: 'employeeSchedulesCreateTemplate',
						component: () =>
							import(
								/* webpackChunkName "SchedulesEmployeeCreateTemplate" */
								'./../pages/Schedules/EmployeeSchedules/CreateTemplate'
							),
					},
					{
						path: ':employee_id?/information',
						name: 'employeeSchedulesInfo',
						component: () =>
							import(
								/* webpackChunkName "SchedulesEmployeeInformation" */
								'./../pages/Schedules/EmployeeSchedules/Information'
							),
					},
				],
			},
			/***
			 *
			 * Allowance Module Routes
			 *
			 ***/
			// {
			// 	path: 'allowance',
			// 	component: () => import(/* webpackChunkName "Layout"*/ './../pages/Allowance/Layout'),
			// 	children: [
			// 		{
			// 			path: '',
			// 			name: 'allowance',
			// 			component: () =>
			// 				import(/* webpackChunkName "allowances"*/ './../pages/Allowance/Pages/Allowance'),
			// 			children: [],
			// 		},
			// 		{
			// 			path: ':employeeId?/:allowance_name',
			// 			name: 'allowancesView',
			// 			component: () =>
			// 				import(
			// 					/* webpackChunkName "allowancesView"*/

			// 					'./../pages/Allowance/Pages/AllowanceView'
			// 				),
			// 		},
			// 		{
			// 			path: 'configuration',
			// 			component: () =>
			// 				import(
			// 					/* webpackChunkName "AllowancesConfigurationConstructor"*/
			// 					'./../pages/Allowance/Pages/ConfigConstructor'
			// 				),
			// 			children: [
			// 				{
			// 					path: 'configuration',
			// 					name: 'allowancesConfiguration',
			// 					component: () =>
			// 						import(
			// 							/* webpackChunkName "AllowancesConfigurationMain"*/
			// 							'./../pages/Allowance/Pages/Configuration'
			// 						),
			// 					children: [],
			// 				},
			// 				{
			// 					path: ':allowanceType?/',
			// 					name: 'allowancesConfigurationView',
			// 					component: () =>
			// 						import(
			// 							/* webpackChunkName "AllowancesConfigurationView"*/
			// 							'./../pages/Allowance/Pages/ConfigurationView'
			// 						),
			// 					children: [],
			// 				},
			// 			],
			// 		},
			// 	],
			// },
			/***
			 *
			 * Biometrics Module Routes
			 *
			 ***/
			{
				path: '',
				component: () => import(/* webpackChunkName "juanhrBiometrics"*/ './../pages/Biometric/Layout'),
				children: [
					{
						path: 'biometric',
						name: 'biometric',
						component: () =>
							import(/* webpackChunkName "juanhrBiometrics"*/ './../pages/Biometric/Pages/Biometrics'),
					},
					{
						path: ':device_id?/',
						name: 'biometricsDevice',
						component: () =>
							import(
								/* webpackChunkName "biometricsDevice"*/
								'./../pages/Biometric/Pages/BiometricsDevice'
							),
					},
					{
						path: 'biometricsDeviceLogs',
						name: 'biometricsDeviceLogs',
						component: () =>
							import(
								/* webpackChunkName "biometricsDeviceLogs"*/
								'./../pages/Biometric/Pages/BiometricsDeviceLogs'
							),
					},
					{
						path: 'employee-device/',
						component: () =>
							import(
								/* webpackChunkName "biometricsDevice"*/
								'./../pages/Biometric/Pages/EmployeeConstructor'
							),

						children: [
							{
								path: '',
								name: 'biometricsEmployees',
								component: () =>
									import(
										/* webpackChunkName "juanhrBiometricsEmployees"*/
										'./../pages/Biometric/Pages/BiometricsDevice'
									),
							},
							{
								path: ':employee_id?/',
								name: 'biometricsEmployeeView',
								component: () =>
									import(
										/* webpackChunkName "juanhrBiometricsEmployeeView"*/
										'./../pages/Biometric/Pages/BiometricsEmployeeView'
									),
							},
						],
					},
				],
			},
			/***
			 *
			 * Service Record Module Routes
			 *
			 ***/
			{
				path: '',
				component: () => import(/* webpackChunkName "Layout"*/ './../pages/ServiceRecord/Layout'),
				children: [
					{
						path: 'serviceRecord',
						name: 'serviceRecord',
						component: () =>
							import(
								/* webpackChunkName "serviceRecord"*/
								'./../pages/ServiceRecord/Pages/ServiceRecord'
							),
						children: [],
					},
					{
						path: 'new',
						name: 'serviceRecordNew',
						component: () =>
							import(
								/* webpackChunkName "juanhrServiceRecordNew"*/
								'./../pages/ServiceRecord/Pages/NewServiceRecord'
							),
					},
					{
						path: ':employeeId?',
						name: 'serviceRecordView',
						component: () =>
							import(
								/* webpackChunkName "juanhrServiceRecordView"*/

								'./../pages/ServiceRecord/Pages/ServiceRecordView'
							),
					},
					{
						path: 'position',
						component: () =>
							import(
								/* webpackChunkName "serviceRecordPositionConstructor"*/
								'./../pages/ServiceRecord/Pages/PositionConstructor'
							),
						children: [
							{
								path: '',
								name: 'serviceRecordPosition',
								component: () =>
									import(
										/* webpackChunkName "serviceRecordPosition"*/
										'./../pages/ServiceRecord/Pages/Position'
									),
								children: [],
							},
							{
								path: ':position?/',
								name: 'serviceRecordPositionView',
								component: () =>
									import(
										/* webpackChunkName "serviceRecordConfigurationView"*/
										'./../pages/ServiceRecord/Pages/PositionView'
									),
								children: [],
							},
						],
					},
				],
			},
			/***
			 *
			 * Leaves Module Routes
			 *
			 ***/
			{
				path: '',
				component: () => import(/* webpackChunkName "LeaveLayout"*/ './../pages/Leave/Layout'),

				children: [
					{
						path: 'leave',
						name: 'leave',
						component: () => import(/* webpackChunkName "LeavePAge"*/ './../pages/Leave/Pages/Leave'),
					},
					{
						path: ':employee_id?/',
						name: 'leaveView',
						component: () => import(/* webpackChunkName "leaveView"*/ './../pages/Leave/Pages/LeaveView'),
					},
					{
						path: 'configuration',
						name: 'leaveConfiguration',
						component: () =>
							import(/* webpackChunkName "leaveConfiguration"*/ './../pages/Leave/Pages/Configuration'),
					},
					{
						path: 'types',
						name: 'leaveTypes',
						component: () => import(/* webpackChunkName "LeaveType"*/ './../pages/Leave/Pages/LeaveType'),
					},
					{
						path: ':leaveTypeID?/',
						name: 'leaveTypesView',
						component: () =>
							import(/* webpackChunkName "LeaveType"*/ './../pages/Leave/Pages/LeaveTypeView'),
					},
					{
						path: 'approvals',
						name: 'leaveSettings',
						component: () =>
							import(/* webpackChunkName "leaveConfiguration"*/ './../pages/Leave/Pages/Settings'),
					},
				],
			},
			/***
			 *
			 * Benefits Module Routes
			 *
			 ***/
			// {
			// 	path: 'benefit',
			// 	component: () => import(/* webpackChunkName "BenefitsLayout" */ './../pages/Benefits/Layout'),
			// 	children: [
			// 		{
			// 			path: '',
			// 			name: 'benefit',
			// 			component: () =>
			// 				import(
			// 					/* webpackChunkName "BenefitsEmployeeBenefits" */
			// 					'./../pages/Benefits/EmployeeBenefits/Main'
			// 				),
			// 		},
			// 		{
			// 			path: 'configuration',
			// 			name: 'employeeBenefitsConfig',
			// 			component: () =>
			// 				import(
			// 					/* webpackChunkName "BenefitsConfiguration" */
			// 					'./../pages/Benefits/Configuration/Main'
			// 				),
			// 		},
			// 		{
			// 			path: ':employee_id?/information',
			// 			name: 'employeeBenefitsInfo',
			// 			component: () =>
			// 				import(
			// 					/* webpackChunkName "BenefitsEmployeeBenefitsInfo" */
			// 					'./../pages/Benefits/EmployeeBenefits/Information'
			// 				),
			// 		},
			// 	],
			// },
			/***
			 *
			 * Loans Module Routes
			 *
			 ***/
			// {
			// 	path: '',
			// 	component: () => import(/* Loan "LoanLayout" */ './../pages/Loan/Layout'),
			// 	children: [
			// 		{
			// 			path: 'loan',
			// 			name: 'loan',
			// 			component: () => import(/* webpackChunkName "loan"*/ './../pages/Loan/Pages/Loan'),
			// 			children: [],
			// 		},
			// 		{
			// 			path: ':employeeId?/:loan_name',
			// 			name: 'loanView',
			// 			component: () =>
			// 				import(
			// 					/* webpackChunkName "loanView"*/

			// 					'./../pages/Loan/Pages/LoanView'
			// 				),
			// 			children: [],
			// 		},
			// 		{
			// 			path: '',
			// 			component: () =>
			// 				import(
			// 					/* webpackChunkName "LoanConfigurationConstructor"*/
			// 					'./../pages/Loan/Pages/ConfigConstructor'
			// 				),
			// 			children: [
			// 				{
			// 					path: 'configuration',
			// 					name: 'loansConfiguration',
			// 					component: () =>
			// 						import(
			// 							/* webpackChunkName "LoanssConfigurationMain"*/
			// 							'./../pages/Loan/Pages/Configuration'
			// 						),
			// 					children: [],
			// 				},
			// 				{
			// 					path: ':loanType?/',
			// 					name: 'loansConfigurationView',
			// 					component: () =>
			// 						import(
			// 							/* webpackChunkName "loansConfigurationView"*/
			// 							'./../pages/Loan/Pages/ConfigurationView'
			// 						),
			// 					children: [],
			// 				},
			// 			],
			// 		},
			// 	],
			// },
			/***
			 *
			 * Daily Time Records Module Routes
			 *
			 ***/
			{
				path: 'dtr',
				component: () => import(/* webpackChunkName "Main"*/ './../pages/DTR/Layout'),
				children: [
					{
						path: '',
						name: 'dtr',
						component: () => import(/* webpackChunkName "Main"*/ './../pages/DTR/Pages/Lists'),
					},
					{
						path: ':employeeId/dtr',
						name: 'juanhrEmployeeDtr',
						component: () => import(/* webpackChunkName "Main"*/ './../pages/DTR/Pages/EmployeeDTR'),
					},
				],
			},
			/***
			 *
			 * Adjustment Module Routes
			 *
			 ***/
			// {
			// 	path: 'adjustment',
			// 	component: () => import(/* webpackChunkName "AdjustmentsLayout" */ './../pages/Adjustments/Layout'),
			// 	children: [
			// 		{
			// 			path: '',
			// 			name: 'adjustment',
			// 			component: () =>
			// 				import(
			// 					/* webpackChunkName "AdjustmentsEmployeeAdjustments" */
			// 					'./../pages/Adjustments/EmployeeAdjustments/Main'
			// 				),
			// 		},
			// 		{
			// 			path: 'list',
			// 			name: 'employeeAdjustmentsList',
			// 			component: () =>
			// 				import(
			// 					/* webpackChunkName "AdjustmentsAdjustmentList" */
			// 					'./../pages/Adjustments/AdjustmentList/Main'
			// 				),
			// 		},
			// 		{
			// 			path: ':employeeId?/information',
			// 			name: 'employeeAdjustmentsInfo',
			// 			component: () =>
			// 				import(
			// 					/* webpackChunkName "SchedulesEmployeeInformation" */
			// 					'./../pages/Adjustments/EmployeeAdjustments/Information'
			// 				),
			// 		},
			// 	],
			// },

			/***
			 * Organization Management Module Routes
			 *
			 ***/
			// {
			// 	path: 'organizationManagement',
			// 	component: () => import(/* webpackChunkName "Main"*/ './../pages/OrganizationStructure/Layout'),
			// 	children: [
			// 		{
			// 			path: '',
			// 			name: 'organizationManagement',
			// 			component: () => import(/* webpackChunkName "Main"*/ './../pages/OrganizationStructure/Layout'),
			// 		},
			// 	],
			// },

			/***
			 *
			 * Branch Assignements Module Routes
			 *
			 ***/
			{
				path: 'branchAssignment',
				component: () =>
					import(/* webpackChunkName "BranchAssignmentLayout" */ './../pages/BranchAssignments/Layout'),

				children: [
					{
						path: '',
						name: 'branchAssignment',
						component: () =>
							import(/* webpackChunkName "Main"*/ './../pages/BranchAssignments/Pages/Lists'),
					},
					{
						path: ':employeeId?/information',
						name: 'branchAssignmentsEmployeeInfo',
						component: () =>
							import(/* webpackChunkName "Main"*/ './../pages/BranchAssignments/pages/Information'),
					},
				],
			},
			/***
			 *
			 * Attendance Statistics Module Routes
			 *
			 ***/
			// {
			// 	path: 'attendanceStatistics',
			// 	name: 'attendanceStatistics',
			// 	component: () => import(/* webpackChunkName "Layout"*/ './../pages/AttendaceStatistics/Layout'),
			// },
			/***
			 * Attendance Monitoring
			 *
			 ***/
			{
				path: 'attendanceMonitoring',
				component: () => import(/* webpackChunkName "Main"*/ './../pages/AttendanceMonitoring/Layout'),
				children: [
					{
						path: '',
						name: 'attendanceMonitoring',
						component: () => import(/* webpackChunkName "Main"*/ './../pages/AttendanceMonitoring/Layout'),
					},
				],
			},
		],
	},
];
