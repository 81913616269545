import { httpGet, httpPost, httpPut, httpDel } from 'boot/axios';

const state = {
	lists: [],
	displayPopUpForm: false
};
const getters = {
	GET_HOLIDAYS(state) {
		return state.lists;
	},
	GET_POPUP_STATUS(state) {
		return state.displayPopUpForm;
	}
};

const mutations = {
	SET_HOLIDAY(state, payload) {
		state.lists = payload;
	},
	SET_POPUP_STATUS(state, payload) {
		state.displayPopUpForm = payload;
	},
	INSERT_HOLIDAY(state, payload) {
		state.lists.unshift(payload);
	},

	MODIFY_HOLIDAY(state, payload) {
		let objectIndex = state.lists.findIndex(e => e.id === payload.id);
		objectIndex !== -1 &&
			Object.keys(state.lists[objectIndex]).forEach(key => {
				state.lists[objectIndex][key] = payload[key];
			});
	},

	REMOVE_HOLIDAY(state, payload) {
		payload.forEach(id => {
			/** Find Array Index on State */
			let objectIndex = state.lists.findIndex(d => d.id === Number(id));

			/** If objectIndex !== -1 means the id was found on state then we remove it manually */
			objectIndex !== -1 && state.lists.splice(objectIndex, 1);
		});
	}
};

const actions = {
	FETCH_HOLIDAY({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/holidays',
				{
					success(response) {
						response.data.status === 'success' && commit('SET_HOLIDAY', response.data.data);
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					}
				},
				payload
			);
		});
	},

	SAVE_HOLIDAY({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPost('juanHR/holidays', payload, {
				success(response) {
					response.data.status === 'success' && commit('INSERT_HOLIDAY', response.data.data);
					resolve(response.data);
				},

				catch(response) {
					console.log(response);
				}
			});
		});
	},

	UPDATE_HOLIDAY({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPut(`juanHR/holidays/${payload.id}`, payload, {
				success(response) {
					response.data.status === 'success' && commit('MODIFY_HOLIDAY', response.data.data);
					resolve(response.data);
				},

				catch(response) {
					console.log(response);
				}
			});
		});
	},

	DELETE_HOLIDAY({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpDel(
				`juanHR/holidays/${payload}`,
				{
					id: payload
				},
				{
					success(response) {
						response.data.status === 'success' && commit('REMOVE_HOLIDAY', payload);
						resolve(response.data);
					},
					catch(response) {}
				}
			);
		});
	}
};

export default { namespaced: true, state, getters, mutations, actions };
