import { httpGet, httpPost, httpDel, httpPut } from 'boot/axios';

const state = {
	configurations: [],
};

const getters = {
	GET_GSIS_CONFIGURATIONS: (state) => {
		return state.configurations;
	},
};

const mutations = {
	SET_GSIS_CONFIGURATIONS(state, payload) {
		state.configurations = payload;
	},

	INSERT_GSIS_CONFIGURATION(state, payload) {
		state.configurations.unshift(payload);
	},

	MODIFY_GSIS_CONFIGURATION(state, payload) {
		let objectIndex = state.configurations.findIndex((b) => b.configuration_id === payload.configuration_id);
		Object.keys(state.configurations[objectIndex]).forEach((key) => {
			state.configurations[objectIndex][key] = payload[key];
		});
	},

	REMOVE_GSIS_CONFIGURATION(state, payload) {
		let objectIndex = state.configurations.findIndex((b) => b.configuration_id === payload.configuration_id);
		objectIndex !== -1 && state.configurations.splice(objectIndex, 1);
	},
};

const actions = {
	FETCH_GSIS_CONFIGURATIONS({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet('juanHR/benefitConfigurationGSIS', {
				success(response) {
					response.data.status === 'success' && commit('SET_GSIS_CONFIGURATIONS', response.data.data);
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				},
			});
		});
	},

	SAVE_GSIS_CONFIGURATION({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPost('juanHR/benefitConfigurationGSIS', payload, {
				success(response) {
					response.data.status === 'success' && commit('INSERT_GSIS_CONFIGURATION', response.data.data);
					resolve(response.data);
				},
				catch(response) {},
			});
		});
	},

	UPDATE_GSIS_CONFIGURATION({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPut(`juanHR/benefitConfigurationGSIS/${payload.configuration_id}`, payload, {
				success(response) {
					response.data.status === 'success' && commit('MODIFY_GSIS_CONFIGURATION', response.data.data);
					resolve(response.data);
				},
				catch(response) {},
			});
		});
	},

	DELETE_GSIS_CONFIGURATION({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpDel(`juanHR/benefitConfigurationGSIS/${payload.configuration_id}`, payload, {
				success(response) {
					/** If response is succss commit to remove branch on vuex */
					response.data.status === 'success' && commit('REMOVE_GSIS_CONFIGURATION', payload);
					/** resolve promise */
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				},
			});
		});
	},
};

export default { namespaced: true, state, getters, mutations, actions };
