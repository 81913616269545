const state = {
	employeeAvatars: {
		female: '',
		male: '',
	},
	photo: {},
};

const getters = {
	GET_EMPLOYEE_AVATARS(state) {
		return state.employeeAvatars;
	},
	GET_PHOTO_BASE64(state) {
		return state.photo;
	},
};

const mutations = {
	SET_EMPLOYEE_AVATARS(state, payload) {
		state.employeeAvatars = payload;
	},
	SET_PHOTO_BASE64(state, payload) {
		state.photo = payload;
	},
};

// const actions = {
// 	// This will check the employee photo updated anywhere in the module
// 	CHECK_EMPLOYEE_PHOTO_UPDATE({ commit, getters }, payload) {
// 		let empIndex = payload.employeeList.findIndex(
// 			(emp) => emp.employee_id === getters.GET_PHOTO_BASE64.employee_id
// 		);
// 		if (empIndex !== -1 && payload.employeeList[empIndex].photo !== getters.GET_PHOTO_BASE64.base64) {
// 			commit(
// 				payload.setter,
// 				{
// 					index: empIndex,
// 					base64: getters.GET_PHOTO_BASE64.base64,
// 				},
// 				{ root: true }
// 			);
// 		}
// 	},
// };

export default { namespaced: true, state, getters, mutations };
