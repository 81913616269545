import { httpGet, httpPost, httpPut, httpDel } from 'boot/axios';

const state = {
	lists: [],
	lazyLists: [],
	searchLists: [],
	deleteEnabled: false,
	displayAllowanceConfigForm: false,
};

const getters = {
	GET_ALLOWANCE: (state) => {
		return state.lists;
	},

	GET_SAVED_SEARCHES_ALLOWANCE: (state) => {
		return state.searchLists;
	},

	GET_ALLOWANCE_CONFIGURATION: (state) => {
		return state.lists;
	},
	GET_ALLOWANCE_CONFIGURATION_LAZY: (state) => {
		return state.lazyLists;
	},

	GET_ALLOWANCE_CONFIG_FORM_STATE: (state) => {
		return state.displayAllowanceConfigForm;
	},
	GET_ALLOWANCE_CONFIG_DELETE_STATE: (state) => {
		return state.deleteEnabled;
	},
};

const mutations = {
	TOGGLE_ALLOWANCE_CONFIG_DELETE(state, payload) {
		state.deleteEnabled = state.deleteEnabled !== true;
	},
	SET_ALLOWANCE_CONFIGURATION(state, payload) {
		state.lists = payload;
	},
	INSERT_ALLOWANCE_CONFIGURATION(state, payload) {
		state.lists.unshift(payload);
		state.lazyLists.unshift(payload);
		state.searchLists.unshift(payload);
	},

	PUSH_SAVED_SEARCHES_ALLOWANCE(state, payload) {
		state.searchLists = state.searchLists.concat(payload);
	},

	SET_ALLOWANCE_CONFIG_FORM_STATE(state, payload) {
		state.displayAllowanceConfigForm = payload;
	},

	JOIN_ALLOWANCE_CONFIG(state, payload) {
		payload.forEach((e) => {
			state.lazyLists.unshift(e);
			state.searchLists.unshift(e);
		});
	},

	MODIFY_ALLOWANCE_CONFIGURATION(state, payload) {
		let objectIndex = state.lazyLists.findIndex((e) => e.allowance_id === payload.allowance_id);
		objectIndex !== -1 &&
			Object.keys(state.lazyLists[objectIndex]).forEach((key) => {
				state.lazyLists[objectIndex][key] = payload[key];
			});
	},

	REMOVE_ALLOWANCE_CONFIGURATION(state, payload) {
		payload.forEach((id) => {
			/** Find Array Index on State */
			let objectIndex = state.lists.findIndex((d) => d.id === Number(id));

			/** If objectIndex !== -1 means the id was found on state then we remove it manually */
			objectIndex !== -1 && state.lists.splice(objectIndex, 1);

			/** Find Array Index on State */
			let objectIndex2 = state.lazyLists.findIndex((d) => d.id === Number(id));

			/** If objectIndex !== -1 means the id was found on state then we remove it manually */
			objectIndex2 !== -1 && state.lazyLists.splice(objectIndex2, 1);
		});
	},

	REMOVE_EMPLOYEE_ALLOWANCE_CONFIGURATION(state, payload) {
		payload.allowance_id.forEach((arrayItem) => {
			// Get allowances bound to Employee ID
			let employeeAllowancesConfig = state.lists.filter((lists) => lists.allowance_id === arrayItem);
			console.log(employeeAllowancesConfig);
			// Iterate found employee allowances
			employeeAllowancesConfig.forEach((employeeAllowancesConfigs) => {
				let objectIndex = state.lists.findIndex((b) => b.id === employeeAllowancesConfigs.id);
				objectIndex !== -1 && state.lists.splice(objectIndex, 1);
			});

			// Get allowances bound to Employee ID
			let employeeAllowancesConfig2 = state.lazyLists.filter((lists) => lists.allowance_id === arrayItem);
			console.log(employeeAllowancesConfig2);
			// Iterate found employee allowances
			employeeAllowancesConfig2.forEach((employeeAllowancesConfigs2) => {
				let objectIndex = state.lazyLists.findIndex(
					(b) => b.allowance_id === employeeAllowancesConfigs2.allowance_id
				);
				objectIndex !== -1 && state.lazyLists.splice(objectIndex, 1);
			});

			// Get allowances bound to Employee ID
			let employeeAllowancesConfig3 = state.searchLists.filter((lists) => lists.allowance_id === arrayItem);
			console.log(employeeAllowancesConfig3);
			// Iterate found employee allowances
			employeeAllowancesConfig3.forEach((employeeAllowancesConfigs3) => {
				let objectIndex = state.searchLists.findIndex(
					(b) => b.allowance_id === employeeAllowancesConfigs3.allowance_id
				);
				objectIndex !== -1 && state.searchLists.splice(objectIndex, 1);
			});
		});
	},
};

const actions = {
	FETCH_ALLOWANCE_CONFIGURATIONS({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/allowanceConfiguration',
				{
					success(response) {
						response.data.status === 'success';
						resolve(response.data);
						console.log(response.data.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},

	FETCH_ALLOWANCE_CONFIGURATIONS_LAZY({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/allowanceConfiguration',
				{
					success(response) {
						response.data.status === 'success' && commit('JOIN_ALLOWANCE_CONFIG', response.data.data);
						resolve(response.data.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},

	SEARCH_ALLOWANCE({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/allowanceConfiguration',
				{
					success(response) {
						response.data.status === 'success' &&
							commit('PUSH_SAVED_SEARCHES_ALLOWANCE', response.data.data);
						resolve(response.data);
					},
					catch(response) {},
				},
				payload
			);
		});
	},

	SAVE_ALLOWANCE_CONFIGURATION: ({ commit }, payload) => {
		return new Promise((resolve, reject) => {
			httpPost('juanHR/allowanceConfiguration', payload, {
				success(response) {
					response.data.status === 'success' && commit('INSERT_ALLOWANCE_CONFIGURATION', response.data.data);
					resolve(response.data);
					console.log(response);
				},
				catch(response) {
					reject(response.data);
					console.log(response);
				},
			});
		});
	},

	UPDATE_ALLOWANCE_CONFIGURATION({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPut(`juanHR/allowanceConfiguration/${payload.allowance_id}`, payload, {
				success(response) {
					/** Commit Data */
					response.data.status === 'success' && commit('MODIFY_ALLOWANCE_CONFIGURATION', response.data.data);
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				},
			});
		});
	},

	DELETE_ALLOWANCE_CONFIGURATION({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpDel(
				`juanHR/allowanceConfiguration/${payload.id}`,
				{
					id: [payload.id],
				},
				{
					success(response) {
						/** If response is succss commit to remove branch on vuex */
						response.data.status === 'success' && commit('REMOVE_ALLOWANCE_CONFIGURATION', payload);
						/** resolve promise */
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				}
			);
		});
	},

	DELETE_EMPLOYEE_ALLOWANCES_CONFIGURATION({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpDel(`juanHR/allowanceConfiguration/${payload.allowance_id}`, payload, {
				success(response) {
					/** If response is succss commit to remove branch on vuex */
					response.data.status === 'success' && commit('REMOVE_EMPLOYEE_ALLOWANCE_CONFIGURATION', payload);
					/** resolve promise */
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				},
			});
		});
	},
	TOGGLE_ALLOWANCE_CONFIG_DELETE({ commit }, payload) {
		commit('TOGGLE_ALLOWANCE_CONFIG_DELETE');
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
