import { httpGet, httpPost, httpPut, httpDel, httpFileDownload } from 'boot/axios';

const state = {
	lists: []
};

const getters = {
  GET_BIOMETRICS_CONFIGURATION: state => {
    return state.lists
  }
};

const mutations = {
	SET_BIOMETRICS_CONFIGURATION(state, payload) {
		state.lists = payload;
	}
};

const actions = {
	FETCH_DEVICE({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/biometricsConfig',
				{
					success(response) {
						response.data.status === 'success' && commit('SET_BIOMETRICS_CONFIGURATION', response.data.data);
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},

	SAVE_DEVICE: ({ commit }, payload) => {
		return new Promise((resolve, reject) => {
			httpPost('juanHR/biometricsConfig', payload, {
				success(response) {
					response.data.status === 'success' && commit('SET_BIOMETRICS_CONFIGURATION', response.data.data);
					resolve(response.data);
				},
				catch(response) {
					reject(response);
					console.log(response);
				},
			});
		});
	},

	UPDATE_DEVICE({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPut(`juanHR/biometricsConfig/${payload.id}`, payload, {
				success(response) {
					/** Commit Data */
					response.data.status === 'success' && commit('SET_BIOMETRICS_CONFIGURATION', response.data.data);
					resolve(response.data);
				},
				catch(response) {
          reject(response);
					console.log(response);
				},
			});
		});
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
