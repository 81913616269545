// import Sample from './Sample/bootstrap'
// START IMPORT BOOTSTRAP
import JuanHR from './JuanHR/bootstrap';

const routes = [
...JuanHR.routes,
  // ...Sample.routes
]

const store = {
...JuanHR.store,
  // ...Sample.store
}

export default { routes, store }



