import { httpGet, httpPost, httpDel, httpPut, httpFileUpload } from 'boot/axios';

const state = {
	memosAndNotices: [],
	searchResults: [],
	suggestResults: [],
	deleteEnabled: false,
};

const getters = {
	GET_MEMOS_AND_NOTICES: (state) => {
		return state.memosAndNotices;
	},

	GET_FILTERED_MEMOS_AND_NOTICES: (state) => {
		return state.searchResults;
	},

	GET_SUGGEST_MEMOS_AND_NOTICES: (state) => {
		return state.suggestResults;
	},

	GET_MEMOS_AND_NOTICES_DELETE_STATE: (state) => {
		return state.deleteEnabled;
	},
};

const mutations = {
	TOGGLE_MEMOS_AND_NOTICES_DELETE(state, payload) {
		state.deleteEnabled = state.deleteEnabled !== true;
	},

	SET_MEMOS_AND_NOTICES(state, payload) {
		state.memosAndNotices = payload;
	},

	JOIN_MEMOS_AND_NOTICES(state, payload) {
		payload.forEach((memo) => {
			state.memosAndNotices.push(memo);
		});
	},

	SET_FILTERED_MEMOS_AND_NOTICES(state, payload) {
		state.searchResults = payload;
	},

	SET_SUGGEST_MEMOS_AND_NOTICES(state, payload) {
		state.suggestResults = payload;
	},

	INSERT_MEMO_AND_NOTICE(state, payload) {
		state.memosAndNotices.unshift(payload);
	},

	INSERT_MEMO_AND_NOTICE_FILES(state, payload) {
		let memoNoticeIndex = state.memosAndNotices.findIndex((m) => m.id === payload.memo_id);

		console.log(payload);
		console.log(state.memosAndNotices[memoNoticeIndex]);
		state.memosAndNotices[memoNoticeIndex].files.push({
			attachment_id: payload.file_object.attachment_id,
			file: payload.file_object.file,
			uploadStarted: false,
			isUploaded: true,
			progress: 0,
			cancelToken: null,
		});
	},

	MODIFY_MEMO_AND_NOTICE(state, payload) {
		let objectIndex = state.memosAndNotices.findIndex((b) => b.id === payload.id);
		Object.keys(state.memosAndNotices[objectIndex]).forEach((key) => {
			// Ignore files key, the payload doesn't include files in it
			if (key !== 'files') {
				state.memosAndNotices[objectIndex][key] = payload[key];
			}
		});
	},

	REMOVE_MEMOS_AND_NOTICES(state, payload) {
		payload.id.forEach((arrayItem) => {
			let objectIndex = state.memosAndNotices.findIndex((b) => b.id === arrayItem);
			objectIndex !== -1 && state.memosAndNotices.splice(objectIndex, 1);

			objectIndex = state.searchResults.findIndex((b) => b.id === arrayItem);
			objectIndex !== -1 && state.searchResults.splice(objectIndex, 1);
		});
	},

	REMOVE_MEMO_AND_NOTICE_FILES(state, payload) {
		let memoNoticeIndex = state.memosAndNotices.findIndex((m) => m.id === parseInt(payload.memo_id));
		payload.attachment_id.forEach((attachment_id) => {
			let objectIndex = state.memosAndNotices[memoNoticeIndex].files.findIndex(
				(f) => f.attachment_id === attachment_id
			);
			objectIndex !== -1 && state.memosAndNotices[memoNoticeIndex].files.splice(objectIndex, 1);
		});
	},

	EMPTY_FILTERED_MEMOS_AND_NOTICES(state, payload) {
		state.searchResults = [];
	},

	EMPTY_SUGGEST_MEMOS_AND_NOTICES(state, payload) {
		state.suggestResults = [];
	},
};

const actions = {
	TOGGLE_MEMOS_AND_NOTICES_DELETE({ commit }, payload) {
		commit('TOGGLE_MEMOS_AND_NOTICES_DELETE');
	},

	FETCH_MEMOS_AND_NOTICES({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet('juanHR/memoAndNotice', {
				success(response) {
					response.data.status === 'success' && commit('SET_MEMOS_AND_NOTICES', response.data.data);
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				},
			});
		});
	},

	FETCH_MEMOS_AND_NOTICES_LAZY({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/memoAndNotice',
				{
					success(response) {
						response.data.status === 'success' && commit('JOIN_MEMOS_AND_NOTICES', response.data.data);
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},

	FETCH_FILTERED_MEMOS_AND_NOTICES({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/memoAndNotice',
				{
					success(response) {
						response.data.status === 'success' &&
							commit('SET_FILTERED_MEMOS_AND_NOTICES', response.data.data);
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},

	FETCH_SUGGEST_MEMOS_AND_NOTICES({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/memoAndNotice',
				{
					success(response) {
						response.data.status === 'success' &&
							commit('SET_SUGGEST_MEMOS_AND_NOTICES', response.data.data);
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},

	FETCH_FILTERED_MEMOS_AND_NOTICES_FOR_DOWNLOAD({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/memoAndNotice',
				{
					success(response) {
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},

	SAVE_MEMO_AND_NOTICE({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPost('juanHR/memoAndNotice', payload, {
				success(response) {
					response.data.status === 'success' && commit('INSERT_MEMO_AND_NOTICE', response.data.data);
					resolve(response.data);
				},
				catch(response) {},
			});
		});
	},

	UPLOAD_FILE({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpFileUpload(`juanHR/memoAndNotice/${payload.id}`, payload, {
				success(response) {
					resolve(response.data);
				},
				catch(response) {
					reject(response.data);
				},
			});
		});
	},

	UPDATE_MEMO_AND_NOTICE({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPut(`juanHR/memoAndNotice/${payload.id}`, payload, {
				success(response) {
					response.data.status === 'success' && commit('MODIFY_MEMO_AND_NOTICE', response.data.data);
					resolve(response.data);
				},
				catch(response) {},
			});
		});
	},

	DELETE_MEMOS_AND_NOTICES({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpDel(`juanHR/memoAndNotice/${payload.id}`, payload, {
				success(response) {
					/** If response is succss commit to remove branch on vuex */
					response.data.status === 'success' && commit('REMOVE_MEMOS_AND_NOTICES', payload);
					/** resolve promise */
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				},
			});
		});
	},

	DELETE_MEMO_AND_NOTICE_FILES({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpDel(
				`juanHR/memoAndNotice/${payload.attachment_id}`,
				{
					attachment_id: payload.attachment_id,
				},
				{
					success(response) {
						/** If response is succss commit to remove branch on vuex */
						response.data.status === 'success' && commit('REMOVE_MEMO_AND_NOTICE_FILES', payload);
						/** resolve promise */
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				}
			);
		});
	},
};

export default { namespaced: true, state, getters, mutations, actions };
