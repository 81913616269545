const state = {
	sidebarPropertiy: {},
};

const getters = {
	GET_SIDEBAR_PROPERTIES(state) {
		return state.sidebarPropertiy;
	},
};

const mutations = {
	SET_SIDEBAR_PROPERTIES(state, payload) {
		state.sidebarPropertiy[payload.property] = payload.value;
	}
};

const actions = {};

export default { namespaced: true, state, getters, mutations, actions };
