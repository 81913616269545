import { httpGet, httpPut, httpPost, httpDel } from 'boot/axios';
// import { date } from 'quasar';
// let timeStamp = Date.now();
// let formattedString = date.formatDate(timeStamp, 'YYYY-MM-DD');
import moment from 'moment';
let formattedString = moment(Date.now()).format('YYYY-MM-DD');

const state = {
	lazyLists: [],
	dtr_bulk_entries: [],
	triggers: {
		displayPopupForm: false,
		displayDtrGenerationPopupForm: false,
		displayDtrEvaluationPopupForm: false,
		displayDtrBulkEntriesPopupForm: false,
		displayBulkDelete: false,
	},
	date_filter: {
		start_date_filter: formattedString,
		end_date_filter: formattedString,
	},
	employeeLazyLists: [],
	selectedTrashEmployee: [],
	activeEmployee: {},

	searchResults: [],
	suggestionResults: [],
};

const getters = {
	GET_BULK_ENTRIES: (state) => {
		return state.dtr_bulk_entries;
	},
	GET_DTR_LAZY(state) {
		return state.lazyLists;
	},

	GET_EMPLOYEE_DTR_LAZY(state) {
		return state.employeeLazyLists;
	},

	GET_GLOBAL_TRIGGERS(state) {
		return state.triggers;
	},

	GET_DATE_FILTERS(state) {
		return state.date_filter;
	},

	GET_SELECTED_TRASH() {
		return state.selectedTrashEmployee;
	},

	GET_ACTIVE_EMPLOYEE(state) {
		return state.activeEmployee;
	},

	GET_SEARCH_RESULTS(state) {
		return state.searchResults;
	},
	GET_SEUGGEST_RESULTS(state) {
		return state.suggestionResults;
	},
};

const mutations = {
	SET_DTR(state, payload) {},

	SET_SELECTED_TRASH(state, payload) {
		state.selectedTrashEmployee = payload;
	},

	JOIN_DTR(state, payload) {
		payload.forEach((e) => {
			state.lazyLists.push(e);
		});
	},

	SET_LAZY_LISTS(state, payload) {
		state.lazyLists = payload;
	},

	INSERT_DTR_BULK_ENTRIES(state, payload) {
		payload.forEach((dtr_bulk_entry) => {
			state.dtr_bulk_entries.unshift(dtr_bulk_entry);
		});
	},

	JOIN_EMPLOYEE_DTR(state, payload) {
		payload.forEach((e) => {
			state.employeeLazyLists.push(e);
		});
	},

	SET_EMPLOYEE_DTR_LAZY(state, payload) {
		state.employeeLazyLists = payload;
	},

	SET_GLOBAL_TRIGGERS(state, payload) {
		state.triggers[payload.key] = payload.value;
	},

	SET_DATE_FILTERS(state, payload) {
		state.date_filter[payload.key] = payload.value;
	},

	SET_SEARCH_RESULTS(state, payload) {
		state.searchResults = payload;
	},
	SET_SUGGEST_RESULTS(state, payload) {
		state.suggestionResults = payload;
	},

	REMOVE_EMPLOYEE_DTR_LAZY(state, payload) {
		payload.id.forEach((id) => {
			/** Find Array Index on State */
			let objectIndex = state.employeeLazyLists.findIndex((d) => d.id === Number(id));
			/** If objectIndex !== -1 means the id was found on state then we remove it manually */
			objectIndex !== -1 && state.employeeLazyLists.splice(objectIndex, 1);
		});
	},

	MODIFY_EMPLOYEE_DTR_LAZY(state, payload) {
		payload.forEach((dtr) => {
			/** Update Time in/out*/
			let objectIndex = state.employeeLazyLists.findIndex((d) => d.id === dtr.id);
			objectIndex !== -1 && (state.employeeLazyLists[objectIndex].time = dtr.time);
		});
	},

	REMOVE_EMPLOYEE_DTR(state, payload) {
		payload.id[0].forEach((id) => {
			/** Find Array Index on State */
			let objectIndex = state.lazyLists.findIndex((d) => d.employee_id === Number(id));
			/** If objectIndex !== -1 means the id was found on state then we remove it manually */
			objectIndex !== -1 && state.lazyLists.splice(objectIndex, 1);
			/** Find Array Index on State */
			objectIndex = state.searchResults.findIndex((d) => d.employee_id === Number(id));
			/** If objectIndex !== -1 means the id was found on state then we remove it manually */
			objectIndex !== -1 && state.searchResults.splice(objectIndex, 1);
		});
	},

	SET_ACTIVE_EMPLOYEE(state, payload) {
		state.activeEmployee = payload;
	},
};

const actions = {
	// FETCH_DTR({ commit }, payload) {
	// 	return new Promise((resolve, reject) => {
	// 		httpGet(
	// 			'juanHR/dtr',
	// 			{
	// 				success(response) {
	// 					resolve(response.data);
	// 				},
	// 				catch() {}
	// 			},
	// 			payload
	// 		);
	// 	});
	// },

	SAVE_DTR_BULK_ENTRIES({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPost('juanHR/dtr', payload, {
				success(response) {
					console.log(response);
					response.data.status === 'success' && commit('INSERT_DTR_BULK_ENTRIES', response.data.data);
					resolve(response.data);
				},
				catch(response) {},
			});
		});
	},

	FETCH_EMPLOYEE_LAZY({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/dtr',
				{
					success(response) {
						response.data.status === 'success' && commit('JOIN_DTR', response.data.data);
						resolve(response.data);
					},
					catch() {},
				},
				payload
			);
		});
	},

	SEARCH_EMPLOYEE_LAZY({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/dtr',
				{
					success(response) {
						response.data.status === 'success' && commit('SET_SEARCH_RESULTS', response.data.data);
						resolve(response.data);
					},
					catch() {},
				},
				payload
			);
		});
	},

	FIND_EMPLOYEE_LAZY({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/dtr',
				{
					success(response) {
						response.data.status === 'success' && commit('SET_SUGGEST_RESULTS', response.data.data);
						resolve(response.data);
					},
					catch() {},
				},
				payload
			);
		});
	},

	FETCH_EMPLOYEE_DTR_LAZY({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/dtr',
				{
					success(response) {
						response.data.status === 'success' && commit('JOIN_EMPLOYEE_DTR', response.data.data);
						resolve(response.data);
					},
					catch() {},
				},
				payload
			);
		});
	},

	DELETE_EMPLOYEE_DTR_LAZY({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpDel(`juanHR/dtr/${payload.id}`, payload, {
				success(response) {
					if (response.data.status === 'success')
						if (payload.range) {
							commit('REMOVE_EMPLOYEE_DTR', payload);
						} else {
							commit('REMOVE_EMPLOYEE_DTR_LAZY', payload);
						}
					resolve(response.data);
				},
				catch(response) {},
			});
		});
	},

	BULK_UPLOAD_MISSING_LOGS({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPost('juanHR/dtr', payload, {
				success(response) {
					/** Commit mutation will be depends on what route use is in */
					// response.data.status === 'success' && commit('')
					resolve(response.data);
				},

				catch(response) {},
			});
		});
	},

	SAVE_EMPLOYEE_DTR({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPost('juanHR/dtr', payload, {
				success(response) {
					/** Commit mutation will be depends on what route use is in */
					resolve(response.data);
				},

				catch(response) {},
			});
		});
	},

	GENERATE_DTR({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPost('juanHR/dtr', payload, {
				success(response) {
					console.log(response);
					/**Commit mutation will be depends on what route use is in */
					resolve(response.data);
				},

				catch(response) {},
			});
		});
	},

	UPDATE_EMPLOYEE_DTR({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPut(`juanHR/dtr/${payload.employee_id}`, payload.data, {
				success(response) {
					response.data.status === 'success' && commit('MODIFY_EMPLOYEE_DTR_LAZY', response.data.data);
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				},
			});
		});
	},

	EVALUATE_DTR({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPost('juanHR/dtrEvaluation', payload, {
				success(response) {
					resolve(response.data);
				},
				catch(response) {
					reject(response.data);
				},
			});
		});
	},
};

export default { namespaced: true, state, getters, mutations, actions };
