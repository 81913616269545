import { httpGet, httpPost, httpPut, httpDel } from 'boot/axios';

const state = {
	lists: [],
	lazyLists: [],
	searchLists: [],
	deleteEnabled: false,
	displayLoanConfigForm: false,
};

const getters = {
	GET_LOANS: (state) => {
		return state.lists;
	},
	GET_SAVED_SEARCHES_LOANS: (state) => {
		return state.searchLists;
	},
	GET_LOAN_CONFIGURATION: (state) => {
		return state.lists;
	},
	GET_LOAN_CONFIGURATION_LAZY: (state) => {
		return state.lazyLists;
	},

	GET_LOAN_CONFIG_FORM_STATE: (state) => {
		return state.displayLoanConfigForm;
	},
	GET_LOAN_CONFIG_DELETE_STATE: (state) => {
		return state.deleteEnabled;
	},
};

const mutations = {
	TOGGLE_LOAN_CONFIG_DELETE(state, payload) {
		state.deleteEnabled = state.deleteEnabled !== true;
	},
	SET_LOAN_CONFIGURATION(state, payload) {
		state.lists = payload;
	},
	INSERT_LOAN_CONFIGURATION(state, payload) {
		state.lists.unshift(payload);
		state.lazyLists.unshift(payload);
	},

	PUSH_SAVED_SEARCHES_LOANS(state, payload) {
		state.searchLists = state.searchLists.concat(payload);
	},

	SET_LOAN_CONFIG_FORM_STATE(state, payload) {
		state.displayLoanConfigForm = payload;
	},

	JOIN_LOAN_CONFIG(state, payload) {
		payload.forEach((e) => {
			state.lazyLists.unshift(e);
		});
	},

	MODIFY_LOAN_CONFIGURATION(state, payload) {
		let objectIndex = state.lists.findIndex((e) => e.id === payload.id);
		objectIndex !== -1 &&
			Object.keys(state.lists[objectIndex]).forEach((key) => {
				state.lists[objectIndex][key] = payload[key];
			});

		console.log(state.lists, 'this is state lists');

		console.log(payload, 'this is payload');

		console.log(state.lazyLists, 'this is lazy list before update');

		// let objectIndex2 = state.lazyLists.findIndex(e => e.id === payload.id);
		// objectIndex2 !== -1 &&
		// 	Object.keys(state.lazyLists[objectIndex2]).forEach(key => {
		// 		state.lazyLists[objectIndex2][key] = payload[key];
		// 	});
	},

	REMOVE_LOAN_CONFIGURATION(state, payload) {
		payload.forEach((id) => {
			/** Find Array Index on State */
			let objectIndex = state.lists.findIndex((d) => d.id === Number(id));

			/** If objectIndex !== -1 means the id was found on state then we remove it manually */
			objectIndex !== -1 && state.lists.splice(objectIndex, 1);

			/** Find Array Index on State */
			let objectIndex2 = state.lazyLists.findIndex((d) => d.id === Number(id));

			/** If objectIndex !== -1 means the id was found on state then we remove it manually */
			objectIndex2 !== -1 && state.lazyLists.splice(objectIndex2, 1);
		});
	},

	REMOVE_EMPLOYEE_LOANS_CONFIGURATION(state, payload) {
		payload.loan_id.forEach((arrayItem) => {
			// Get allowances bound to Employee ID
			let employeeLoansConfig = state.lists.filter((lists) => lists.loan_id === arrayItem);
			console.log(employeeLoansConfig);
			// Iterate found employee allowances
			employeeLoansConfig.forEach((employeeLoansConfigs) => {
				let objectIndex = state.lists.findIndex((b) => b.id === employeeLoansConfigs.id);
				objectIndex !== -1 && state.lists.splice(objectIndex, 1);
			});

			// Get allowances bound to Employee ID
			let employeeLoansConfig2 = state.lazyLists.filter((lists) => lists.loan_id === arrayItem);
			console.log(employeeLoansConfig2);
			// Iterate found employee allowances
			employeeLoansConfig2.forEach((employeeLoansConfigs2) => {
				let objectIndex = state.lazyLists.findIndex((b) => b.id === employeeLoansConfigs2.id);
				objectIndex !== -1 && state.lazyLists.splice(objectIndex, 1);
			});
		});
	},
};

const actions = {
	FETCH_LOAN_CONFIGURATIONS({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/loanConfiguration',
				{
					success(response) {
						response.data.status === 'success' && commit('SET_LOAN_CONFIGURATION', response.data.data);
						resolve(response.data);
						console.log(response);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},
	FETCH_LOAN_CONFIGURATION_SOLO({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/loanConfiguration',
				{
					success(response) {
						resolve(response.data);
						console.log(response);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},

	FETCH_LOAN_CONFIGURATION_LAZY({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/loanConfiguration',
				{
					success(response) {
						response.data.status === 'success' && commit('JOIN_LOAN_CONFIG', response.data.data);
						resolve(response.data.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},

	SEARCH_LOAN({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/loanConfiguration',
				{
					success(response) {
						response.data.status === 'success' && commit('PUSH_SAVED_SEARCHES_LOANS', response.data.data);
						resolve(response.data);
					},
					catch(response) {},
				},
				payload
			);
		});
	},

	SAVE_LOAN_CONFIGURATION: ({ commit }, payload) => {
		return new Promise((resolve, reject) => {
			httpPost('juanHR/loanConfiguration', payload, {
				success(response) {
					response.data.status === 'success' && commit('INSERT_LOAN_CONFIGURATION', response.data.data);
					resolve(response.data);
					console.log(response);
				},
				catch(response) {
					reject(response.data);
					console.log(response);
				},
			});
		});
	},

	UPDATE_LOAN_CONFIGURATION({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPut(`juanHR/loanConfiguration/${payload.loan_id}`, payload, {
				success(response) {
					/** Commit Data */
					response.data.status === 'success' && commit('MODIFY_LOAN_CONFIGURATION', response.data.data);
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				},
			});
		});
	},

	DELETE_LOAN_CONFIGURATION({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpDel(
				`juanHR/loanConfiguration/${payload.id}`,
				{
					id: [payload.id],
				},
				{
					success(response) {
						/** If response is succss commit to remove branch on vuex */
						response.data.status === 'success' && commit('REMOVE_LOAN_CONFIGURATION', payload);
						/** resolve promise */
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				}
			);
		});
	},

	DELETE_EMPLOYEE_LOANS_CONFIGURATION({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpDel(`juanHR/loanConfiguration/${payload.loan_id}`, payload, {
				success(response) {
					/** If response is succss commit to remove branch on vuex */
					response.data.status === 'success' && commit('REMOVE_EMPLOYEE_LOANS_CONFIGURATION', payload);
					/** resolve promise */
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				},
			});
		});
	},
	TOGGLE_LOAN_CONFIG_DELETE({ commit }, payload) {
		commit('TOGGLE_LOAN_CONFIG_DELETE');
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
