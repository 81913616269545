import { httpGet, httpPost, httpDel, httpPut } from 'boot/axios';

const state = {
	schedules: [],
	lazyEmployees: [],
	employeeSchedules: [],
	searchResults: [],
	deleteEnabled: false,
};

const getters = {
	GET_SCHEDULES: (state) => {
		return state.schedules;
	},

	GET_SCHEDULES_EMPLOYEES_LAZY: (state) => {
		return state.lazyEmployees;
	},

	GET_EMPLOYEE_SCHEDULES: (state) => {
		return state.employeeSchedules;
	},

	GET_SCHEDULE_DELETE_STATE: (state) => {
		return state.deleteEnabled;
	},

	GET_FILTERED_SCHEDULES: (state) => {
		return state.searchResults;
	},
};

const mutations = {
	TOGGLE_SCHEDULE_DELETE(state, payload) {
		state.deleteEnabled = state.deleteEnabled !== true;
	},

	SET_EMPLOYEE_SCHEDULES(state, payload) {
		state.employeeSchedules = payload;
	},

	SET_SCHEDULES(state, payload) {
		state.schedules = payload;
	},

	JOIN_SCHEDULES_EMPLOYEES(state, payload) {
		payload.forEach((e) => {
			let objectIndex = state.lazyEmployees.findIndex((b) => b.employee_id === e.employee_id);
			objectIndex === -1 && state.lazyEmployees.push(e);
		});

		console.log(state.lazyEmployees, 'this is lazy state');
	},

	SET_FILTERED_SCHEDULES(state, payload) {
		state.searchResults = payload;
	},

	INSERT_SCHEDULES(state, payload) {
		state.schedules.unshift(payload);
	},

	MODIFY_SCHEDULE(state, payload) {
		let objectIndex = state.schedules.findIndex((b) => b.id === payload.id);
		Object.keys(state.schedules[objectIndex]).forEach((key) => {
			state.schedules[objectIndex][key] = payload[key];
		});
	},

	MODIFY_EMPLOYEE_SCHEDULE(state, payload) {
		let objectIndex = state.employeeSchedules.findIndex((b) => b.id === payload.id);
		Object.keys(state.employeeSchedules[objectIndex]).forEach((key) => {
			state.employeeSchedules[objectIndex][key] = payload[key];
		});
	},

	REMOVE_SCHEDULES(state, payload) {
		payload.id.forEach((id) => {
			let objectIndex = state.employeeSchedules.findIndex((b) => b.id === id);
			objectIndex !== -1 && state.employeeSchedules.splice(objectIndex, 1);

			let objectIndexs = state.lazyEmployees.findIndex((b) => b.id === id);
			objectIndexs !== -1 && state.lazyEmployees.splice(objectIndexs, 1);
		});
	},

	REMOVE_SCHEDULES_EMPLOYEE(state, payload) {
		payload.forEach((arrayItem) => {
			let objectIndex = state.lazyEmployees.findIndex((b) => b.employee_id === arrayItem);
			objectIndex !== -1 && state.lazyEmployees.splice(objectIndex, 1);
		});
	},

	REMOVE_EMPLOYEE_SCHEDULES(state, payload) {
		payload.employee_id.forEach((arrayItem) => {
			// Get schedules bound to Employee ID
			let employeeSchedules = state.lazyEmployees.filter((schedule) => schedule.employee_id === arrayItem);
			console.log(employeeSchedules);

			// Iterate found employee schedules
			employeeSchedules.forEach((emp_schedule) => {
				let objectIndex = state.lazyEmployees.findIndex((b) => b.id === emp_schedule.id);
				objectIndex !== -1 && state.lazyEmployees.splice(objectIndex, 1);

				objectIndex = state.searchResults.findIndex((b) => b.id === emp_schedule.id);
				objectIndex !== -1 && state.searchResults.splice(objectIndex, 1);
			});
		});
	},
};

const actions = {
	TOGGLE_SCHEDULE_DELETE({ commit }, payload) {
		commit('TOGGLE_SCHEDULE_DELETE');
	},

	FETCH_EMPLOYEE_SCHEDULES({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/schedule',
				{
					success(response) {
						response.data.status === 'success' && commit('SET_EMPLOYEE_SCHEDULES', response.data.data);
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},

	FETCH_SCHEDULES({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet('juanHR/schedule', {
				success(response) {
					response.data.status === 'success' && commit('SET_SCHEDULES', response.data.data);
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				},
			});
		});
	},

	FETCH_SCHEDULES_LAZY({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/schedule',
				{
					success(response) {
						console.log(response.data, 'this is response from fetch lazy');
						response.data.status === 'success' && commit('JOIN_SCHEDULES_EMPLOYEES', response.data.data);
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},

	FETCH_FILTERED_SCHEDULES({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/schedule',
				{
					success(response) {
						response.data.status === 'success' && commit('SET_FILTERED_SCHEDULES', response.data.data);
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},

	FETCH_FILTERED_SCHEDULES_FOR_DOWNLOAD({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/schedule',
				{
					success(response) {
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},

	SAVE_SCHEDULES({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPost('juanHR/schedule', payload, {
				success(response) {
					response.data.status === 'success' && commit('INSERT_SCHEDULES', response.data.data);
					resolve(response.data);
				},
				catch(response) {},
			});
		});
	},

	UPDATE_SCHEDULE({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPut(`juanHR/schedule/${payload.id}`, payload, {
				success(response) {
					response.data.status === 'success' && commit('MODIFY_EMPLOYEE_SCHEDULE', response.data.data);
					resolve(response.data);
				},
				catch(response) {},
			});
		});
	},

	UPDATE_SCHEDULES({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPut(`juanHR/schedule/${payload.id}`, payload, {
				success(response) {
					response.data.status === 'success' && commit('MODIFY_SCHEDULE', response.data.data);
					resolve(response.data);
				},
				catch(response) {},
			});
		});
	},

	DELETE_SCHEDULES({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpDel(`juanHR/schedule/${payload.id}`, payload, {
				success(response) {
					console.log(response, ' this is response on delete');
					/** If response is succss commit to remove branch on vuex */
					response.data.status === 'success' && commit('REMOVE_SCHEDULES', payload);
					/** resolve promise */
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				},
			});
		});
	},

	DELETE_EMPLOYEE_SCHEDULES({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpDel(`juanHR/schedule/${payload.employee_id}`, payload, {
				success(response) {
					/** If response is succss commit to remove branch on vuex */
					response.data.status === 'success' && commit('REMOVE_EMPLOYEE_SCHEDULES', payload);
					/** resolve promise */
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				},
			});
		});
	},
};

export default { namespaced: true, state, getters, mutations, actions };
