import { httpGet, httpPost, httpPut, httpDel, httpFileDownload } from 'boot/axios';

const state = {
	listOnline: [],
	lists: [],
	webSocketServerData: {
		websocketConnected: false,
		websocketServer: null,
	},
	lazyListsGroup: [],
	resetDeviceLazyLoad: false,
	deleteEnabled: false,
	displayDeviceForm: false,
};

const getters = {
	GET_WEBSOCKET_DATA: (state) => {
		return state.webSocketServerData;
	},
	GET_DEVICE: (state) => {
		return state.lists;
	},
	GET_DEVICE_LAZY_GROUP(state) {
		return state.lazyListsGroup;
	},
	GET_ONLINE_DEVICES: (state) => {
		return state.listOnline;
	},
	GET_RESET_DEVICE_LAZY_LOAD(state) {
		return state.resetBiometricLazyLoad;
	},
	GET_DEVICE_FORM_STATE: (state) => {
		return state.displayDeviceForm;
	},
	GET_DEVICE_DELETE_STATE: (state) => {
		return state.deleteEnabled;
	},
};

const mutations = {
	TOGGLE_DEVICE_DELETE(state, payload) {
		state.deleteEnabled = state.deleteEnabled !== true;
	},
	SET_DEVICE(state, payload) {
		state.lists = payload;
	},
	SET_WEBSOCKET_DATA(state, payload) {
		state.webSocketServerData = payload;
	},
	SET_ONLINE_DEVICE(state, deviceSerialID) {
		state.listOnline.push(deviceSerialID);
		if (state.lazyListsGroup.length > 0) {
			let index = state.lazyListsGroup.findIndex((device) => device.device_serial_id === deviceSerialID);
			if (index !== -1) {
				state.lazyListsGroup[index].isConnected = true;
			}
		}
	},
	INSERT_DEVICE(state, payload) {
		let exist = state.lists.filter((ba) => ba.device_id === Number(payload.device_id));
		!exist.length && state.lists.unshift(payload);
		!exist.length && state.lazyListsGroup.unshift(payload);
	},

	SET_DEVICE_FORM_STATE(state, payload) {
		state.displayDeviceForm = payload;
	},

	MODIFY_DEVICE(state, payload) {
		let objectIndex = state.lists.findIndex((e) => e.id === payload.id);
		objectIndex !== -1 &&
			Object.keys(state.lists[objectIndex]).forEach((key) => {
				state.lists[objectIndex][key] = payload[key];
			});
	},

	JOIN_DEVICE_GROUP(state, payload) {
		payload.forEach((e) => {
			let exist = state.lazyListsGroup.filter((i) => i.device_id === Number(e.device_id));
			if (!exist.length) {
				e.isConnected = false;
				state.lazyListsGroup.push(e);
			}
		});
	},

	REMOVE_DEVICE(state, payload) {
		let objectIndex = state.lists.findIndex((e) => e.id === payload.id);
		objectIndex !== -1 && state.lists.splice(objectIndex, 1);
	},

	REMOVE_EMPLOYEE_DEVICE(state, payload) {
		payload.device_id.forEach((arrayItem) => {
			let objectIndexs = state.lazyListsGroup.findIndex((b) => b.device_id === arrayItem);
			objectIndexs !== -1 && state.lazyListsGroup.splice(objectIndexs, 1);

			// Get device bound to Employee ID
			let employeeDevice = state.lists.filter((lists) => lists.device_id === arrayItem);
			console.log(employeeDevice);
			// Iterate found employee device
			employeeDevice.forEach((employeeDevices) => {
				let objectIndex = state.lists.findIndex((b) => b.id === employeeDevices.id);
				objectIndex !== -1 && state.lists.splice(objectIndex, 1);
			});
		});
	},
};

const actions = {
	FETCH_DEVICE({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/device',
				{
					success(response) {
						response.data.status === 'success' && commit('SET_DEVICE', response.data.data);
						resolve(response.data);
						console.log(response);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},

	FETCH_DEVICE_LOGS({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/device',
				{
					success(response) {
						resolve(response.data);
					},
					catch(response) {
						reject(response);
					},
				},
				payload
			);
		});
	},

	FETCH_DEVICE_LAZY_GROUP({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/device',
				{
					success(response) {
						response.data.status === 'success' && commit('JOIN_DEVICE_GROUP', response.data.data);
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},

	SAVE_DEVICE: ({ commit }, payload) => {
		console.log(payload);
		return new Promise((resolve, reject) => {
			httpPost('juanHR/device', payload.form, {
				success(response) {
					let tosave = response.data.data || {};
					tosave.dept_name = payload.others.department_name;
					response.data.status === 'success' && commit('INSERT_DEVICE', tosave);
					resolve(response.data);
					console.log(response);
				},
				catch(response) {
					reject(response);
					console.log(response);
				},
			});
		});
	},

	UPDATE_DEVICE({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPut(`juanHR/device/${payload.device_id}`, payload, {
				success(response) {
					/** Commit Data */
					response.data.status === 'success' && commit('MODIFY_DEVICE', response.data.data);
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				},
			});
		});
	},

	DELETE_DEVICE({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpDel(
				`juanHR/device/${payload.id}`,
				{
					id: [payload.id],
				},
				{
					success(response) {
						/** If response is succss commit to remove branch on vuex */
						response.data.status === 'success' && commit('REMOVE_DEVICE', payload);
						/** resolve promise */
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				}
			);
		});
	},

	DELETE_EMPLOYEE_DEVICE({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpDel(`juanHR/device/${payload.device_id}`, payload, {
				success(response) {
					/** If response is succss commit to remove branch on vuex */
					response.data.status === 'success' && commit('REMOVE_EMPLOYEE_DEVICE', payload);
					/** resolve promise */
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				},
			});
		});
	},
	TOGGLE_DEVICE_DELETE({ commit }, payload) {
		commit('TOGGLE_DEVICE_DELETE');
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
