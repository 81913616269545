import { httpGet, httpPost, httpDel, httpPut } from 'boot/axios';

const state = {
	adjustments: [],
	employeeAdjustments: [],
	lazyEmployees: [],
	searchResults: [],
	suggestResults: [],
	deleteEnabled: false,
	addFormVisible: false,
};

const getters = {
	GET_ADJUSTMENT_DELETE_STATE: (state) => {
		return state.deleteEnabled;
	},

	GET_ADJUSTMENT_ADD_FORM_DISPLAY_STATE: (state) => {
		return state.addFormVisible;
	},

	GET_ADJUSTMENTS: (state) => {
		return state.adjustments;
	},

	GET_EMPLOYEE_ADJUSTMENTS: (state) => {
		return state.employeeAdjustments;
	},

	GET_ADJUSTMENT_EMPLOYEES_LAZY: (state) => {
		return state.lazyEmployees;
	},

	GET_FILTERED_ADJUSTMENTS: (state) => {
		return state.searchResults;
	},

	GET_SUGGEST_ADJUSTMENTS: (state) => {
		return state.suggestResults;
	},
};

const mutations = {
	TOGGLE_ADJUSTMENT_DELETE(state, payload) {
		state.deleteEnabled = state.deleteEnabled !== true;
	},

	SHOW_ADD_FORM(state, payload) {
		state.addFormVisible = true;
	},

	HIDE_ADD_FORM(state, payload) {
		state.addFormVisible = false;
	},

	GET_EMPLOYEE_ADJUSTMENTS(state, payload) {
		state.employeeAdjustments = state.adjustments.filter((s) => s.employee_id === payload);
	},

	SET_ADJUSTMENTS(state, payload) {
		state.adjustments = payload;
	},

	JOIN_ADJUSTMENTS_EMPLOYEES(state, payload) {
		payload.forEach((e) => {
			let objectIndex = state.lazyEmployees.findIndex((b) => b.employee_id === e.employee_id);
			objectIndex === -1 && state.lazyEmployees.push(e);
		});
	},

	SET_FILTERED_ADJUSTMENT(state, payload) {
		state.searchResults = payload;
	},

	SET_SUGGEST_ADJUSTMENT(state, payload) {
		state.suggestResults = payload;
	},

	INSERT_ADJUSTMENT(state, payload) {
		state.adjustments.unshift(payload);
		let employeeIndex = state.lazyEmployees.findIndex((e) => e.employee_id === payload.employee_id);
		employeeIndex === -1 && state.lazyEmployees.unshift(payload);
	},

	INSERT_ADJUSTMENTS(state, payload) {
		payload.inserted.forEach((adjustment) => {
			let employeeIndex1 = state.adjustments.findIndex((e) => e.employee_id === adjustment.employee_id);
			employeeIndex1 === -1 && state.adjustments.unshift(adjustment);

			let employeeIndex = state.lazyEmployees.findIndex((e) => e.employee_id === adjustment.employee_id);
			employeeIndex === -1 && state.lazyEmployees.unshift(adjustment);

			// state.adjustments.unshift(adjustment);
			// state.lazyEmployees.unshift(adjustment);
		});
	},

	INSERT_ADJUSTMENTS_EMPLOYEE(state, payload) {
		let employeeIndex = state.lazyEmployees.findIndex((e) => e.employee_id === payload.employee_id);
		employeeIndex === -1 && state.lazyEmployees.unshift(payload);
	},

	MODIFY_ADJUSTMENT(state, payload) {
		let objectIndex = state.adjustments.findIndex((b) => b.id === payload.id);
		Object.keys(state.adjustments[objectIndex]).forEach((key) => {
			state.adjustments[objectIndex][key] = payload[key];
		});
	},

	REMOVE_ADJUSTMENTS(state, payload) {
		payload.id.forEach((arrayItem) => {
			let objectIndex = state.adjustments.findIndex((b) => b.id === arrayItem);
			objectIndex !== -1 && state.adjustments.splice(objectIndex, 1);
		});
	},

	REMOVE_ADJUSTMENTS_EMPLOYEE(state, payload) {
		payload.forEach((arrayItem) => {
			let objectIndex = state.lazyEmployees.findIndex((b) => b.employee_id === arrayItem);
			objectIndex !== -1 && state.lazyEmployees.splice(objectIndex, 1);
		});
	},

	REMOVE_EMPLOYEE_ADJUSTMENTS(state, payload) {
		payload.employee_id.forEach((employee_id) => {
			// Iterate found employee adjustments
			let objectIndex = state.lazyEmployees.findIndex((b) => b.employee_id === employee_id);
			objectIndex !== -1 && state.lazyEmployees.splice(objectIndex, 1);

			objectIndex = state.searchResults.findIndex((b) => b.employee_id === employee_id);
			objectIndex !== -1 && state.searchResults.splice(objectIndex, 1);
		});
	},

	CLEAR_FILTERED_ADJUSTMENTS(state) {
		state.searchResults = [];
	},

	CLEAR_SUGGEST_ADJUSTMENTS(state) {
		state.suggesthResults = [];
	},

  CLEAR_LAZY_EMPLOYEES(state) {
    state.lazyEmployees = [];
  }
};

const actions = {
	TOGGLE_ADJUSTMENT_DELETE({ commit }, payload) {
		commit('TOGGLE_ADJUSTMENT_DELETE');
	},

	SHOW_ADD_FORM({ commit }, payload) {
		commit('SHOW_ADD_FORM');
	},

	HIDE_ADD_FORM({ commit }, payload) {
		commit('HIDE_ADD_FORM');
	},

	GET_EMPLOYEE_ADJUSTMENTS({ commit }, payload) {
		commit('GET_EMPLOYEE_ADJUSTMENTS', payload);
	},

	CLEAR_FILTERED_ADJUSTMENTS({ commit }, payload) {
		commit('CLEAR_FILTERED_ADJUSTMENTS');
	},

	FETCH_FILTERED_ADJUSTMENTS({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/adjustment',
				{
					success(response) {
						response.data.status === 'success' && commit('SET_FILTERED_ADJUSTMENT', response.data.data);
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},

	FETCH_SUGGEST_ADJUSTMENTS({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/adjustment',
				{
					success(response) {
						response.data.status === 'success' && commit('SET_SUGGEST_ADJUSTMENT', response.data.data);
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},

	FETCH_FILTERED_ADJUSTMENTS_FOR_DOWNLOAD({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/adjustment',
				{
					success(response) {
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},

	FETCH_ADJUSTMENTS({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/adjustment',
				{
					success(response) {
						response.data.status === 'success' && commit('SET_ADJUSTMENTS', response.data.data);
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},

	FETCH_ADJUSTMENTS_LAZY({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/adjustment',
				{
					success(response) {
						response.data.status === 'success' && commit('JOIN_ADJUSTMENTS_EMPLOYEES', response.data.data);
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},

	SAVE_ADJUSTMENT({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPost('juanHR/adjustment', payload, {
				success(response) {
					response.data.status === 'success' && commit('INSERT_ADJUSTMENT', response.data.data);
					resolve(response.data);
				},
				catch(response) {},
			});
		});
	},

	SAVE_ADJUSTMENTS({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPost('juanHR/adjustment', payload, {
				success(response) {
					response.data.status === 'success' && commit('INSERT_ADJUSTMENTS', response.data.data);
					resolve(response.data);
				},
				catch(response) {},
			});
		});
	},

	UPDATE_ADJUSTMENT({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPut(`juanHR/adjustment/${payload.id}`, payload, {
				success(response) {
					response.data.status === 'success' && commit('MODIFY_ADJUSTMENT', response.data.data);
					resolve(response.data);
				},
				catch(response) {},
			});
		});
	},

	DELETE_ADJUSTMENTS({ commit, state }, payload) {
		return new Promise((resolve, reject) => {
			httpDel(`juanHR/adjustment/${payload.id}`, payload, {
				success(response) {

					/** If response is succss commit to remove branch on vuex */
          if (response.data.status === 'success') {
            commit('REMOVE_ADJUSTMENTS', payload)
            //CHECK IF STATES IS EMPTY
            if (!state.adjustments.length) {
              let emptyArr = []
              commit('CLEAR_LAZY_EMPLOYEES')
            }
          }

					/** resolve promise */
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				},
			});
		});
	},

	DELETE_EMPLOYEE_ADJUSTMENTS({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpDel(`juanHR/adjustment/${payload.employee_id}`, payload, {
				success(response) {
					/** If response is succss commit to remove branch on vuex */
					response.data.status === 'success' && commit('REMOVE_EMPLOYEE_ADJUSTMENTS', payload);
					/** resolve promise */
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				},
			});
		});
	},
};

export default { namespaced: true, state, getters, mutations, actions };
