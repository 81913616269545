import { httpGet, httpPost, httpPut, httpDel } from 'boot/axios';
// import { isObject } from './../../../../../shared/helpers/Common';
import { isObject } from './../../../shared/helpers/Common';

const state = {
	lists: [],
	lazyLists: [],
	selectedMonth: [],
	// bulkDelete: false,
	triggers: {
		displayBulkDelete: false,
		removeSelectedMonths: false,
		deleteOvertimeRangeOrSingle: false,
	},
	deleteEnabled: false,
	activeEmployee: [],
	searchResults: [],
	suggestResults: [],
	// employeeNameOptions: []
};
const getters = {
	GET_OVERTIME(state) {
		return state.lists;
	},
	GET_OVERTIME_LAZY(state) {
		return state.lazyLists;
	},

	GET_SELECTED_MONTHS(state) {
		return state.selectedMonth;
	},

	GET_BULK_DELETE_STATE(state) {
		return state.bulkDelete;
	},

	GET_OVERTIME_DELETE_STATE: (state) => {
		return state.deleteEnabled;
	},

	GET_GLOBAL_TRIGGERS(state) {
		return state.triggers;
	},

	GET_SEARCH_RESULTS(state) {
		return state.searchResults;
	},

	GET_SUGGEST_RESULTS(state) {
		return state.suggestResults;
	},
	GET_ACTIVE_EMPLOYEE(state) {
		return state.activeEmployee;
	},

	// GET_EMPLOYEE_NAME_OPTIONS(state) {
	// 	return state.employeeNameOptions;
	// }
};

const mutations = {
	TOGGLE_OVERTIME_DELETE(state, payload) {
		state.deleteEnabled = state.deleteEnabled !== true;
	},

	SET_OVERTIME(state, payload) {
		state.lists = payload;
	},

	INSERT_OVERTIME(state, payload) {
		state.lists.unshift(payload);
	},

	JOIN_OVERTIME(state, payload) {
		payload.forEach((e) => {
			let exist = state.lazyLists.filter((i) => i.employee_id === Number(e.employee_id));
			!exist.length && state.lazyLists.push(e);
		});
	},

	// SET_SEARCH_DATA(state, payload) {
	// 	state.searchResults = payload;
	// },

	SET_SUGGEST_DATA(state, payload) {
		state.suggestResults = payload;
	},

	SET_EMPLOYEE_NAME_OPTIONS(state, payload) {
		if (state.employeeNameOptions.length == 0) {
			state.employeeNameOptions = [];
			payload.forEach((e) => {
				state.employeeNameOptions.push({
					label: e.last_name + ', ' + e.first_name + ' ' + e.middle_name,
					value: e.employee_id,
				});
			});
		}
	},

	MODIFY_OVERTIME(state, payload) {
		let objectIndex = state.lists.findIndex((e) => e.id === payload.id);
		objectIndex !== -1 &&
			Object.keys(state.lists[objectIndex]).forEach((key) => {
				state.lists[objectIndex][key] = payload[key];
			});
	},

	REMOVE_OVERTIME(state, payload) {
		payload.forEach((id) => {
			/** Find Array Index on State */
			let objectIndex = state.lists.findIndex((d) => (payload.employee ? d.employee_id : d.id === Number(id)));
			/** If objectIndex !== -1 means the id was found on state then we remove it manually */
			objectIndex !== -1 && state.lists.splice(objectIndex, 1);

			/** Find Array Index on State */
			objectIndex = state.lazyLists.findIndex((d) => (payload.employee ? d.employee_id : d.id === Number(id)));
			/** If objectIndex !== -1 means the id was found on state then we remove it manually */
			objectIndex !== -1 && state.lazyLists.splice(objectIndex, 1);
		});
	},

	REMOVE_ALL_EMPLOYEE_OT(state, payload) {
		payload.forEach((id) => {
			/** Find Array Index on State */
			let objectIndex = state.lists.findIndex((d) => d.employee_id === Number(id));
			/** If objectIndex !== -1 means the id was found on state then we remove it manually */
			objectIndex !== -1 && state.lists.splice(objectIndex, 1);

			objectIndex = state.lists.findIndex((d) => d.id === Number(id));
			/** If objectIndex !== -1 means the id was found on state then we remove it manually */
			objectIndex !== -1 && state.lists.splice(objectIndex, 1);

			/** Find Array Index on State */
			objectIndex = state.lazyLists.findIndex((d) => d.employee_id === Number(id));
			/** If objectIndex !== -1 means the id was found on state then we remove it manually */
			objectIndex !== -1 && state.lazyLists.splice(objectIndex, 1);

			objectIndex = state.lazyLists.findIndex((d) => d.id === Number(id));
			/** If objectIndex !== -1 means the id was found on state then we remove it manually */
			objectIndex !== -1 && state.lazyLists.splice(objectIndex, 1);

			objectIndex = state.searchResults.findIndex((d) => d.employee_id === Number(id));
			objectIndex !== -1 && state.searchResults.splice(objectIndex, 1);
		});
	},

	SET_SELETED_MONTHS(state, payload) {
		state.selectedMonth = payload;
	},

	SET_BULK_DELETE_STATE(state, payload) {
		state.bulkDelete = payload;
	},

	SET_GLOBAL_TRIGGERS(state, payload) {
		state.triggers[payload.key] = payload.value;
	},

	SET_SEARCH_DATA(state, payload) {
		state.searchResults = payload;
	},
	SET_ACTIVE_EMPLOYEE(state, payload) {
		state.activeEmployee = payload;
	},
};

const actions = {
	FETCH_OVERTIME({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/overtime',
				{
					success(response) {
						response.data.status === 'success' &&
							!payload.grouped &&
							commit('SET_OVERTIME', response.data.data);
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},

	FETCH_OVERTIME_LAZY({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/overtime',
				{
					success(response) {
						response.data.status === 'success' && commit('JOIN_OVERTIME', response.data.data);
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},

	FIND_OVERTIME({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/overtime',
				{
					success(response) {
						response.data.status === 'success' && commit('SET_SEARCH_DATA', response.data.data);
						resolve(response.data);
					},

					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},

	LOOK_OVERTIME({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/overtime',
				{
					success(response) {
						response.data.status === 'success' && commit('SET_SUGGEST_DATA', response.data.data);
						resolve(response.data);
					},

					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
	},

	SAVE_OVERTIME({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPost('juanHR/overtime', payload, {
				success(response) {
					if (response.data.status === 'success') {
						commit('JOIN_OVERTIME', [response.data.data]);
						commit('INSERT_OVERTIME', response.data.data);
					}
					resolve(response.data);
				},

				catch(response) {
					console.log(response);
				},
			});
		});
	},

	UPDATE_OVERTIME({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPut(`juanHR/overtime/${payload.id}`, payload, {
				success(response) {
					response.data.status === 'success' && commit('MODIFY_OVERTIME', response.data.data);
					resolve(response.data);
				},

				catch(response) {
					console.log(response);
				},
			});
		});
	},

	DELETE_OVERTIME({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpDel(
				`juanHR/overtime/${payload}`,
				{
					employee_id: payload,
				},
				{
					success(response) {
						if (response.data.status === 'success') {
							// payload.range &&
							commit('REMOVE_ALL_EMPLOYEE_OT', payload);
						}
						resolve(response.data);
					},
					catch(response) {},
				}
			);
		});
	},

	DELETE_SELECTED_OVERTIME({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpDel(
				`juanHR/overtime/${payload}`,
				{
					overtime_id: payload,
				},
				{
					success(response) {
						if (response.data.status === 'success') {
							// payload.range &&
							commit('REMOVE_ALL_EMPLOYEE_OT', payload);
						}
						resolve(response.data);
					},
					catch(response) {},
				}
			);
		});
	},

	DELETE_RANGE_OVERTIME({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpDel(
				`juanHR/overtime/${payload.range_value}`,
				{
					employee_id: payload.employee_id,
					range_value: payload.range_value,
					range: true,
				},
				{
					success(response) {
						if (response.data.status === 'success') {
							// payload.range &&
							commit('REMOVE_ALL_EMPLOYEE_OT', payload);
						}
						resolve(response.data);
					},
					catch(response) {},
				}
			);
		});
	},

	UPLOAD_BULK_OVERTIME({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpPost(`juanHR/overtime`, payload, {
				success(response) {
					let successRow = !isObject(response.data.data) ? response.data.data : response.data.data.inserted;
					response.data.status === 'success' && commit('JOIN_OVERTIME', successRow);
					resolve(response.data);
				},
				catch(response) {
					console.log(response);
				},
			});
		});
	},

	// EMPLOYEE_OPTIONS({ commit }, payload) {
	// 	return new Promise((resolve, reject) => {
	// 		httpGet('juanHR/overtime', {
	// 			success(response) {
	// 				commit('SET_EMPLOYEE_NAME_OPTIONS', response.data.data);
	// 				resolve(response.data);
	// 			},
	// 			catch(response) {
	// 				console.log(response);
	// 			}
	// 		});
	// 	});
	// },

	OVERTIME_DOWNLOAD({ commit }, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/overtime',
				{
					success(response) {
						resolve(response.data);
					},
					catch(response) {
						console.log(response);
					},
				},
				payload
			);
		});
		// console.log(payload);
	},

	// CLEAR_ADVANCE_SEARCH_FORM(state, payload) {
	// 	state.search = {
	// 		keyword: '',
	// 		employee_ref_id: '',
	// 		employee_id: '',
	// 		start_date: '',
	// 		end_date: '',
	// 		before_date: '',
	// 		after_date: '',
	// 		exact_date: '',
	// 		equal_hours: '',
	// 		not_equal_hours: '',
	// 		less_than_hours: '',
	// 		less_equal_hours: '',
	// 		greater_than_hours: '',
	// 		greater_equal_hours: '',
	// 		first_hour: '',
	// 		second_hour: '',
	// 		offset: '',
	// 		limit: '',
	// 		dateFilter: '',
	// 		hourFilter: ''
	// 	};
	// }
};

export default { namespaced: true, state, getters, mutations, actions };
