import { httpGet, httpPost, httpPut, httpDel } from 'boot/axios';

const state = {
	lists: [],
  excludes: {
    month: null,
    year: null
  },
  informationList: [],
};

const getters = {
	GET_THIRTEENTH_MONTH_INFORMATION: state => {
		return state.lists;
	},
  GET_EXCLUDED_MONTH_YEAR: state => {
    return state.excludes;
  },
  GET_INFORMATION_LIST: state => {
    return state.informationList;
  }
};

const mutations = {
	SET_THIRTEENTH_MONTH_INFORMATION(state, payload) {
		state.lists = payload;
	},
  SET_EXCLUDED_MONTH_YEAR(state, payload) {
    state.excludes = payload;
  },
  SET_INFORMATION_LIST(state, payload) {
    state.informationList = payload;
  }
};

const actions = {
	FETCH_THIRTEENTH_MONTH_INFORMATION(context, payload) {
		return new Promise((resolve, reject) => {
			httpGet(
				'juanHR/thirteenthMonthInformation',
				{
					success:(response) => {
						if (response.data.status === 'success');
						context.commit("SET_THIRTEENTH_MONTH_INFORMATION", response.data.data);

						resolve(response.data);
					},

          catch:(response) =>{
            console.log(response);
          }
				},
				payload
			);
		});
	},

  // SAVE_THIRTEENTH_MONTH_BULK_UPLOAD: (context, payload) => {
  //   return new Promise((resolve, reject) => {
  //     httpPost('juanHR/thirteenthMonthInformation', payload, {
  //       success: (response) => {
  //         if (response.data.status === 'success') {
  //           context.commit("SET_INFORMATION_LIST", response.data.data);

  //           resolve(response.data);
  //           console.log('Resolve Value', response);
  //         }
  //       },
  //       catch: (response) => {
  //         reject(response.data);
  //       }
  //     });
  //   });
  // }
};

export default { state, getters, mutations, actions, namespaced: true };
